import React, { useState, useEffect, useRef } from 'react';

import { reqGET, repo_img_link, content_language, translate } from '../../../../Utils';

import * as Hosts from "../../../../Hosts";

import { language } from '../Language';

import { Navigation, Pagination, Autoplay, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';

import "swiper/swiper-bundle.min.css";

//import parse from 'html-react-parser';
import AssecImage from "../../ASSEC_Image/Image"

//import Loader from '../../../../loader';
// import "../../Pagina.css";
import "./HomeBrands_04.css"

const HomeBrands = (props) => {

	const [, set_dados] = useState(null)
	const brands_list = useRef([])
	const dados_config = useRef([])

    const [brands_title, set_brands_title] = useState('')
    const [brands_subtitle, set_brands_subtitle] = useState('')

    const [isloading, setLoading] = useState(true);

    const [lang, set_lang] = useState(localStorage.getItem('lang'));
    const [/*lang_id*/, set_lang_id] = useState('1');

    useEffect(() => {

        if(props.dados_config !== undefined && props.dados_config !== null && props.dados_config !== '') {
            set_dados(dados_config.current = props.dados_config)
            
            for (let el of dados_config.current.content[0].components) {

                if(el.info.tag === 'brand' && el.info.active) {
                    set_brands_title(translate(el.info.title, lang))
                    set_brands_subtitle(translate(el.info.subtitle, lang))
                }
            }
        }

        if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                    set_lang_id(el.id)
                }
            })
        }

        let getBrands = async () => {
            reqGET(`${Hosts.SIMCore_API}/web/content/slug/${Hosts.companycode}/brand_short`)
              .then(res => {
        
                if(res.data.length > 0) {
                  set_dados(brands_list.current = shuffle(res.data[0].content[0]?.media?.image))
                  setLoading(false)
                }
        
              }).catch(erro => console.log('Erro1', erro))
        }
        getBrands()

    }, [props, lang])


    function shuffle(array) {
      for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array; 
    }

    return (
      isloading ?
        null
		  :
        <section id="homebrands_04" className='div-component'>
          <div className="container">
            <div className="row">
              <div className="col-12 title-properties">
                <h3 className="titulo-pp">
                  {brands_title}
                </h3>
                {
                  brands_subtitle !== '' ?
                    <h4 className={"subtitulo-pp"}>
                      {brands_subtitle}
                    </h4>
                  :
                    null
                }
                <hr></hr>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                {
                brands_list.current !== undefined && brands_list.current !== null && brands_list.current.length > 0 ?
                  //swiper carousel
                  <>
                    <div className="swiper-container swiper-brands">
                      <Swiper className="p-1" modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
                        speed={500}
                        loop={false}
                        autoHeight={false}
                        autoplay={{
                          delay: 2500,
                          disableOnInteraction: false,
                        }}
                        //centeredSlides={true}
                        centerInsufficientSlides={true}
                        spaceBetween={15}
                        slidesPerView={2}
                      //navigation
                        pagination={{
                          el: '.swiper-pagination-brands',
                          clickable: true
                        }}
                      //scrollbar={{ draggable: true }}
                      breakpoints={{
                        // when window width is >= 0px
                        0: {
                          slidesPerView: 4,
                          //lidesPerGroup: 4
                        },
                        // when window width is >= 768px
                        768: {
                          slidesPerView: 6,
                          //lidesPerGroup: 6
                        },
                        992: {
                          slidesPerView: 8,
                          //lidesPerGroup: 8
                        },
                        1200: {
                          slidesPerView: 10,
                          //slidesPerGroup: 10
                        },
                      }}
                      >
                        {
                          brands_list.current.map((v, key) => {
                            if(key < 20) {
                              return (
                                <SwiperSlide key={key}>
                                  {/*<a href={'./brand/all'} title={(v.link.includes(translate(v.name, lang)) || translate(v.name, lang).includes('webp') || translate(v.name, lang).includes('jpg') || translate(v.name, lang).includes('png')) ? '' : translate(v.name, lang)}>*/}
                                      <div className="card" title={(v.link.includes(translate(v.name, lang)) || translate(v.name, lang).includes('webp') || translate(v.name, lang).includes('jpg') || translate(v.name, lang).includes('png')) ? '' : translate(v.name, lang)}>
                                          <div className="card-img-top brand-image">
                                              <AssecImage
                                                  linkImage={v.link !== '' ? v.link : repo_img_link('nophoto.webp')}
                                                  width="400"
                                                  height="300"
                                                  fit='fit'
                                                  type='img'
                                                  sClass='img-fluid'
                                                  alt={translate(v.name, lang)}
                                                  title={(v.link.includes(translate(v.name, lang)) || translate(v.name, lang).includes('webp') || translate(v.name, lang).includes('jpg') || translate(v.name, lang).includes('png')) ? '' : translate(v.name, lang)}
                                              />
                                          </div>
                                      </div>
                                  {/*</a>*/}
                                </SwiperSlide>
                              )
                            } else {
                              return null
                            }
                          })
                        }
                      </Swiper>
                    </div>
                    <div className="swiper-pagination-brands mt-4 d-none"></div>
                  </>
                  
                :
                  null
                }
              </div>
              <div className="col-12 mt-5 text-center position-relative">
                <a className="btn btn-primary" href={'./brand/all'} >{language[lang].see_it_all}</a>
              </div>
            </div>
          </div>
        </section>
    )
};
export default HomeBrands;