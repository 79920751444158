import React, { useState, useEffect, useRef } from 'react';

import { /*reqGET,*/ repo_img_link, content_language, translate } from '../../../../Utils';

//import * as Hosts from "../../../../Hosts";

//import { language } from '../Language';

import AssecImage from "../../ASSEC_Image/Image"

import Loader from '../../../../loader';
// import "../../Pagina.css";
import "./HomeGallery_04.css"

const HomeGallery = (props) => {

	const [, set_dados] = useState(null)
	const gallery_list = useRef([])
	const dados_config = useRef([])

    const [gallery_title, set_gallery_title] = useState('')
    const [gallery_subtitle, set_gallery_subtitle] = useState('')

    const [isloading, setLoading] = useState(true);

    const [lang, set_lang] = useState(localStorage.getItem('lang'));
    const [/*lang_id*/, set_lang_id] = useState('1');

    useEffect(() => {

        if(props.dados_config !== undefined && props.dados_config !== null && props.dados_config !== '') {
            set_dados(dados_config.current = props.dados_config)
            
            for (let el of dados_config.current.content[0].components) {

                if(el.info.tag === 'gallery' && el.info.active) {
                    set_gallery_title(translate(el.info.title, lang))
                    set_gallery_subtitle(translate(el.info.subtitle, lang))

                    set_dados(gallery_list.current = shuffle(dados_config.current.content[0].gallery))
                    setLoading(false)
                }
            }
        }

        if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                    set_lang_id(el.id)
                }
            })
        }

       /*let getGallery = async () => {
            await reqGET(`${Hosts.SIMCore_API}/web/content/type/${Hosts.companycode}/gallery/random`)
              .then(async res => {
        
                let aGalleryAux = []
        
                if(res.data.length > 0) {
                  for await(let el of res.data) {
        
                    el.content.forEach(el_c => {
                      if(el_c.media.image !== undefined && el_c.media.image !== null && el_c.media.image.length > 0) {
        
                        if(parseInt(lang_id) !== 1 && el_c.language[lang_id] !== undefined && el_c.language[lang_id] !== null && el_c.language[lang_id].media !== undefined && el_c.language[lang_id].media !== null && el_c.language[lang_id].media.image !== undefined && el_c.language[lang_id].media.image !== null && el_c.language[lang_id].media.image.length > 0) {
                          el_c.language[lang_id].media.image.forEach(el_i => {                  
                            el_i.gallery_id = el.id
                            aGalleryAux.push(el_i)
                          })
                        }
                        else {
                          el_c.media.image.forEach(el_i => {
                            el_i.gallery_id = el.id
                            aGalleryAux.push(el_i)
                          })
        
                        }
        
                      }
                    }) 
        
                  }
        
                  set_dados(gallery_list.current = aGalleryAux)
                  setLoading(false)
                }
        
              })
              .catch(erro => 
                console.log('erro', erro)
              )
        }*/
        //getGallery()

    }, [props, lang])

    function shuffle(array) {
        for (let i = array.length - 1; i > 0; i--) {
          let j = Math.floor(Math.random() * (i + 1));
          [array[i], array[j]] = [array[j], array[i]];
        }
        return array; 
    }

    return (
        isloading ?
          <Loader />
		  :
          <section id="homegallery_04" className='div-component'>
            <div className="container">
              <div className="row">
                <div className="col-12 title-properties">
                  <h3 className="titulo-pp">
                    {gallery_title}
                  </h3>
                  {
                    gallery_subtitle !== '' ?
                      <h4 className={"subtitulo-pp"}>
                        {gallery_subtitle}
                      </h4>
                    :
                      null
                  }
                  <hr></hr>
                </div>
                <div className="col-12">
                  {
                  gallery_list.current !== undefined && gallery_list.current !== null && gallery_list.current.length > 0 ?
                    <>
                      <div className="row gy-4 justify-content-center">
                        <div className='col-md-5'>
                            <>
                              {
                                gallery_list.current[0].type === 'video' ?
                                  <a
                                      href={gallery_list.current[0].link.replace('watch?v=', 'embed/')}
                                      data-fancybox="gallery"
                                      data-caption={(gallery_list.current[0].link.includes(gallery_list.current[0].name) || gallery_list.current[0].name.includes('webp') || gallery_list.current[0].name.includes('jpg') || gallery_list.current[0].name.includes('png') || gallery_list.current[0].name.includes('youtube.com')) ? '' : gallery_list.current[0].name}
                                  >
                                    <div className="gallery-item">
                                      <img src={'https://i1.ytimg.com/vi/' + gallery_list.current[0].link.replace('watch?v=', 'embed/').split('/').pop() +'/0.jpg'} className="img-fluid" alt={gallery_list.current[0].name} title={gallery_list.current[0].name} />
                                        {/*<div className="hover-container">
                                          <div className='icon'>
                                              <i className="bi bi-play-circle-fill"></i>
                                          </div>
                                        </div>*/}
                                    </div>
                                  </a>
                                :
                                  <a
                                      href={gallery_list.current[0].link !== '' ? gallery_list.current[0].link : repo_img_link('nophoto.webp')}
                                      data-fancybox="gallery"
                                      data-caption={(gallery_list.current[0].link.includes(gallery_list.current[0].name) || gallery_list.current[0].name.includes('webp') || gallery_list.current[0].name.includes('jpg') || gallery_list.current[0].name.includes('png')) ? '' : gallery_list.current[0].name}
                                  >
                                    <div className="gallery-item">
                                      <AssecImage
                                        linkImage={gallery_list.current[0].link !== '' ? gallery_list.current[0].link : repo_img_link('nophoto.webp')}
                                        width="550"
                                        height="450"
                                        fit='crop'
                                        type='img'
                                        sClass='img-fluid'
                                        alt={gallery_list.current[0].name}
                                        title={(gallery_list.current[0].link.includes(gallery_list.current[0].name) || gallery_list.current[0].name.includes('webp') || gallery_list.current[0].name.includes('jpg') || gallery_list.current[0].name.includes('png')) ? '' : gallery_list.current[0].name}
                                      />
                                      {/*<div className="hover-container">
                                          <div className='icon'>
                                              <i className="bi bi-eye-fill"></i>
                                          </div>
                                      </div>*/}
                                    </div>
                                  </a>
                                }
                            </>
                        </div>
                        <div className='col-md-7'>
                          <div className='row g-3'>
                            {
                              gallery_list.current.map((el, k) => {
                                if(k > 0 && k < 7) {

                                  let link = ''
                                  let youtube_thumbnail = ''

                                  if(el.type === 'video') {

                                      link = el.link.replace('watch?v=', 'embed/');
                                  
                                      youtube_thumbnail = link.split('/').pop();
                                  }
                                  return (
                                    <div className="col-6 col-md-4" key={k}>
                                      {
                                        el.type === 'video' ?
                                          <a
                                              href={link}
                                              data-fancybox="gallery"
                                              data-caption={(el.link.includes(el.name) || el.name.includes('webp') || el.name.includes('jpg') || el.name.includes('png') || el.name.includes('youtube.com')) ? '' : el.name}
                                          >
                                            <div className="gallery-item">
                                              <img src={'https://i1.ytimg.com/vi/' + youtube_thumbnail +'/0.jpg'} className="img-fluid" alt={el.name} title={el.name} />
                                                {/*<div className="hover-container">
                                                  <div className='icon'>
                                                      <i className="bi bi-play-circle-fill"></i>
                                                  </div>
                                                </div>*/}
                                            </div>
                                          </a>
                                        :
                                          <a
                                              href={el.link !== '' ? el.link : repo_img_link('nophoto.webp')}
                                              data-fancybox="gallery"
                                              data-caption={(el.link.includes(el.name) || el.name.includes('webp') || el.name.includes('jpg') || el.name.includes('png')) ? '' : el.name}
                                          >
                                            <div className="gallery-item">
                                              <AssecImage
                                                linkImage={el.link !== '' ? el.link : repo_img_link('nophoto.webp')}
                                                width="400"
                                                height="350"
                                                fit='crop'
                                                type='img'
                                                sClass='img-fluid'
                                                alt={el.name}
                                                title={(el.link.includes(el.name) || el.name.includes('webp') || el.name.includes('jpg') || el.name.includes('png')) ? '' : el.name}
                                              />
                                              {/*<div className="hover-container">
                                                  <div className='icon'>
                                                      <i className="bi bi-eye-fill"></i>
                                                  </div>
                                              </div>*/}
                                            </div>
                                          </a>
                                        }
                                    </div>
                                  )
                                }
                                else {
                                  return null
                                }
                              })
                            }
                            </div>
                          </div>
                      </div>
                    </>
                  :
                    null
                  }
                </div>
                {/*<div className="col-12 mt-5 text-center position-relative">
                    <a className="btn btn-primary" href={'./gallery/all'} >{language[lang].see_it_all}</a>
                  </div>*/}
              </div>
            </div>
          </section>
    )
};
export default HomeGallery;