import React, { useState, useEffect, useRef } from 'react';

import { reqGET, repo_img_link, content_language, translate } from '../../../../Utils';

import * as Hosts from "../../../../Hosts";

import { language } from '../Language';

import parse from 'html-react-parser';
import AssecImage from "../../ASSEC_Image/Image"

import Loader from '../../../../loader';
// import "../../Pagina.css";
import "./HomeNews_03.css"

const HomeNews = (props) => {

	const [, set_dados] = useState(null)
	const news_list = useRef([])
	const dados_config = useRef([])

    const [news_title, set_news_title] = useState('')
    const [news_subtitle, set_news_subtitle] = useState('')

    const [isloading, setLoading] = useState(true);

    const [lang, set_lang] = useState(localStorage.getItem('lang'));
    const [lang_id, set_lang_id] = useState('1');

    useEffect(() => {

        if(props.dados_config !== undefined && props.dados_config !== null && props.dados_config !== '') {
            set_dados(dados_config.current = props.dados_config)
            
            for (let el of dados_config.current.content[0].components) {

                if(el.info.tag === 'news' && el.info.active) {
                    set_news_title(translate(el.info.title, lang))
                    set_news_subtitle(translate(el.info.subtitle, lang))
                }
            }
        }

        if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                    set_lang_id(el.id)
                }
            })
        }

        let getNews = async () => {
            await reqGET(`${Hosts.SIMCore_API}/web/content/type/${Hosts.companycode}/news`)
                .then(async res => {

                    if (res.data.length > 0) {

                        var hoje = new Date();
                        let aux = []
                        for await (let v of res.data) {
                            if(v.info.date.published !== undefined && v.info.date.published !== '') {
                            var date_p = new Date(v.info.date.published);
            
                            if(hoje >= date_p){
                                /* aux.push(v) */
                                if(v.info.date.avaiable !== undefined && v.info.date.avaiable !== '') { 
                                var date_a = new Date(v.info.date.avaiable);
                                if(hoje <= date_a) {
                                    aux.push(v)
                                }
                                } else{
                                    aux.push(v)
                                }
                            }
                            }
                        }

                        set_dados(news_list.current = aux)
                        setLoading(false)

                    }
                    else {
                        set_dados(news_list.current = res.data)
                        setLoading(false)
                    }

                }).catch(erro => console.log('Erro1', erro))
        }
        getNews()

    }, [props, lang])

    function get_image_content(el, type) {
  
      let result = ''
  
  
        if(parseInt(lang_id) !== 1 && el.content[0]['language'] !== undefined && el.content[0]['language'][lang_id] !== undefined && el.content[0]['language'][lang_id] !== null && el.content[0]['language'][lang_id]['media'] !== null && el.content[0]['language'][lang_id]['media']['image'] !== undefined && el.content[0].language[lang_id]['media']['image'].length > 0) { 
          if(type === 'name') {
            result = el.content[0].language[lang_id].media.image[0].name
          }
          else if(type === 'url') {
            result = el.content[0].language[lang_id].media.image[0].url
          }
          else if(type === 'link') {
            result = el.content[0].language[lang_id].media.image[0].link
          }
        } else {
          if(el.content[0].media.image !== undefined && el.content[0].media.image !== null && el.content[0].media.image.length > 0) {
  
            if(type === 'name') {
              result = el.content[0].media.image[0].name
            }
            else if(type === 'url') {
              result = el.content[0].media.image[0].url
            }
            else if(type === 'link') {
              result = el.content[0].media.image[0].link
            }
    
          }
  
        }
  
      return result
    }

    return (
        isloading ?
            <Loader />
		:
            <section id="homenews_03" className='div-component'>
                <div className="container">
                  <div className="row">
                    <div className="col-12 title-properties">
                      <h3 className="titulo-pp">
                        {news_title}
                      </h3>
                      {
                        news_subtitle !== '' ?
                          <h4 className={"subtitulo-pp"}>
                            {news_subtitle}
                          </h4>
                        :
                         null
                      }
                      <hr></hr>
                    </div>
                    <div className="col-12 text-end mb-3">
                      <a className="btn btn-expand" href={'./article/all'} >
                          <span>{language[lang].see_it_all}</span>
                          <i className="bi bi-arrow-right"></i>
                      </a>
                    </div>
                      {
                      news_list.current !== undefined && news_list.current !== null && news_list.current.length > 0 ?
                        <div className='col-12'>
                          <div className="row g-4">
                            {
                              news_list.current.map((v, k) => {
                                if(k < 3) {
                                  //boostrap columns
                                  return (
                                    <div className="col-12 col-md-6 col-lg-4 mb-4" key={k}>
                                      <a href={'./article/' + v.id} title={parse(lang === 'pt' || v.content[0].language === undefined ? v.content[0].title : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].title === undefined || v.content[0].language[lang_id].title === '' ? v.content[0].title : v.content[0].language[lang_id].title))}>
                                        <div className="article-container">
                                          <div className="article-image">
                                            <AssecImage
                                              linkImage={get_image_content(v, 'link') !== '' ? get_image_content(v, 'link') : repo_img_link('nophoto.webp')}
                                              width="450"
                                              height="350"
                                              fit='crop'
                                              type='img'
                                              sClass='img-fluid'
                                              alt={get_image_content(v, 'name')}
                                              title={get_image_content(v, 'name')}
                                            />
                                          </div>
                                          <div className="article-body">
                                            <div className="article-title">
                                              {parse(lang === 'pt' || v.content[0].language === undefined ? v.content[0].title : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].title === undefined || v.content[0].language[lang_id].title === '' ? v.content[0].title : v.content[0].language[lang_id].title))}
                                            </div>
                                            <div className="article-text">
                                              {parse((lang === 'pt' || v.content[0].language === undefined ? v.content[0].abstract : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].abstract === undefined || v.content[0].language[lang_id].abstract === '' ? v.content[0].abstract : v.content[0].language[lang_id].abstract)))}
                                            </div>
                                            <div className="timestamp">{parse(v.info.date.published.split('T')[0])}</div>
                                            <div className="btn-link article-link mt-3 d-none">
                                              {language[lang].learn_more}
                                              <i className="bi bi-arrow-right"></i>
                                            </div>
                                          </div>
                                        </div>
                                      </a>
                                    </div>
                                    )
                                } else {
                                  return null
                                }
                              })
                            }
                          </div>
                        </div>
                      :
                        null
                      }
                    <div className="col-12 mt-5 text-center d-none">
                      <a className="btn btn-primary" href={'./article/all'} >{language[lang].see_it_all}</a>
                    </div>
                  </div>
                </div>
            </section>
    )
};
export default HomeNews;