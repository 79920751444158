import React, {useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
// import DataTableExtensions from "react-data-table-component-extensions";
// import "react-data-table-component-extensions/dist/index.css";

import "../Pagina.css";
import "../content-styles.css"

import * as Hosts from '../../../Hosts';
import {
    //set_language,
	reqGET,
	// reqPOST,
	// reqPUT,
    content_language,
    // passwordHash,
    // upgrade_cart,
    // my_local_storage,
    // repo_site_assets_link,
    // functionSendMail,

} from '../../../Utils';

// import { getTokenInfo } from '../../../UseToken';

import Loader from '../../../loader'

import Navbar from "../Navbar/Navbar";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Footer from "../Footer/Footer";

import { language } from './Language';
import useContextProvider from '../GenericStore/Providers/ContextProvider';

export default function Encomendas(props) {
    const { /* updateprodnegative, removeprod, get_lista_carrinho, get_numeroProdutos, get_lista, get_ws_info, get_is_logged,*/ get_id_user/*, set_lista_carrinho, update_timer, get_linguagem, refreshlista*/ } = useContextProvider()

    const [encomendas, set_encomendas] = useState([]);

	const [isloading, setLoading] = useState(true);

    const [lang, set_lang] = useState('pt');
    const [, set_lang_id] = useState('1');
    
    useEffect(() => {

        let getClientOrders = async (id_user) => {
            return await reqGET(`${Hosts.SIMCore_API}/store/order/client/${Hosts.companycode}/${id_user}`)
                .then(res => {

                    set_encomendas(res.data)

                    setLoading(false)

                })
                .catch(() => 404
            )
        }
        if (get_id_user() !== undefined && get_id_user() !== null && get_id_user() !== '') {
            getClientOrders(get_id_user())
        }

        if(localStorage.getItem('lang') === undefined  || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if(el.code === localStorage.getItem('lang')) {
                    set_lang_id(el.id)
                }
            })
        }

    }, [props, get_id_user]);

    const columns = [
        {
            name: language[lang].field_n_encomenda,
            selector: 'id',
            sortable: true,
            left: true,
            minWidth: '100px',
            maxWidth: '10%',
        },
        {
            name: language[lang].field_date,
            selector: 'info.data',
            sortable: true,
            center: true,
            minWidth: '100px',
            maxWidth: '20%',
        },
        {
            name: language[lang].field_name,
            selector: 'info.documento.user_info.name',
            sortable: true,
            left: true,
            minWidth: '200px',
            maxWidth: '48%',
        },
        {
            name: language[lang].field_total,
            selector: 'info.documento.total_documento',
            cell: row => 
                <span style={{fontFamily: 'Open Sans, sans-serif', fontVariantNumeric: "tabular-nums"}}>
                    {parseFloat(row.info.documento.total_documento).toFixed(2) + ' €'}
                </span>
            ,
            sortable: true,
            right: true,
            minWidth: '100px',
            maxWidth: '10%',
        },
        {
            name: language[lang].field_pagamento_pendente,
            selector: 'info.pagamento_pendente',
            cell: row => (
                parseInt(row.info.pagamento_pendente) === 1 ? 
                    <span className="badge rounded-0 bg-success"><i className="bi bi-check-lg text-white fs-4"></i></span> 
                : 
                    parseInt(row.info.pagamento_pendente) === 2 ? 
                            <span className="badge rounded-0 bg-primary"><i className="bi bi-check-lg text-white fs-4"></i></span> 
                        : 
                            parseInt(row.info.pagamento_pendente) === 0 ? 
                                <span className="badge rounded-0 bg-warning"><i className="bi bi-exclamation-lg text-dark fs-4"></i></span> 
                            : 
                                parseInt(row.info.pagamento_pendente) === -2 ? 
                                    <span className="badge rounded-0 bg-secondary"><i className="bi bi-arrow-return-left text-white fs-4"></i></span>
                                :
                                    <span className="badge rounded-0 bg-danger"><i className="bi bi-x-lg text-white fs-4"></i></span>
            ),
            sortable: false,
            center: true,
            minWidth: '100px',
            maxWidth: '7%',
        },
        {
            name: '',
            right: true,
            export: false,
            print: false,
            minWidth: '50px',
            maxWidth: '5%',
            cell: row => 
                <span>
                    <i className="bi bi-eye"></i>
                </span>
        },
    ]

    const defaultComponentOptions = {
        rowsPerPageText: language[lang].table_rows_per_page,
        rangeSeparatorText: language[lang].table_separator,
        noRowsPerPage: false,
        selectAllRowsItem: false,
        selectAllRowsItemText: language[lang].table_all,
    }

    return (
        isloading ?
            <Loader />
        :
        <>
            <Navbar /> 

            <Breadcrumb /> 

            <div id="cart" className="container-fluid my-5">
                <div className="row">
                    <div className="container">

                        <div className='row'>
                            <div className='col-12 col-lg-10'>

                                <div className="table-body">
                                        <DataTable
                                            columns={columns}
                                            data={encomendas}
                                            noDataComponent={language[lang].table_empty}
                                            noHeader
                                            pagination
                                            exportHeaders
                                            paginationComponentOptions={defaultComponentOptions}
                                            paginationPerPage={25}
                                            paginationRowsPerPageOptions={[25, 50, 100]}
                                        />
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            
            <Footer />
        </>

    );
}

