import React, { useState, useEffect, useRef } from 'react'

import Loader from '../../../loader';

import Navbar01 from './Navbar_01';
//import Navbar02 from './Navbar_02';
//import Navbar03 from './Navbar_03';

export default function Content(props) {

	const [, set_dados] = useState(null)
	const dados_config = useRef({})
	const template = useRef({})
	const header = useRef({})
	const content = useRef({})
	const pw = useRef({})

    const data_interface = {
        id: '',
        info: {},
        content: {
            childs: []
        }
    }
    const navbar = useRef(data_interface)

    const [isloading, setLoading] = useState(true);

    useEffect(() => {

        if(props.dados_navbar !== undefined && props.dados_navbar !== null && props.dados_navbar !== '') {
            set_dados(navbar.current = props.dados_navbar)
        }

        if(props.dados_config !== undefined && props.dados_config !== null && props.dados_config !== '') {
            set_dados(dados_config.current = props.dados_config)
            set_dados(template.current = props.dados_config.info.template)
            set_dados(header.current = props.dados_config.info.header)
            set_dados(content.current = props.dados_config)
            set_dados(pw.current = props.pag_welcome)

            setLoading(false)
        }

    }, [props]);

    return (isloading ?
		<Loader />
    :
        <>
            {
            header.current.info.tag === 'page-01' ?
                <Navbar01 pag_welcome={pw.current} dados_config={dados_config.current} dados_navbar={navbar.current} />
            :
            header.current.info.tag === 'page-02' ?
                <Navbar01 pag_welcome={pw.current} dados_config={dados_config.current} dados_navbar={navbar.current} />
            :
            header.current.info.tag === 'page-03' ?
                <Navbar01 pag_welcome={pw.current} dados_config={dados_config.current} dados_navbar={navbar.current} />
            :
                <Navbar01 pag_welcome={pw.current} dados_config={dados_config.current} dados_navbar={navbar.current} />
            }
        </>
    )
}
