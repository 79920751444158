import React, { useState, useEffect } from 'react';

//import ReactHtmlParser/*, { processNodes, convertNodeToElement, htmlparser2 }*/ from 'react-html-parser';

import * as Hosts from '../../../Hosts';
import { /*reqGET,*/ content_language, repo_logo_link} from '../../../Utils';

//import Navbar from '../Navbar/Navbar';
//import Footer from '../Footer/Footer';


import Loader from '../../../loader';
import { language } from '../WEBContent/Language';

export default function Normal(props) {

    const [dados_config, set_dados_config] = useState({});

    const [lang, set_lang] = useState('pt');
    const [, set_lang_id] = useState('1');

    const [isloading, setLoading] = useState(true);

    useEffect(() => {

        if(props.info !== undefined && props.info !== null && props.info !== '') {
            console.log('props.info', props.info)
            set_dados_config(props.info.state.dados_config)
        }

        set_lang(localStorage.getItem('lang'))
        content_language.forEach(el => {
            if (el.code === localStorage.getItem('lang')) {
            set_lang_id(el.id)
            }
        })

        setLoading(false) 
        
    }, [props]);

    return (isloading ?
		<Loader />
	:
        <>
            {/*<Navbar />*/}
            <div className="col-12">
                <div id="notfound">
                    <div className="notfound">
                        <div className="404logo">
                            <img id="big-logo" src={dados_config['content'] !== undefined && dados_config.content[0].logo.url !== '' ? dados_config.content[0].logo.url : repo_logo_link("logo.svg")} alt={dados_config.info !== undefined ? dados_config.info.name : Hosts.webSiteTitle} title={dados_config.info !== undefined ? dados_config.info.name : Hosts.webSiteTitle} className="svg-logo img-fluid" />
                        </div>
                        <div className="notfound-404">
                            <h1>404</h1>
                        </div>
                        <h2>{language[lang].not_found1}</h2>
                        <p>{language[lang].not_found2}</p>
                        <p>
                            <a href="./" title="Home">{language[lang].not_found3}</a>
                        </p>
                    </div>
                </div>
            </div>
            {/*<Footer />*/}
        </>
    )
}