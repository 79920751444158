import React, { useState, useEffect, useRef } from 'react';
//import { useLocation } from 'react-router-dom';

//import { getTokenInfo } from '../../UseToken';

import Slider from "./Slider/SliderLanding";
import Navbar from "./Navbar/Navbar_00";
import ASSECForm from "./ASSEC_Form/ASSECForms_00";
import HomeContent from "./WEBContent/HomeContent/HomeContent_00";
import Footer from "./Footer/Footer_00";

import "./Pagina_04.css";

import * as Hosts from '../../Hosts';

import {
  set_language,
  reqGET,
  translate,
  content_language
} from '../../Utils';

import Loader from '../../loader';

const Pagina = props => {
  
  //const { pathname } = useLocation();

	const [, set_dados] = useState(null)
	const dados_config = useRef({})

  //const [product_selected, set_product_selected] = useState('');

  const data_interface = {
    id: '',
    info: {},
    content: {
        childs: []
    }
  }
  const navbar = useRef(data_interface);

  const [isloading, setLoading] = useState(true);

  const [lang, set_lang] = useState('pt');
  const [/*lang_id*/, set_lang_id] = useState('1');

  const [has_banner, set_has_banner] = useState(false)
  // const [has_mote, set_has_mote] = useState(false)
  // const [has_news, set_has_news] = useState(false)
  // const [has_gallery, set_has_gallery] = useState(false)
  // const [has_partners, set_has_partners] = useState(false)
  // const [has_services, set_has_services] = useState(false)
  // const [has_products, set_has_products] = useState(false)
  // const [has_brands, set_has_brands] = useState(false)
  // const [has_statistics, set_has_statistics] = useState(false)

  const [/*refresh*/, setRefresh] = useState(0);

  let getInfo = async () => {
      await reqGET(`${Hosts.SIMCore_API}/web/structure/${Hosts.companycode}`)
        .then(async res => {
            for await (let v of res.data) {
            // res.data.forEach((v) => {
                if (v.info.principal === true) {
                  if (lang !== 'pt') {
                      for await (let cl of content_language) {
                      // content_language.forEach((cl) => {
                          let x = 0
                          if (cl.code === lang) {
                            v.content.childs.forEach((el) => {
                                if (el.id_content !== '') {
                                    if (el.children !== undefined) {
                                        x = x - el.children.length
                                        //for await (let el_c of el.children) {
                                        el.children.forEach(async (el_c) => {
                                            await reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/${el_c.id_content}`)
                                                .then(res_3 => {
                                                    if (res_3.data.length > 0) {
                                                        if (res_3.data[0].info.language.length > 0) {
                                                            if (res_3.data[0].info.language[cl.id] !== undefined && res_3.data[0].info.language[cl.id] !== null) {
                                                                if (res_3.data[0].info.language[cl.id]['name'] !== '') {
                                                                    el_c.title = res_3.data[0].info.language[cl.id]['name'];

                                                                    x++
                                                                    if (v.content.childs.length === x) {
                                                                        setRefresh(refresh => refresh + 1)
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        else {
                                                            x++
                                                            if (v.content.childs.length === x) {
                                                                setRefresh(refresh => refresh + 1)
                                                            }
                                                        }
                                                    }
                                                    else {
                                                        x++
                                                        if (v.content.childs.length === x) {
                                                            setRefresh(refresh => refresh + 1)
                                                        }
                                                    }
                                                })
                                        })
                                    }

                                    reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/${el.id_content}`)
                                        .then(res_2 => {
                                            if (res_2.data.length > 0) {
                                                if (res_2.data[0].info.language.length > 0) {
                                                    if (res_2.data[0].info.language[cl.id] !== undefined && res_2.data[0].info.language[cl.id] !== null) {
                                                        if (res_2.data[0].info.language[cl.id]['name'] !== '') {
                                                            el.title = res_2.data[0].info.language[cl.id]['name'];

                                                            x++
                                                            if (v.content.childs.length === x) {
                                                                setRefresh(refresh => refresh + 1)
                                                            }
                                                        }
                                                    }
                                                }
                                                else {
                                                    x++
                                                    if (v.content.childs.length === x) {
                                                        setRefresh(refresh => refresh + 1)
                                                    }
                                                }
                                            }
                                            else {
                                                x++
                                                if (v.content.childs.length === x) {
                                                    setRefresh(refresh => refresh + 1)
                                                }
                                            }
                                        })


                                } else {
                                    if (el['language'] !== undefined && el['language'] !== null) {
                                        if (el.language[cl.id] !== undefined && el.language[cl.id]['title'] !== undefined && el.language[cl.id]['title'] !== '') {
                                            el.title = el.language[cl.id]['title']

                                            x++
                                            if (v.content.childs.length === x) {
                                                setRefresh(refresh => refresh + 1)
                                            }
                                        }
                                    }
                                }
                            })

                          }
                      }

                  }

                  set_dados(navbar.current = v);
                }
            }

        })
        .catch(erro => console.log('ERROR', erro))
  }

  /*const getConfig = async (id) => {

    if(id !== undefined && id !== null && id !== '') {

      await reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/${id}`)
        .then(async res => {
          
          if(res.data.length > 0) {

            set_dados(dados_config.current = res.data[0])

            for await (let el of res.data[0].content[0].components) {
              if(el.info.tag === 'banner' && el.info.active) {
                set_has_banner(true)
              }
              // if(el.info.tag === 'mote' && el.info.active) {
              //   set_has_mote(true)
              // }
              // if(el.info.tag === 'news' && el.info.active) {
              //   set_has_news(true)
              // }
              // if(el.info.tag === 'gallery' && el.info.active) {
              //   set_has_gallery(true)
              // }
              // if(el.info.tag === 'partner' && el.info.active) {
              //   set_has_partners(true)
              // }
              // if(el.info.tag === 'service' && el.info.active) {
              //   set_has_services(true)
              // }
              // if(el.info.tag === 'product' && el.info.active) {
              //   set_has_products(true)
              // }
              // if(el.info.tag === 'brand' && el.info.active) {
              //   set_has_brands(true)
              // }
              // if(el.info.tag === 'statistics' && el.info.active) {
              //   set_has_statistics(true)
              // }
            }
        
          }

        })
        .catch(erro => console.log('Erro', erro))

    }
    else {
      await reqGET(`${Hosts.SIMCore_API}/web/content/type/${Hosts.companycode}/landingpage/available`)
        .then(async res => {

          if(res.data.length > 0) {

            set_dados(dados_config.current = res.data[0])

            for await (let el of res.data[0].content[0].components) {
              if(el.info.tag === 'banner' && el.info.active) {
                set_has_banner(true)
              }
              // if(el.info.tag === 'mote' && el.info.active) {
              //   set_has_mote(true)
              // }
              // if(el.info.tag === 'news' && el.info.active) {
              //   set_has_news(true)
              // }
              // if(el.info.tag === 'gallery' && el.info.active) {
              //   set_has_gallery(true)
              // }
              // if(el.info.tag === 'partner' && el.info.active) {
              //   set_has_partners(true)
              // }
              // if(el.info.tag === 'service' && el.info.active) {
              //   set_has_services(true)
              // }
              // if(el.info.tag === 'product' && el.info.active) {
              //   set_has_products(true)
              // }
              // if(el.info.tag === 'brand' && el.info.active) {
              //   set_has_brands(true)
              // }
              // if(el.info.tag === 'statistics' && el.info.active) {
              //   set_has_statistics(true)
              // }
            }
        
          }

        })
        .catch(erro => console.log('Erro', erro))
    }
  }*/

  useEffect(() => {

    if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
      set_lang('pt')
      set_language('pt')
      set_lang_id('1')
    }
    else {
      set_lang(localStorage.getItem('lang'))
      content_language.forEach(el => {
        if (el.code === localStorage.getItem('lang')) {
          set_lang_id(el.id)
        }
      })
    }

    (async () => {

      if(props.dados_config !== undefined && props.dados_config !== null && props.dados_config !== '') {
        set_dados(dados_config.current = props.dados_config)
        for await (let el of props.dados_config.content[0].components) {
          if(el.info.tag === 'banner' && el.info.active) {
              set_has_banner(true)
          }
        }
      }
      await getInfo()

      /*if((getTokenInfo()?.profile.includes('Administrador') || getTokenInfo()?.profile.includes('Gestor')) && pathname.includes('preview')) {

        let id_aux = pathname.split('/').pop()
        if(id_aux !== '') {
          await getConfig(id_aux)
        }

      }
      else {
        await getConfig()   
      }*/
      
      setLoading(false)
    })()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  window.addEventListener('popstate', function (e) {
    //console.error('eeeeeeee', e)
    var state = e.state;
    if (state !== null) {
      //load content with ajax
    }
  });

  return (
    isloading ?
      <Loader />
    :
      <div className={'welcome'}>
        <h2 className='h2-heading visually-hidden-focusable'>
          {
            dados_config.current.content !== undefined && dados_config.current.content[0].title !== undefined ?
              translate(dados_config.current.content[0].title, lang)
            :
              Hosts.webSiteTitle
          }
        </h2>

        <Navbar pag_welcome={true} dados_config={dados_config.current} dados_navbar={navbar.current} />

        <div className='position-relative'>
          {
            has_banner ?
              <Slider pag_welcome={true} dados_config={dados_config.current} />
            :
              null
          }

          <ASSECForm pag_welcome={true} dados_config={dados_config.current} />
        </div>

        {
          dados_config.current.content[0].components.map((el_content, k_content) => {
            return (
              <HomeContent key={k_content} pag_welcome={true} dados_config={dados_config.current} dados_component={el_content} tag_page={'04'} />
            )
          })
        }

        <Footer pag_welcome={true} dados_config={dados_config.current} dados_navbar={navbar.current} />

      </div>
  )
}

export default Pagina;