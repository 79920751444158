import React, { useState, useEffect, useRef } from 'react';

import { /*reqGET, repo_img_link,*/ content_language, translate } from '../../../../Utils';

//import * as Hosts from "../../../../Hosts";

import { language } from '../Language';

import parse from 'html-react-parser';
//import AssecImage from "../../ASSEC_Image/Image"

import Loader from '../../../../loader';
import "./HomePricing_01.css"

const HomePricing = (props) => {

	const [, set_dados] = useState(null)
	const pricing_list = useRef([])
	const dados_config = useRef([])

    const [pricing_title, set_pricing_title] = useState('')
    const [pricing_subtitle, set_pricing_subtitle] = useState('')

    const [isloading, setLoading] = useState(true);

    const [lang, set_lang] = useState(localStorage.getItem('lang'));
    const [lang_id, set_lang_id] = useState('1');

    const handle_change_pricing_selected = (name) => {

        //document.getElementById("input_prod").value = name

        topFunction()

    }

    function topFunction() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    useEffect(() => {

        if(props.dados_config !== undefined && props.dados_config !== null && props.dados_config !== '') {
            set_dados(dados_config.current = props.dados_config)
            
            for (let el of dados_config.current.content[0].components) {

                if(el.info.tag === 'pricing' && el.info.active) {
                    set_pricing_title(translate(el.info.title, lang))
                    set_pricing_subtitle(translate(el.info.subtitle, lang))

                    set_dados(pricing_list.current = dados_config.current.content[0].pricings_list)
                    setLoading(false)
                }
            }
        }

        if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                    set_lang_id(el.id)
                }
            })
        }

        /*
        let getProducts = async () => {
            reqGET(`${Hosts.SIMCore_API}/web/content/type/${Hosts.companycode}/product/name`)
                .then(res => {

                    set_dados(products_list.current = res.data)
                    setLoading(false)

                }).catch(erro => console.log('Erro1', erro))
        }
        getProducts()
        */
    }, [props, lang])

    return (
        isloading ?
            <Loader />
		:
            <section id="homepricing_01" className='div-component'>
                <div className="container">
                    <div className="row">
                        <div className="col-12 title-properties">
                            <h3 className="titulo-pp">
                                {pricing_title}
                            </h3>
                            {
                                pricing_subtitle !== '' ?
                                    <h4 className={"subtitulo-pp"}>
                                        {pricing_subtitle}
                                    </h4>
                                :
                                    null
                            }
                            <hr></hr>
                        </div>
                        {
                            pricing_list.current !== undefined && pricing_list.current !== null && pricing_list.current.length > 0 ?
                                <>
                                    <div className="col-12">
                                        <div className="row g-4 justify-content-center">
                                        {
                                        pricing_list.current.map((v, k) => {
                                            if(k < 9) {
                                                return (
                                                    <div className={"col-12 col-sm-6 col-lg-4" + (v.info.status === 'with_emphasis' ? ' pricing-highlight' : '')} key={k}>
                                                        <div className="card">
                                                            <div className="card-body">
                                                                {
                                                                    v.content[0].title !== undefined && v.content[0].title !== '' ?
                                                                        <h3 className="pricing-title">
                                                                            {parse(lang === 'pt' || v.content[0].language === undefined ? v.content[0].title : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].title === undefined || v.content[0].language[lang_id].title === '' ? v.content[0].title : v.content[0].language[lang_id].title))}
                                                                        </h3>
                                                                    :
                                                                        null
                                                                }
                                                                {
                                                                    v.content[0].price !== undefined && v.content[0].price.final !== undefined && v.content[0].price.final !== '' ?
                                                                        <div className='pricing-value'>
                                                                            {v.content[0].price.final}
                                                                            <span className='price-symbol'>&euro;</span>
                                                                        </div>
                                                                    :
                                                                        null
                                                                }
                                                                {
                                                                    v.content[0].price !== undefined && v.content[0].price.mounth !== undefined && v.content[0].price.mounth !== '' ?
                                                                        <div className='pricing-value'>
                                                                            {v.content[0].price.mounth}
                                                                            <span className='price-symbol'>
                                                                                &euro;
                                                                            </span>
                                                                            <span className='price-type'>
                                                                                &#47;{language[lang].month}
                                                                            </span>
                                                                        </div>
                                                                    :
                                                                        null
                                                                }
                                                                {
                                                                    v.content[0].price !== undefined && v.content[0].price.year !== undefined && v.content[0].price.year !== '' ?
                                                                        <div className='pricing-value'>
                                                                            {v.content[0].price.year}
                                                                            <span className='price-symbol'>
                                                                                &euro;
                                                                            </span>
                                                                            <span className='price-type'>
                                                                                &#47;{language[lang].year}
                                                                            </span>
                                                                        </div>
                                                                    :
                                                                        null
                                                                }
                                                                {
                                                                    v.content[0].abstract !== undefined && v.content[0].abstract !== '' ?
                                                                        <div className='pricing-abstract'>
                                                                            {parse((lang === 'pt' || v.content[0].language === undefined ? v.content[0].abstract : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].abstract === undefined || v.content[0].language[lang_id].abstract === '' ? v.content[0].abstract : v.content[0].language[lang_id].abstract)))}
                                                                        </div>
                                                                    :
                                                                         null
                                                                }
                                                                {
                                                                    v.content[0].text !== undefined && v.content[0].text !== '' ?
                                                                        <div className="pricing-features">
                                                                            {parse((lang === 'pt' || v.content[0].language === undefined ? v.content[0].text : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].text === undefined || v.content[0].language[lang_id].text === '' ? v.content[0].text : v.content[0].language[lang_id].text)))}
                                                                        </div>
                                                                    :
                                                                        null
                                                                }
                                                                {
                                                                    dados_config.current.info?.form?.info?.tag === 'form-produto' && dados_config.current.info?.template?.info?.tag === 'page-02' ?
                                                                        <button className="btn btn-primary btn-pricing mt-3" onClick={(e) => handle_change_pricing_selected(v.id)}>
                                                                            {language[lang].learn_more}
                                                                        </button>
                                                                    :
                                                                        null
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            } else {
                                                return null
                                            }
                                        })
                                        }
                                        </div>
                                    </div>
                                </>
                            :
                                null
                        }
                    </div>
                </div>
            </section>
    )
};
export default HomePricing;