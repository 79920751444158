import React, { useState, useEffect } from 'react';

//import ReactHtmlParser/*, { processNodes, convertNodeToElement, htmlparser2 }*/ from 'react-html-parser';

import * as Hosts from '../../../Hosts';
import { /*reqGET,*/ set_language, content_language, repo_logo_link} from '../../../Utils';

//import Navbar from '../Navbar/Navbar';
//import Footer from '../Footer/Footer';


import Loader from '../../../loader';
import { language } from '../WEBContent/Language';

export default function Normal(props) {

    const [dados_config, set_dados_config] = useState({});

    const [lang, set_lang] = useState('pt');
    const [, set_lang_id] = useState('1');

    const [isloading, setLoading] = useState(true);

    useEffect(() => {

        if(props.info !== undefined && props.info !== null && props.info !== '') {
            console.log('props.info', props.info)
            set_dados_config(props.info.state.dados_config)
        }

        if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_language('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                    set_lang_id(el.id)
                }
            })
        }

        setLoading(false) 
        
    }, [props]);

    return (isloading ?
        <Loader />
    :
        <>
            {/*<Navbar />*/}
            <div className="col-12">
                <div id="maintenance">
                    <div className="maintenance">
                        <div className="maintenance-logo">
                            <img id="big-logo" src={dados_config['content'] !== undefined && dados_config['content'].lenght > 0 && dados_config.content[0].logo.url !== '' ? dados_config.content[0].logo.url : repo_logo_link("logo.svg")} alt={dados_config.info !== undefined ? dados_config.info.name : Hosts.webSiteTitle} title={dados_config.info !== undefined ? dados_config.info.name : Hosts.webSiteTitle} className="svg-logo img-fluid" />
                        </div>

                        <div className="maintenance-txt">
                            <h1>{language[lang].maintenance}</h1>
                        </div>

                        <h2>{language[lang].maintenance_txt}</h2>

                        <div className='maintenance-social'>
                            {
                            dados_config.content !== undefined ?
                                dados_config.content[0].social.map((el, k) => {
                                    if(el.link !== '') {
                                        return (
                                            <a key={k} className="mr-3" href={el.link} title={el.info.name} target="_blank" rel="noreferrer" >
                                            <i className={"bi bi-" + el.info.icon.bi}></i>
                                            </a>
                                        )
                                    }
                                    else {
                                        return null
                                    }
                                })
                            :
                                null
                            }
                        </div>
                    </div>
                </div>
            </div>
            {/*<Footer />*/}
        </>
    )
}