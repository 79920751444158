import React, { useState, useEffect, useRef } from 'react'
import { useParams } from "react-router-dom";

import * as Hosts from '../../../Hosts';
import { reqGET, content_language } from '../../../Utils';
import { language } from '../WEBContent/Language';
import { getTokenInfo } from '../../../UseToken';

import './Breadcrumb.css';

export default function Breadcrumb(props) {

    const { pag_id, slug, article_id, gallery_id, preview_id, routes_id, interest_id, insight_id, partner_id, accommodation_id, restaurant_id, form_slug } = useParams();
    const [isloading, setLoading] = useState(true);

    const [lang, set_lang] = useState('pt');
    const [lang_id, set_lang_id] = useState('1');

    //const [content, set_content] = useState(data_interface);
    const [menu, set_menu] = useState([]);

    const [, set_dados] = useState(null);
	const content = useRef(null);

    useEffect(() => {

        if(props.info !== undefined && props.info !== null && props.info !== '') {
            set_menu(props.info.state.dados_navbar)
            set_dados(content.current = props.info.state.dados_content)
        }

        const data_interface = {
            id: '',
            info: {},
            content: {}
        }        

        set_lang(localStorage.getItem('lang'))
        content_language.forEach(el => {
            if (el.code === localStorage.getItem('lang')) {
            set_lang_id(el.id)
            }
        })

        let getPreviewInfoId = async (id) => {
            return await reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/${id}`)
                .then(res => { 
                    if(res.data.length > 0) {

                        if(getTokenInfo()['profile'] !== '') {
                            res.data[0].info['preview'] = true
                            //set_content(res.data[0])
                            set_dados(content.current = res.data[0])
                            //setLoading(false)
                            
                            return true
                        }
                        else {
                        }
                    }
                })
                .catch(erro => console.log('ERROR', erro))
        }

        let getInfoId = async (id) => {
            return await reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/${id}`)
                .then(res => { 
                    if(res.data.length > 0) {

                        var hoje = new Date();
                        let status = 0
                        if(res.data[0].info.date.published !== undefined && res.data[0].info.date.published !== '') {
                            var date_p = new Date(res.data[0].info.date.published);

                            status = 1
                            if(hoje >= date_p) 
                                status = 2
                        }

                        if(status !== 2) {
                            //res.data[0].content[0] = {text: 'rascunho ou agendado'}
                            res.data[0].info.layout = '404'
                        }

                        //set_content(res.data[0]) 
                        set_dados(content.current = res.data[0])

                        return true
                    }
                })
                .catch(erro => console.log('ERROR', erro))
        }
    
        let getInfoSlug = async (slug) => {
            return await reqGET(`${Hosts.SIMCore_API}/web/content/slug/${Hosts.companycode}/${slug}`)
                .then(res => { 
                    if(res.data.length > 0) {

                        var hoje = new Date();
                        let status = 0
                        if(res.data[0].info.date.published !== undefined && res.data[0].info.date.published !== '') {
                            var date_p = new Date(res.data[0].info.date.published);

                            status = 1
                            if(hoje >= date_p) 
                                status = 2
                        }

                        if(status !== 2) {
                            //res.data[0].content[0] = {text: 'rascunho ou agendado'}
                            res.data[0].info.layout = '404'
                        }
                        //set_content(res.data[0]) 
                        set_dados(content.current = res.data[0])

                        return true
                        //setLoading(false)
                    }
                    else {
                        //console.log('slug: ', slug)
                        //set_content({id: '', info: {type: slug}})
                        set_dados(content.current = {id: '', info: {type: slug}})
                        //setLoading(false)
                        return true
                    }
                })
                .catch(erro => console.log('ERROR', erro))
        }

        let getAll = async (type) => {
            let aux = data_interface
            aux.info['type'] = type
            aux.info['layout'] = 'all'
            
            //set_content(content)
            set_dados(content.current = aux)

            return true
        }

        if(preview_id !== undefined && preview_id !== 'undefined' && preview_id !== '') {
            getPreviewInfoId(preview_id)
                .then((res) => {
                    if(res === true){
                        setLoading(false)
                    }
                })
        }
        else if(pag_id !== undefined && pag_id !== 'undefined' && pag_id !== '') {
            getInfoId(pag_id)
                .then((res) => {
                    if(res === true){
                        setLoading(false)
                    }
                })
        }
        else if(slug !== undefined && slug !== 'undefined' && slug !== '')  {
            getInfoSlug(slug)
                .then((res) => {
                    if(res === true){
                        setLoading(false)
                    }
                })
        }
        else if(article_id !== undefined && article_id !== 'undefined' && article_id !== '')  {
            if(article_id === 'all') {
                getAll('news')
                    .then((res) => {
                        if(res === true){
                            setLoading(false)
                        }
                    })
            }
            else {
                
                getInfoId(article_id)
                    .then((res) => {
                        if(res === true){
                            setLoading(false)
                        }
                    })
            }
        }
        else if(insight_id !== undefined && insight_id !== 'undefined' && insight_id !== '')  {
            if(insight_id === 'all') {
                getAll('insight')
                    .then((res) => {
                        if(res === true){
                            setLoading(false)
                        }
                    })
            }
            else {
                getInfoId(insight_id)
                    .then((res) => {
                        if(res === true){
                            setLoading(false)
                        }
                    })
            }
        }
        else if(partner_id !== undefined && partner_id !== 'undefined' && partner_id !== '')  {
            if(partner_id === 'all') {
                getAll('partner')
                    .then((res) => {
                        if(res === true){
                            setLoading(false)
                        }
                    })
            }
            else {
                getInfoId(partner_id)
                    .then((res) => {
                        if(res === true){
                            setLoading(false)
                        }
                    })
            }
        }
        else if(routes_id !== undefined && routes_id !== 'undefined' && routes_id !== '')  {
            if(routes_id === 'all') {
                getAll('routes')
                    .then((res) => {
                        if(res === true){
                            setLoading(false)
                        }
                    })
            }
            else {
                getInfoId(routes_id)
                    .then((res) => {
                        if(res === true){
                            setLoading(false)
                        }
                    })
            }
        }
        else if(accommodation_id !== undefined && accommodation_id !== 'undefined' && accommodation_id !== '')  {
            if(accommodation_id === 'all') {
                getAll('accommodation')
                    .then((res) => {
                        if(res === true){
                            setLoading(false)
                        }
                    })
            }
            else {
                getInfoId(accommodation_id)
                    .then((res) => {
                        if(res === true){
                            setLoading(false)
                        }
                    })
            }
        }
        else if(restaurant_id !== undefined && restaurant_id !== 'undefined' && restaurant_id !== '')  {
            if(restaurant_id === 'all') {
                getAll('restaurant')
                    .then((res) => {
                        if(res === true){
                            setLoading(false)
                        }
                    })
            }
            else {
                getInfoId(restaurant_id)
                    .then((res) => {
                        if(res === true){
                            setLoading(false)
                        }
                    })
            }
        }
        else if(interest_id !== undefined && interest_id !== 'undefined' && interest_id !== '')  {
            if(interest_id === 'all') {
                getAll('interest')
                    .then((res) => {
                        if(res === true){
                            setLoading(false)
                        }
                    })
            }
            else {
                getInfoId(interest_id)
                    .then((res) => {
                        if(res === true){
                            setLoading(false)
                        }
                    })
            }
        }
        else if(gallery_id !== undefined && gallery_id !== 'undefined' && gallery_id !== '')  {
            if(gallery_id === 'all') {
                getAll('gallery')
                    .then((res) => {
                        if(res === true){
                            setLoading(false)
                        }
                    })
            }
            else {
                getInfoId(gallery_id)
                    .then(() => {
                        setLoading(false)
                    })
            }
        }
        else if(form_slug !== undefined && form_slug !== 'undefined' && form_slug !== '')  {
            getAll('career')
                .then(() => {
                    setLoading(false)
                })
        }


    }, [props, pag_id, slug, article_id, gallery_id, preview_id, interest_id, insight_id, routes_id, partner_id, accommodation_id, restaurant_id, form_slug]);

    return (isloading ?
		null
	:
        <>
            <nav aria-label="breadcrumb">
                
                <ol className="breadcrumb mb-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 " style={{display: 'flex'}}>
                                <li className="breadcrumb-item"><a href={Hosts.WEB_SITE_URI}>Home</a></li>
                                {
                                content.current.info.type === 'news' || content.current.info.type === 'insight' || content.current.info.type === 'routes' || content.current.info.type === 'partner' || content.current.info.type === 'accommodation' || content.current.info.type === 'restaurant' || content.current.info.type === 'career' || content.current.info.type === 'gallery' ? 
                                    content.current.id !== '' ?
                                        (() => {
                                            switch (content.current.info.type) {
                                                case 'news':
                                                    return (
                                                        <>
                                                            <li className="breadcrumb-item text-truncate"><a href={Hosts.WEB_SITE_URI + 'article/all'}>{language[lang].news_turistrela}</a></li>
                                                            <li className="breadcrumb-item active text-truncate" aria-current="page">
                                                                {lang === 'pt' || content.current.content[0].language === undefined ? content.current.content[0].title : (content.current.content[0].language[lang_id] === undefined || content.current.content[0].language[lang_id] === null || content.current.content[0].language[lang_id].title === undefined || content.current.content[0].language[lang_id].title === '' ? content.current.content[0].title : content.current.content[0].language[lang_id].title)}
                                                            </li>
                                                        </>
                                                    )
                                                case 'insight':
                                                    return (
                                                        <>
                                                            <li className="breadcrumb-item text-truncate"><a href={Hosts.WEB_SITE_URI + 'insight/all'}>{language[lang].insights_turistrela}</a></li>
                                                            <li className="breadcrumb-item active text-truncate" aria-current="page">
                                                                {lang === 'pt' || content.current.content[0].language === undefined ? content.current.content[0].title : (content.current.content[0].language[lang_id] === undefined || content.current.content[0].language[lang_id] === null || content.current.content[0].language[lang_id].title === undefined || content.current.content[0].language[lang_id].title === '' ? content.current.content[0].title : content.current.content[0].language[lang_id].title)}
                                                            </li>
                                                        </>
                                                    )
                                                case 'routes':
                                                    return (
                                                        <>
                                                            <li className="breadcrumb-item text-truncate"><a href={Hosts.WEB_SITE_URI + 'routes/all'}>{language[lang].routes}</a></li>
                                                            <li className="breadcrumb-item active text-truncate" aria-current="page">
                                                                {lang === 'pt' || content.current.content[0].language === undefined ? content.current.content[0].title : (content.current.content[0].language[lang_id] === undefined || content.current.content[0].language[lang_id] === null || content.current.content[0].language[lang_id].title === undefined || content.current.content[0].language[lang_id].title === '' ? content.current.content[0].title : content.current.content[0].language[lang_id].title)}
                                                            </li>
                                                        </>
                                                    )
                                                case 'partner':
                                                    return (
                                                        <>
                                                            <li className="breadcrumb-item text-truncate"><a href={Hosts.WEB_SITE_URI + 'partner/all'}>{language[lang].content_turistrela}</a></li>
                                                            <li className="breadcrumb-item active text-truncate" aria-current="page">
                                                                {lang === 'pt' || content.current.content[0].language === undefined ? content.current.content[0].title : (content.current.content[0].language[lang_id] === undefined || content.current.content[0].language[lang_id] === null || content.current.content[0].language[lang_id].title === undefined || content.current.content[0].language[lang_id].title === '' ? content.current.content[0].title : content.current.content[0].language[lang_id].title)}
                                                            </li>
                                                        </>
                                                    )
                                                case 'gallery':
                                                    return (
                                                        <>
                                                            <li className="breadcrumb-item text-truncate"><a href={Hosts.WEB_SITE_URI + 'gallery/all'}>{language[lang].galleries_turistrela}</a></li>
                                                            <li className="breadcrumb-item active text-truncate" aria-current="page">
                                                                {lang === 'pt' || content.current.content[0].language === undefined ? content.current.content[0].title : (content.current.content[0].language[lang_id] === undefined || content.current.content[0].language[lang_id] === null || content.current.content[0].language[lang_id].title === undefined || content.current.content[0].language[lang_id].title === '' ? content.current.content[0].title : content.current.content[0].language[lang_id].title)}
                                                            </li>
                                                        </>
                                                    )
                                                case 'accommodation':
                                                    return (
                                                        <>
                                                            <li className="breadcrumb-item active text-truncate">{language[lang].oferta_turistica}</li>
                                                            <li className="breadcrumb-item text-truncate"><a href={Hosts.WEB_SITE_URI + 'accommodation/all'}>{language[lang].alojamento}</a></li>
                                                            <li className="breadcrumb-item active text-truncate" aria-current="page">
                                                                {lang === 'pt' || content.current.content[0].language === undefined ? content.current.content[0].title : (content.current.content[0].language[lang_id] === undefined || content.current.content[0].language[lang_id] === null || content.current.content[0].language[lang_id].title === undefined || content.current.content[0].language[lang_id].title === '' ? content.current.content[0].title : content.current.content[0].language[lang_id].title)}
                                                            </li>
                                                        </>
                                                    )
                                                case 'restaurant':
                                                    return (
                                                        <>
                                                            <li className="breadcrumb-item active text-truncate">{language[lang].oferta_turistica}</li>
                                                            <li className="breadcrumb-item text-truncate 00 "><a href={Hosts.WEB_SITE_URI + 'restaurant/all'}>{language[lang].restauracao}</a></li>
                                                            <li className="breadcrumb-item active text-truncate" aria-current="page">
                                                                {lang === 'pt' || content.current.content[0].language === undefined ? content.current.content[0].title : (content.current.content[0].language[lang_id] === undefined || content.current.content[0].language[lang_id] === null || content.current.content[0].language[lang_id].title === undefined || content.current.content[0].language[lang_id].title === '' ? content.current.content[0].title : content.current.content[0].language[lang_id].title)}
                                                            </li>
                                                        </>
                                                    )
                                                default:
                                                    return null
                                            }
                                        })()
                                    : 
                                        (() => {
                                            switch (content.current.info.type) {
                                                case 'news':
                                                    return (
                                                        <>
                                                            <li className="breadcrumb-item text-truncate"><a href={Hosts.WEB_SITE_URI + 'article/all'}>{language[lang].news_turistrela}</a></li>
                                                        </>
                                                    )
                                                case 'insight':
                                                    return (
                                                        <>
                                                            <li className="breadcrumb-item text-truncate"><a href={Hosts.WEB_SITE_URI + 'insight/all'}>{language[lang].insights_turistrela}</a></li>
                                                        </>
                                                    )
                                                case 'routes':
                                                    return (
                                                        <>
                                                            <li className="breadcrumb-item text-truncate"><a href={Hosts.WEB_SITE_URI + 'routes/all'}>{language[lang].routes}</a></li>
                                                        </>
                                                    )
                                                case 'partner':
                                                    return (
                                                        <>
                                                            <li className="breadcrumb-item text-truncate"><a href={Hosts.WEB_SITE_URI + 'partner/all'}>{language[lang].content_turistrela}</a></li>
                                                        </>
                                                    )
                                                case 'gallery':
                                                    return (
                                                        <>
                                                            <li className="breadcrumb-item text-truncate"><a href={Hosts.WEB_SITE_URI + 'gallery/all'}>{language[lang].galleries_turistrela}</a></li>
                                                        </>
                                                    )
                                                case 'career':
                                                    return (
                                                        <>
                                                            <li className="breadcrumb-item text-truncate"><a href={Hosts.WEB_SITE_URI + 'form/carreiras'}>{language[lang].careers_turistrela}</a></li>
                                                        </>
                                                    )
                                                case 'accommodation':
                                                    return (
                                                        <>
                                                            <li className="breadcrumb-item active text-truncate">{language[lang].oferta_turistica}</li>
                                                            <li className="breadcrumb-item text-truncate"><a href={Hosts.WEB_SITE_URI + 'accommodation/all'}>{language[lang].alojamento}</a></li>
                                                        </>
                                                    )
                                                case 'restaurant':
                                                    return (
                                                        <>
                                                            <li className="breadcrumb-item active text-truncate">{language[lang].oferta_turistica}</li>
                                                            <li className="breadcrumb-item text-truncate"><a href={Hosts.WEB_SITE_URI + 'restaurant/all'}>{language[lang].restauracao}</a></li>
                                                        </>
                                                    )
                                                default:
                                                    return null
                                            }
                                        })()
                                :
                                    <>
                                        {
                                        menu.content !== undefined ?
                                            menu.content.childs.map((v, key) => {
                                                return (
                                                    content.current.info['parent'] !== undefined && v.id_content === content.current.info.parent ?
                                                        <li key={key} className="breadcrumb-item active text-truncate" aria-current="page">
                                                            {lang === 'pt' || v.language === undefined ? v.title : (v.language[lang_id] === undefined || v.language[lang_id].title === undefined || v.language[lang_id].title === '' ? v.title : v.language[lang_id].title)}
                                                        </li>
                                                        
                                                    :
                                                        v.link === content.current.info.type ?
                                                            <li key={key} className="breadcrumb-item active text-truncate" aria-current="page">
                                                                {lang === 'pt' || v.language === undefined ? v.title : (v.language[lang_id] === undefined || v.language[lang_id].title === undefined || v.language[lang_id].title === '' ? v.title : v.language[lang_id].title)}
                                                            </li>
                                                        :
                                                            null
                                                )
                                            })
                                        : 
                                            null
                                        }
                                        {
                                        content.current.info.name !== undefined ?
                                            <li className="breadcrumb-item active text-truncate" aria-current="page">
                                                {/*content.current.info.name*/}
                                                {lang === 'pt' || content.current.info.language === undefined ? content.current.info.name : (content.current.info.language[lang_id] === undefined || content.current.info.language[lang_id].name === undefined || content.current.info.language[lang_id].name === '' ? content.current.info.name : content.current.info.language[lang_id].name)}
                                            </li> 
                                        : 
                                            null
                                        }
                                        {/*
                                            //páginas manuais (caso seja necessário)
                                            content.info.type === 'accesses' || content.info.type === 'livecam' ? 

                                                <li className="breadcrumb-item active" aria-current="page">{language[lang][content.info.type]}</li>
                                                
                                            : 
                                                ''
                                        */}  
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </ol>
                
            </nav>
        </>

    )
}
