import React, { useState, useEffect, useRef } from 'react'

// import Loader from '../../../loader';

import HomePartners01 from './HomePartners_01';
import HomePartners02 from './HomePartners_02';
import HomePartners03 from './HomePartners_03';

export default function Content(props) {

	const [, set_dados] = useState(null)
	const dados_config = useRef({})
	const template = useRef({})
	const layout = useRef('')
	const content = useRef({})
	const pw = useRef({})

    const [isloading, setLoading] = useState(true);

    useEffect(() => {

        if(props.dados_config !== undefined && props.dados_config !== null && props.dados_config !== '') {
            set_dados(dados_config.current = props.dados_config)
            set_dados(template.current = props.dados_config.info.template)
            set_dados(content.current = props.dados_config)
            set_dados(pw.current = props.pag_welcome)
            set_dados(layout.current = props.layout)

            setLoading(false)
        }

    }, [props]);

    return (isloading ?
		null
    :
        <>
            {
            layout.current === 'home-partners-01' ?
                <HomePartners01 pag_welcome={pw.current} dados_config={dados_config.current} />
            :
            layout.current === 'home-partners-02' ?
                <HomePartners02 pag_welcome={pw.current} dados_config={dados_config.current} />
            :
            layout.current === 'home-partners-03' ?
                <HomePartners03 pag_welcome={pw.current} dados_config={dados_config.current} />
            :
                <HomePartners01 pag_welcome={pw.current} dados_config={dados_config.current} />
            }
        </>
    )
}
