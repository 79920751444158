import React, { useState, useEffect, useRef } from 'react';

import './ASSECForms_03.css';

import { language } from '../WEBContent/Language';

import { 
  reqPOST,
  content_language, 
  translate, 
  functionSendMail 
} from '../../../Utils';

import * as Hosts from '../../../Hosts';

//import { facebook } from "@fortawesome/free-solid-svg-icons";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


import Swal from 'sweetalert2';

const AssecForm = (props) => {

  const [, set_dados] = useState(null)
	const dados_config = useRef({})
	const dados_inscricao = useRef('')

  const [lang, set_lang] = useState(localStorage.getItem('lang'));
  const [, set_lang_id] = useState('1');

  const [isloading, setLoading] = useState(true);

  useEffect(() => {

    if(props.dados_config !== undefined && props.dados_config !== null && props.dados_config !== '') {
      set_dados(dados_config.current = props.dados_config)
    }

    if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
      set_lang('pt')
      set_lang_id('1')

      setLoading(false)
    }
    else {
      set_lang(localStorage.getItem('lang'))
      content_language.forEach(el => {
        if (el.code === localStorage.getItem('lang')) {
          set_lang_id(el.id)
        }
      })

      setLoading(false)
    }

  }, [props])

  const [agree, setAgree] = useState(false);

  const checkboxHandler = () => {
    // if agree === true, it will be set to false
    // if agree === false, it will be set to true
    setAgree(!agree);
    // Don't miss the exclamation mark
  }
  const checkboxHandler_reload = (agree) => {
    //console.log('AGREE', agree)
    if (agree === true)
        return true
    else 
        return false
    // if agree === false, it will be set to true
    //setAgree(!agree);
    // Don't miss the exclamation mark
  }

  const change_inscricao_value = (e) => {
		const { /*name,*/ value } = e.target;

    set_dados(dados_inscricao.current = value)

  }

  const change_fileds_value = (e) => {
		const { /*name,*/ value, dataset } = e.target;
		const { order } = dataset;

    set_dados(dados_config.current.info.form.info.fields[order]['value'] = value)
    
    // console.log('dados_config.current.info.form.info.fields', JSON.parse(JSON.stringify(dados_config.current.info.form.info.fields)))
  }

  const send_form = async () => {

    let email_to = ''
    
    let dados_db = {
      id: '',
      info: {
        lp_id: dados_config.current.id,
        lp_name: dados_config.current.info.name,
        form_id: dados_config.current.info.form.id,
        form_name: dados_config.current.info.form.info.name,
        components: [],
        companycode: Hosts.companycode
      }
    } 

    if(dados_inscricao.current === '') {

      Swal.fire({
        title: language[lang].contact_form,
        text: language[lang].no_value,
        icon: 'warning',
        //showCancelButton: true,
        confirmButtonColor: '#08396a',
        //confirmButtonText: 'Tentar novamente',
      })

    }
    else {
      if(dados_inscricao.current !== '') {
        dados_db.info.components.push({
          name: 'Inscrição',
          value: dados_inscricao.current
        })
      }
    
      let verify = true
      for await (let el of dados_config.current.info.form.info.fields) {

        dados_db.info.components.push({
          name: el.name,
          value: el.value
        })

        if(el.name.toLowerCase() === 'email') {
          email_to = el.value
        }

        if(el.required && (el.value === undefined || el.value === null || el.value === '')) {
          verify = false
        }
      }

      if(verify && email_to !== '') {

        let mail_content = '<table width="600" cellpadding="0" cellspacing="0" border="0" align="0" class="0" style="width: 600px; height: 90px; padding: 15px 30px; background-color:' + Hosts.EMAIL_BACKGROUND_COLOR +';border:1px solid transparent;border-collapse:collapse;">' +
            '<tr style="border-collapse: collapse;">' +
                '<td align="left" class style="padding:40px; font-family:poppins, helvetica, arial, sans-serif; font-size:' + Hosts.EMAIL_TEXT_SIZE +'; line-height:' + Hosts.EMAIL_TEXT_LINE_HEIGHT  +'; color:' + Hosts.EMAIL_TEXT_COLOR +'">' +
                    '<div>'+
                        '<h4>'+ language[lang].contact_form +':</h4>'
        ;

        mail_content += '<span><b>Inscrição: </b><label>' + dados_inscricao.current + '</label></span><br/>';

        for await (let el of dados_config.current.info.form.info.fields) {
          mail_content += '<span><b>'+ el.name +': </b><label>' + el.value + '</label></span><br/>';
        }

        mail_content += '</div>'+
                '</td>' +
            '</tr>' +
        '</table>'

        // console.log('dados_db', dados_db)
        // return

        await reqPOST(Hosts.SIMCore_API + '/web/contact/form', dados_db)

        await functionSendMail(dados_config.current.content[0].contacts.email_form, [email_to], [dados_config.current.content[0].contacts.email_form], dados_config.current.info.name + ' - ' + language[lang].contact_form, mail_content, [], dados_config)
          .then((res) => {
              if(res === true) {
                
                dados_config.current.info.form.info.fields.forEach(el => {
                  el.value = ''
                })

                document.getElementById('agree').checked = false
                  setAgree(false)
                  //setRefresh(refresh + 1)
                  Swal.fire({
                      title: language[lang].contact_form,
                      text: language[lang].sent_successfully,
                      icon: 'success',
                      //showCancelButton: true,
                      confirmButtonColor: '#08396a',
                      //confirmButtonText: 'Tentar novamente',
                      })

              }
              else {
                  console.log('erro')
              }
          })

      }
      else {
        Swal.fire({
          title: language[lang].contact_form,
          text: language[lang].no_form_fields,
          icon: 'warning',
          //showCancelButton: true,
          confirmButtonColor: '#08396a',
          //confirmButtonText: 'Tentar novamente',
        })
      }
    }

  }

  return isloading ?
    null
  :
    <section id="assecform">
      <div className={"div-form 3333 text-white mt-lg-5 py-4 px-5"}>
        <div>
          <h2>{translate(dados_config.current.info.form.info.title, lang)}</h2>
          <p>{translate(dados_config.current.info.form.info.subtitle, lang)}</p>
        </div>
        <div className='fields'>
          {
          dados_config.current.info.form.info.tag === 'form-inscricao' ?
            <div className="mb-3">
              <input id={"input_inscricao"} className='form-control border-0 rounded-0 font-weight-bolder' style={{color: 'var(--primary)'}} data-order={0} type={'text'} name={'Inscrição'} placeholder={'Inscrição*'} value={dados_inscricao.current} onChange={(e) => change_inscricao_value(e)} required={true} />
            </div>
          :
            null
          }
          {
          dados_config.current.info.form.info.fields.map((el_f, k_f) => {
            if(el_f.type === 'text') {
              return (
                <div key={k_f} className="mb-3">
                  <input className='form-control border-0 rounded-0' data-order={k_f} type={el_f.type} name={el_f.name} placeholder={el_f.name + (el_f.required ? '*' : '')} value={el_f.value !== undefined ? el_f.value : ''} onChange={(e) => change_fileds_value(e)} required={el_f.required} />
                </div>
              )
            }
            else if(el_f.type === 'email') {
              return (
                <div key={k_f} className="mb-3">
                  <input className='form-control border-0 rounded-0' data-order={k_f} type={el_f.type} name={el_f.name} placeholder={el_f.name + (el_f.required ? '*' : '')} value={el_f.value !== undefined ? el_f.value : ''} onChange={(e) => change_fileds_value(e)} required={el_f.required} />
                </div>
              )
            }
            else if(el_f.type === 'textarea') {
              return (
                <div key={k_f} className="mb-3">
                  <textarea className='form-control border-0 rounded-0' data-order={k_f} type={el_f.type} rows="3" name={el_f.name} placeholder={el_f.name + (el_f.required ? '*' : '')} value={el_f.value !== undefined ? el_f.value : ''} onChange={(e) => change_fileds_value(e)} required={el_f.required} />
                </div>
              )
            }
            else {
              return null
            }
          })
          }
        </div>
        <div className="form-check" style={{maxWidth: '500px'}}>
          <input className="form-check-input rounded-0" type="checkbox" id="agree" onChange={checkboxHandler} defaultChecked={checkboxHandler_reload(agree)}/>
          <label className="form-check-label small" htmlFor="agree">{language[lang].agree_terms_conditions}</label>&nbsp;
          {
            dados_config.current.content !== undefined && dados_config.current.content[0].terms.link !== '' ?
              <a className="small" href={dados_config.current.content[0].terms.link} target="_blank" rel="noreferrer">
                {language[lang].terms_conditions_link}
              </a>
            :
              null
          }
          <label className="small d-none" htmlFor="agree">{language[lang].terms_conditions_required}</label>
        </div>

        <div className='text-center mt-3'>
          <button className="btn-primary mx-auto py-2 px-5" onClick={() => send_form()} disabled={!agree}>{language[lang].form_know_more}</button>    
        </div>
      </div>
    </section>


};
export default AssecForm;