import React, { useState, useEffect, useRef } from 'react'

// import Loader from '../../../loader';

import HomeMote from "../HomeMote/HomeMote_00";
import HomeNews from "../HomeNews/HomeNews_00";
import HomeProducts from "../HomeProducts/HomeProducts_00";
import HomeServices from "../HomeServices/HomeServices_00";
import HomePartners from "../HomePartners/HomePartners_00";
import HomeGallery from "../HomeGallery/HomeGallery_00";
import HomeBrands from "../HomeBrands/HomeBrands_00";
//import HomeNumbers from "../HomeNumbers/HomeNumbers_00";
import HomeSteps from "../HomeSteps/HomeSteps_00";
import HomeFaqs from "../HomeFaqs/HomeFaqs_00";
import HomeCta from "../HomeCallToAction/HomeCallToAction_00";
import HomeLinks from "../HomeLinks/HomeLinks_00";
import HomeFloatingContent from "../HomeFloatingContent/HomeFloatingContent_00";
import HomePricing from "../HomePricing/HomePricing_00";

export default function Content(props) {

	const [, set_dados] = useState(null)
	const dados_config = useRef({})
	const template = useRef({})
	const layout = useRef('')
	const content = useRef({})
	const component = useRef({})
	const pw = useRef({})
	const tag_page = useRef({})

    const [isloading, setLoading] = useState(true);

    useEffect(() => {

        if(props.dados_config !== undefined && props.dados_config !== null && props.dados_config !== '') {
            set_dados(dados_config.current = props.dados_config)
            set_dados(template.current = props.dados_config.info.template)
            set_dados(content.current = props.dados_config)
            set_dados(component.current = props.dados_component)
            set_dados(pw.current = props.pag_welcome)
            set_dados(layout.current = props.layout)
            set_dados(tag_page.current = props.tag_page)

            setLoading(false)
        }

    }, [props]);

    return (isloading ?
		null
    :
        (() => {

            switch (component.current.info.tag) {
                case 'brand':
                    return (
                        <HomeBrands pag_welcome={true} dados_config={dados_config.current} dados_component={component.current} layout={'home-brands-' + tag_page.current} />
                    )
                case 'gallery':
                    return (
                        <HomeGallery pag_welcome={true} dados_config={dados_config.current} dados_component={component.current} layout={'home-gallery-' + tag_page.current} />
                    )
                case 'mote':
                    return (
                        <HomeMote pag_welcome={true} dados_config={dados_config.current} dados_component={component.current} layout={'home-mote-' + tag_page.current} />
                    )
                case 'news':
                    return (
                        <HomeNews pag_welcome={true} dados_config={dados_config.current} dados_component={component.current} layout={'home-news-' + tag_page.current} />
                    )
                case 'statistics':
                    /*return (
                        <HomeNumbers pag_welcome={true} dados_config={dados_config.current} dados_component={component.current} layout={'home-numbers-' + tag_page.current} />
                    )*/
                    return (
                        <HomeSteps pag_welcome={true} dados_config={dados_config.current} dados_component={component.current} layout={'home-steps-' + tag_page.current} />
                    )
                case 'partner':
                    return (
                        <HomePartners pag_welcome={true} dados_config={dados_config.current} dados_component={component.current} layout={'home-partners-' + tag_page.current} />
                    )
                case 'product':
                    return (
                        <HomeProducts pag_welcome={true} dados_config={dados_config.current} dados_component={component.current} layout={'home-products-' + tag_page.current} />
                    )
                case 'service':
                    return (
                        <HomeServices pag_welcome={true} dados_config={dados_config.current} dados_component={component.current} layout={'home-services-' + tag_page.current} />
                    )
                case 'faqs':
                    return (
                        <HomeFaqs pag_welcome={true} dados_config={dados_config.current} dados_component={component.current} layout={'home-faqs-' + tag_page.current} />
                    )
                case 'cta':
                    return (
                        <HomeCta pag_welcome={true} dados_config={dados_config.current} dados_component={component.current} layout={'home-cta-' + tag_page.current} />
                    )
                case 'connections':
                    return (
                        <HomeLinks pag_welcome={true} dados_config={dados_config.current} dados_component={component.current} layout={'home-links-' + tag_page.current} />
                    )
                case 'floating':
                    return (
                        <HomeFloatingContent pag_welcome={true} dados_config={dados_config.current} dados_component={component.current} layout={'home-floating-content-' + tag_page.current} />
                    )
                case 'pricing':
                    return (
                        <HomePricing pag_welcome={true} dados_config={dados_config.current} dados_component={component.current} layout={'home-pricing-' + tag_page.current} />
                    )

                default:
                    return null
            }


        })()

    )
}
