import React, { useState, useEffect } from 'react';
import ReactHtmlParser/*, { processNodes, convertNodeToElement, htmlparser2 }*/ from 'react-html-parser';

import { content_language } from '../../../Utils';

import Navbar from '../Navbar/Navbar_00';
import Footer from '../Footer/Footer';
import Loader from '../../../loader';
import Preview from '../Preview/Preview';
// import Breadcrumb from '../Breadcrumb/Breadcrumb';

import AssecImage from "../ASSEC_Image/Image"

export default function Card(props) {

    const [dados_config, set_dados_config] = useState({});
    const [navbar, set_navbar] = useState({});

    const [isloading, setLoading] = useState(true);

    const [lang, set_lang] = useState(localStorage.getItem('lang'));
	const [lang_id, set_lang_id] = useState('1');

    const data_interface = {
        id: '',
        info: {},
        content: {}
    }
    const [content, set_content] = useState(data_interface);

    useEffect(() => {

        if(props.info !== undefined && props.info !== null && props.info !== '') {
            set_dados_config(props.info.state.dados_config)
            set_navbar(props.info.state.dados_navbar)
            set_content(props.info.state.dados_content)
        }

        try {

            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                set_lang_id(el.id)
                }
            })

            setLoading(false)

            return

        
        } catch {
            console.log('nada')

			return
		}
        
    }, [props]);

    return (isloading ?
		<Loader />
	:
        <>
            {
            content.info['preview'] === true ? 
                <Preview />
            : 
                null
            }
            <Navbar dados_config={dados_config} dados_navbar={navbar} />
            {/*<Breadcrumb dados_config={dados_config} dados_navbar={navbar} dados_content={content} />*/}
            <main className="div-card">
                <section className="content-body">
                    <div className="container-fluid">
                        <div className="row">
                            <div className='col-12 bg-light py-5'>
                                <div className='container'>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <h3 className="titulo-primario">
                                                <span>
                                                    {
                                                    content.content.map((el, k) => {  
                                                        if (k === 0){
                                                            return (
                                                                lang === 'pt' || content.content[0].language === undefined ? 
                                                                    content.content[0].title 
                                                                : 
                                                                    content.content[0].language[lang_id] === undefined || content.content[0].language[lang_id] === null || content.content[0].language[lang_id].title === undefined || content.content[0].language[lang_id].title === '' ? 
                                                                        content.content[0].title 
                                                                    : 
                                                                        content.content[0].language[lang_id].title
                                                            )
                                                        }
                                                        else {
                                                            return null
                                                        }
                                                    })
                                                    }
                                                </span>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        {
                            content.content.map((el, k) => { 
                                if (k === 0){ 
                                    return (
                                        <div key={k} className="row">
                                            {
                                            el.media !== undefined && el.media['link'] !== undefined && el.media['link'] !== '' ?
                                                <>
                                                    <div className="div-content col-12 col-xl-6 p-5 vh-100">
                                                        <h2 className={el.title !== '' ? "titulo-primario mb-5" : "d-none"}>{el.title}</h2>
                                                        <div className={"div-text"}>
                                                            {ReactHtmlParser(el.text)}
                                                        </div>
                                                    </div>

                                                    <div className="div-orange-image position-absolute bottom-0 d-none d-xl-block"></div>

                                                    <div className="div-image col-12 col-xl-6 text-center">
                                                        <AssecImage 
                                                            linkImage={el.media['link']}
                                                            width="450"
                                                            height="300"
                                                            fit='crop'
                                                            type='img'
                                                            sClass="img-fluid ms-5" 
                                                            alt={el.title}
                                                        />
                                                        {/*<img src={el.media['link']} className="img-fluid ms-5" alt={el.title} />*/}
                                                    </div>

                                                    <section className="div-scroll mt-5">
                                                        <div>
                                                            <span></span>
                                                            <p className="small mt-n2">Scroll</p>
                                                        </div>
                                                    </section>
                                                </>
                                            : 
                                                <>
                                                    <div className="div-content col-12 mb-5">
                                                        <h2 className={el.title !== '' ? "titulo-primario mb-5" : "d-none"}>{el.title}</h2>
                                                        <div className="div-text">
                                                            {ReactHtmlParser(el.text)}
                                                        </div>
                                                    </div>
                                                </>
                                            }

                                            {
                                            el['media'] !== undefined && el['media']['image'] !== undefined && el.media.image.length > 1 ?
                                                <div className="div-content col-12 mb-5">
                                                    <div className="row div-gallery">
                                                        {
                                                        el.media.image.map((el_img, k_img) => {
                                                            return (
                                                                <div key={k_img} className="col-6 col-md-3">
                                                                    <AssecImage 
                                                                        linkImage={el_img.link}
                                                                        width="450"
                                                                        height="300"
                                                                        fit='crop'
                                                                        type='img'
                                                                        sClass="img-fluid" 
                                                                        alt=""
                                                                    />
                                                                    {/*<img className="img-fluid" src={el_img.link} alt="" />*/}
                                                                </div>
                                                            )
                                                        })
                                                        }
                                                    </div>
                                                </div>
                                            :
                                                <></>
                                            }
                                            {
                                            el['media'] !== undefined && el['media']['doc'] !== undefined && el.media.doc.length > 0 ?
                                                <div className="div-content col-12 mb-5">
                                                    <div className="row div-documents">
                                                        {
                                                        el.media.doc.map((el_doc, k_doc) => {
                                                            return (
                                                                <div key={k_doc} className="mb-2">
                                                                    <AssecImage 
                                                                        linkImage={el_doc.link}
                                                                        width="450"
                                                                        height="300"
                                                                        fit='crop'
                                                                        type='img'
                                                                        sClass="img-fluid" 
                                                                        alt=""
                                                                    />
                                                                    {/*<img className="img-fluid" src={el_doc.link} alt="" />*/}
                                                                    <a className="btn btn-primary" href={el_doc.link} target="_blank" rel="noreferrer">{el_doc.name}</a>
                                                                </div>
                                                            )
                                                        })
                                                        }
                                                    </div>
                                                </div>
                                            :
                                                <></>
                                            }
                                        </div>
                                    )
                                }
                                return null
                            })
                        }
                    </div>
                </section>

                <section className="content-body content-list">
                    <div className="container">
                        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4 g-3 mb-5">
                            {
                            content.content.map((el, k) => {  
                                return (
                                    k > 0 ?
                                        <div key={k} className="col">
                                            <div className="card shadow-sm">
                                                {
                                                el['media'] !== undefined && el['media']['image'] !== undefined && el.media.image.length > 0 ?
                                                    <>
                                                        <AssecImage 
                                                            linkImage={el.media.image[0].link}
                                                            width="450"
                                                            height="300"
                                                            fit='crop'
                                                            type='img'
                                                            sClass="img-fluid" 
                                                            alt=""
                                                        />
                                                        {/* <img alt='' className="img-fluid" src={el.media.image[0].link} /> */}
                                                    </>
                                                :
                                                    ""
                                                }

                                                <div className="card-body">
                                                    <div className="card-text">{ReactHtmlParser(el.text)}</div>
                                                </div>
                                            </div>
                                        </div>
                                    :
                                        null
                                )
                            })
                            }
                        </div>
                    </div>
                </section>

            </main>
            <Footer dados_config={dados_config} dados_navbar={navbar} />
        </>
    )
}