import React, { useState, useEffect, useRef } from 'react';
//import parse from 'html-react-parser';
//import { useLocation } from 'react-router-dom';
import './Footer.css';

import { language } from '../WEBContent/Language';

import { /*set_language,*/ repo_logo_link, /*repo_site_documents_link, repo_img_link,*/ content_language, /*translate*/ } from '../../../Utils';

import * as Hosts from '../../../Hosts';

//import { facebook } from "@fortawesome/free-solid-svg-icons";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const Footer = (props) => {

  const [, set_dados] = useState(null)
	const dados_config = useRef({})

  const [lang, set_lang] = useState(localStorage.getItem('lang'));
  const [, set_lang_id] = useState('1');

  //const location = useLocation().pathname;

  const data_interface_navbar = {
    id: '',
    info: {},
    content: {
      childs: []
    }
  }

  const navbar = useRef(data_interface_navbar);

  const [isloading, setLoading] = useState(true);

  useEffect(() => {
  
    if(props.dados_navbar !== undefined && props.dados_navbar !== null && props.dados_navbar !== '') {
      set_dados(navbar.current = props.dados_navbar)
    }

    if(props.dados_config !== undefined && props.dados_config !== null && props.dados_config !== '') {
      set_dados(dados_config.current = props.dados_config)
    }

    if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
      set_lang('pt')
      set_lang_id('1')

      setLoading(false)
    }
    else {
      set_lang(localStorage.getItem('lang'))
      content_language.forEach(el => {
        if (el.code === localStorage.getItem('lang')) {
          set_lang_id(el.id)
        }
      })

      setLoading(false)
    }

  }, [props])
  
  //const page_location = useLocation().pathname.split('/').pop();

  return isloading ?
    null
    :
    <footer id="footer" className="text-uppercase text-white">
      <div className="container">
        <div className="row pt-5">
          <div className="col-12 text-white d-none">
            <h4 className="footer-heading">{Hosts.webSiteTitle}</h4>
          </div>
          <div className="col-12 mb-2 d-none">
            <a href={Hosts.WEB_SITE_URI} title={Hosts.webSiteTitle} >
              <img src={repo_logo_link("logo.svg")} width="180" height="75" alt={Hosts.webSiteTitle} title={Hosts.webSiteTitle} className="img-fluid" />
            </a>
          </div>

          <div className="col-12 text-center mb-3 social-media">
            <h5 className="my-2">{language[lang].social_media_follow_us}</h5>
            {
            dados_config.current.content !== undefined ?
              dados_config.current.content[0].social.map((el, k) => {
                if(el.link !== '') {
                  return (
                    <a key={k} className="mx-2" href={el.link} title={el.info.name} target="_blank" rel="noreferrer" >
                      <i className={"bi bi-" + el.info.icon.bi}></i>
                    </a>
                  )
                }
                else {
                  return null
                }
              })
            :
              null
            }
          </div>
          <div className='col-12 text-center mb-2'>
            {language[lang].footer_morada}: {dados_config.current.content[0].contacts.address}
            {
            dados_config.current.content[0].contacts.tlf !== '' ?
              <>
                <span className='mx-2 text-white'>|</span>
                {language[lang].footer_tlf}: {dados_config.current.content[0].contacts.tlf}
              </>
            :
              null
            }
            {
            dados_config.current.content[0].contacts.tlm !== '' ?
              <>
                <span className='mx-2 text-white'>|</span>
                {language[lang].footer_tlm}: {dados_config.current.content[0].contacts.tlm}
              </>
            :
              null
            }
            <span className='mx-2 text-white'>|</span>
            {language[lang].footer_email}: {dados_config.current.content[0].contacts.email}
          </div>
          <div className="col-12 text-center">
            {
            dados_config.current.content !== undefined && dados_config.current.content[0].terms.link !== '' ?
              <a className={''} href={dados_config.current.content[0].terms.link}>
                {language[lang].terms_conditions}
              </a>
            :
              null
            }
            <span className='mx-2 text-white'>|</span>
            <a className={''} href="https://www.livroreclamacoes.pt/" title={"Link - " + language[lang].complaint_book} target="_blank" rel="noreferrer">
              {language[lang].complaint_book}
            </a>
          </div>
        </div>
      </div>
      <div className="col-12 text-center py-3 justify-content-center text-white">
        <ul className="list-inline mb-0 copyright">
          <li className="list-inline-item p-0">
            <span className="">
              <i className="bi bi-c-circle"></i>&nbsp;2024&nbsp;{Hosts.webSiteTitle}
            </span>
          </li>
          <li className="list-inline-item p-0">
            <span className="px-1">&#124;</span>
          </li>
          <li className="list-inline-item p-0">
            <span>{language[lang].developed}</span>
          </li>
          <li className="list-inline-item p-0">
            <span>
              <a href="http://sim.assec.pt" title="Website Assec Sim!" target="_blank" rel="noreferrer">
                {/*<img className="img-fluid" src="./img/logo.png" alt="Assec Sim!" style={logoAssec}/>*/}
                Assec Sim!
              </a>
            </span>
          </li>
        </ul>
      </div>
    </footer>


};
export default Footer;