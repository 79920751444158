import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import './Navbar_01.css';

import { language } from '../WEBContent/Language';

import {
    //reqGET,
    set_language,
    //repo_site_assets_link, 
    //repo_logo_link,
    //repo_site_documents_link, 
    translate,
    content_language
} from '../../../Utils';

import { faLongArrowAltLeft, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import * as Hosts from "../../../Hosts";
//import { usarToken } from '../GenericStore/UseToken';

const Navbar = (props) => {

	const [, set_dados] = useState(null)

    const dados_config = useRef({})

    const data_interface = {
        id: '',
        info: {},
        content: {
            childs: []
        }
    }
    const navbar = useRef(data_interface);

    // const [user_profile, set_user_profile] = useState('');
    const [pag_logo, set_pag_logo] = useState('');

    const [lang, set_lang] = useState(localStorage.getItem('lang'));
    const [lang_id, set_lang_id] = useState('1');

    //const [enableFixedSideNavigationMenu, /*setEnableFixedSideNavigationMenu*/] = useState(true);

    //const [/*refresh*/, setRefresh] = useState(0);

    const [page_location, set_page_location] = useState(useLocation().pathname.split('/').pop());

    const location = useLocation().pathname;

    const change_lang = (l) => {
        set_language(l)
        set_lang(l)
        window.location.reload()
    }

    useEffect(() => {
    
        function get_image_logo(el, type) {
    
            let result = ''
    
            //if(parseInt(lang_id) === 1) {
    
                if(el.content !== undefined && el.content[0].logo !== undefined && el.content[0].logo !== null) {
    
                    if(type === 'url') {
                    result = el.content[0].logo.url
                    }
    
                }
    
            //}
    
            return result
        }

        //console.log('props', props)

        if(props.dados_navbar !== undefined && props.dados_navbar !== null && props.dados_navbar !== '') {
            set_dados(navbar.current = props.dados_navbar)
        }

        if(props.dados_config !== undefined && props.dados_config !== null && props.dados_config !== '') {
            set_dados(dados_config.current = props.dados_config)
            set_pag_logo(get_image_logo(props.dados_config, 'url'))
        }

        if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_language('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                set_lang_id(el.id)
                }
            })
        }

        if(page_location === 'all') {
            let aux = location.split('/all');
            set_page_location(aux[0].split('/').pop())
        }

    }, [props, location, page_location, lang, lang_id])

    return (
        
        <div className={"sticky-top bg-black"}>
            {
                content_language.length > 1 ? 
                    <div className="topbar">
                        <div className='container'>
                            <div className='topbar-aligment'>
                                <ul className="list-inline m-0">
                                    {/*<li className="list-inline-item">
                                        <button className={"pb-0 button_no_border " + (lang === 'pt' ? 'current_lang' : 'btn_lang')} onClick={() => change_lang('pt')}>PT</button>
                                    </li>
                                    <li className="list-inline-item">&#124;</li>
                                    <li className="list-inline-item">
                                        <button className={"pb-0 button_no_border " + (lang === 'en' ? 'current_lang' : 'btn_lang')} onClick={() => change_lang('en')}>EN</button>
                                    </li>
                                    <li className="list-inline-item">&#124;</li>
                                    <li className="list-inline-item">
                                        <button className={"pb-0 button_no_border " + (lang === 'es' ? 'current_lang' : 'btn_lang')} onClick={() => change_lang('es')}>ES</button>
                                    </li>
                                    <li className="list-inline-item">&#124;</li>
                                    <li className="list-inline-item">
                                        <button className={"pb-0 button_no_border " + (lang === 'fr' ? 'current_lang' : 'btn_lang')} onClick={() => change_lang('fr')}>FR</button>
                                    </li>*/}
                                    {
                                        
                                        content_language.map((el_lang, k_lang) => {
                                            //language selector
                                            return (
                                                <li key={k_lang} className="list-inline-item">
                                                    <button className={"pb-0 text-uppercase button_no_border " + (lang === el_lang.code ? 'current_lang' : 'btn_lang')} onClick={() => change_lang(el_lang.code)}>{el_lang.code}</button>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                :
                    null 
            }
            <div className='d-flex justify-content-center big-logo-container'>
                <a href={Hosts.WEB_SITE_URI}>
                    <img id="big-logo"
                        className="svg-logo img-fluid"
                        src={pag_logo}
                        alt={dados_config.current.content !== undefined && dados_config.current.content[0].title !== undefined ? translate(dados_config.current.content[0].title, lang) : Hosts.webSiteTitle}
                        title={dados_config.current.content !== undefined && dados_config.current.content[0].title !== undefined ? translate(dados_config.current.content[0].title, lang) : Hosts.webSiteTitle}
                    />
                </a>
            </div>
            {
                false ? 
                    <section id="navbar">
                        <nav className="navbar navbar-expand-lg nav-effect bg-navbar">
                            <div className='container-fluid container-lg'>
                                <a className="navbar-brand d-lg-none" href={Hosts.WEB_SITE_URI} alt={Hosts.webSiteTitle}>
                                    <img className="svg-logo img-fluid py-2" /*src={logoPath(mainLogo)}*/ src={pag_logo} alt={Hosts.webSiteTitle} title={Hosts.webSiteTitle} />
                                </a>
                                {
                                false ?
                                    <>
                                        <button className="navbar-toggler align-self-center" type="button" /*onClick={changeLogo}*/ data-toggle="collapse" data-target="#sideNavigationMenu" aria-controls="sideNavigationMenu" aria-expanded="false" aria-label="Toggle navigation">
                                            {/*<span className="navbar-toggler-icon"></span>*/}
                                            <div className="animated-icon3">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </div>
                                        </button>
                                        <div className="collapse navbar-collapse" id="sideNavigationMenu">
                                            <ul className="navbar-nav my-2">
                                                {
                                                props.mostrar_botao_voltar ?
                                                    <li className="nav-item" /* key={key} */ >
                                                        <a href={Hosts.WEB_SITE_URI} className={"nav-link"}>
                                                            <FontAwesomeIcon className="mr-2" icon={faLongArrowAltLeft} />
                                                            {language[lang].back}
                                                        </a>
                                                    </li>
                                                :
                                                    navbar.current.content.childs.map((v, key) => {

                                                        // console.log(v)

                                                        let link = ''
                                                        v.id_content !== '' ?
                                                            link = (v.slug !== undefined && v.slug !== '' ? Hosts.WEB_SITE_URI + '' + v.slug : Hosts.WEB_SITE_URI + 'pag/' + v.id_content)
                                                            :
                                                            link = (v.slug !== undefined && v.slug !== '' ? Hosts.WEB_SITE_URI + '' + v.slug : (v.link.substr(0, 4) !== 'http' && v.link.substr(0, 3) !== 'www' ? Hosts.WEB_SITE_URI + v.link : (v.link.substr(0, 3) === 'www' ? 'http://' + v.link : v.link)))

                                                        let current_top_check = (v.slug !== undefined && v.slug !== '' ? v.slug : (v.id_content !== '' ? v.id_content : v.link));

                                                        if (v.children !== undefined && v.children !== '' && v.children.length > 0) {

                                                            return (
                                                                <li className="nav-item dropdown" key={key}>
                                                                    <a className="nav-link dropdown-toggle" href="/#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-expanded="false">
                                                                        {v.title}
                                                                        <FontAwesomeIcon icon={faAngleDown} fixedWidth />
                                                                    </a>
                                                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                                        {
                                                                            v.children.map((v2, key2) => {

                                                                                //console.log(v2)

                                                                                let link_content_dropdown_item = ''
                                                                                v2.id_content !== '' ?
                                                                                    link_content_dropdown_item = (v2.slug !== undefined && v2.slug !== '' ? Hosts.WEB_SITE_URI + '' + v2.slug : Hosts.WEB_SITE_URI + 'pag/' + v2.id_content)
                                                                                    :
                                                                                    link_content_dropdown_item = (v2.slug !== undefined && v2.slug !== '' ? Hosts.WEB_SITE_URI + '' + v2.slug : (v2.link.substr(0, 4) !== 'http' && v2.link.substr(0, 3) !== 'www' ? Hosts.WEB_SITE_URI + v2.link : (v2.link.substr(0, 3) === 'www' ? 'http://' + v2.link : v2.link)))


                                                                                //let link_content_dropdown_item = (v2.slug !== undefined && v2.slug !== '' ? Hosts.WEB_SITE_URI + '' + v2.slug : Hosts.WEB_SITE_URI + 'pag/' + v2.id_content);
                                                                                let current_top_dropdown = (v2.slug !== undefined && v2.slug !== '' ? v2.slug : (v2.id_content !== '' ? v2.id_content : v2.link));

                                                                                if (location === '/' + v2.link) {
                                                                                    current_top_dropdown = v2.link
                                                                                }

                                                                                return (
                                                                                    v2.link.substr(0, 4) !== 'http' && v2.link.substr(0, 3) !== 'www' ?
                                                                                        <a key={key2} href={link_content_dropdown_item} className={"dropdown-item " + ((page_location !== '' && page_location === current_top_dropdown) || (page_location !== '' && location === '/' + current_top_dropdown) ? 'current_top' : '')}  >
                                                                                            {v2.title}
                                                                                        </a>
                                                                                        :
                                                                                        <a key={key2} href={link_content_dropdown_item} className={"dropdown-item " + ((page_location !== '' && page_location === current_top_dropdown) || (page_location !== '' && location === '/' + current_top_dropdown) ? 'current_top' : '')} target="_blank" rel="noreferrer" >
                                                                                            {v2.title}
                                                                                        </a>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </li>
                                                            )
                                                        }
                                                        else {

                                                            return (
                                                                v.link.substr(0, 4) !== 'http' && v.link.substr(0, 3) !== 'www' ?
                                                                    <li className="nav-item" key={key} >
                                                                        <a href={link} className={"nav-link " + ((page_location !== '' && page_location === current_top_check) || (page_location !== '' && location === '/' + current_top_check) ? 'current_top' : '')} >
                                                                            {v.title}
                                                                        </a>
                                                                    </li>
                                                                    :
                                                                    <li className="nav-item" key={key} >
                                                                        <a href={link} className={"nav-link " + ((page_location !== '' && page_location === current_top_check) || (page_location !== '' && location === '/' + current_top_check) ? 'current_top' : '')} target="_blank" rel="noreferrer" >
                                                                            {v.title}
                                                                        </a>
                                                                    </li>

                                                            )

                                                        }
                                                    })
                                                }

                                                {/*
                                                    <li className="nav-item d-block d-lg-none">
                                                        <a className="nav-link" href="https://www.meteoestrela.pt/previsoes/neve/" target="_blank" rel="noreferrer">
                                                            <img src={repo_site_assets_link("meteorologia.svg")} alt={language[lang].meteorology} title={language[lang].meteorology} className="img-fluid mr-2 d-none" style={{width: '15px', height: '15px'}}/>
                                                            {language[lang].meteorology}
                                                        </a>
                                                    </li>
                                                    <li className="nav-item d-block d-lg-none">
                                                        <a className={"nav-link " + (page_location === 'livecam' ? 'current_top' : '')} href={Hosts.WEB_SITE_URI + 'livecam'}>
                                                            <img src={repo_site_assets_link("livecam.svg")} alt={language[lang].livecam} title={language[lang].livecam} className="img-fluid mr-2 d-none" style={{width: '15px', height: '15px'}}/>
                                                            {language[lang].livecam}
                                                        </a>
                                                    </li>
                                                */}
                                            </ul>
                                            {
                                                props.dados_config.content !== undefined && props.dados_config.content[0].social !== undefined && props.dados_config.content[0].social.length > 0 ? (
                                                    <ul className="navbar-nav social-media mt-5 text-center">
                                                        <li className="nav-item">
                                                            {
                                                                props.dados_config.content[0].social.map((el, k) => {
                                                                    if(el.info.link !== '') {
                                                                        return (
                                                                            <a key={k} className="nav-link d-inline-block p-0 me-3" href={el.info.link.includes('http') ? el.info.link : 'http://' + el.info.link} title={el.info.name} target="_blank" rel="noreferrer" >
                                                                                <i className={"bi bi-" + el.info.icon.bi}></i>
                                                                            </a>
                                                                        )
                                                                    }
                                                                    else {
                                                                        return null
                                                                    }
                                                                })
                                                            }
                                                        </li>
                                                    </ul>
                                                )

                                                :
                                                    null
                                            }
                                            <div className="overlay d-none"></div>
                                        </div>
                                        <div className='collapse navbar-collapse' id="navbarNav">
                                            <ul className="navbar-nav mx-auto">
                                                {
                                                navbar.current.content.childs.map((v, key) => {
                                                    
                                                    /* if (v.id === 17) { */
                                                        // console.log('v', v)
                                                        let link = ''
                                                        v.id_content !== '' ?
                                                            link = (v.slug !== undefined && v.slug !== '' ? Hosts.WEB_SITE_URI + '' + v.slug : Hosts.WEB_SITE_URI + 'pag/' + v.id_content)
                                                        :
                                                            link = (v.slug !== undefined && v.slug !== '' ? Hosts.WEB_SITE_URI + '' + v.slug : Hosts.WEB_SITE_URI + v.link)

                                                        let current_top_check = (v.slug !== undefined && v.slug !== '' ? v.slug : v.id_content);
                                                        if(current_top_check === '' && link.includes('/all')) {
                                                            let link_aux = link.split('/all')
                                                            current_top_check = link_aux[0].split('/')
                                                            current_top_check = current_top_check.pop()
                                                        }

                                                        if (v.children !== undefined && v.children !== '' && v.children.length > 0) {
                                                            return (
                                                                <li key={key} className="nav-item dropdown mx-2">
                                                                    <a href="true" className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-expanded="false">
                                                                        {v.title}
                                                                        <i className="bi bi-chevron-down ml-1" />
                                                                    </a>
                                                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                                        {
                                                                            v.children.map((v2, key2) => {

                                                                                // console.log('v2', v2)

                                                                                let link_dropdown_item = ''
                                                                                v2.id_content !== '' ?
                                                                                    link_dropdown_item = (v2.slug !== undefined && v2.slug !== '' ? Hosts.WEB_SITE_URI + '' + v2.slug : Hosts.WEB_SITE_URI + 'pag/' + v2.id_content)
                                                                                :
                                                                                    link_dropdown_item = (v2.slug !== undefined && v2.slug !== '' ? Hosts.WEB_SITE_URI + '' + v2.slug : Hosts.WEB_SITE_URI + v2.link)
                                                                                
                                                                                let current_top_dropdown = (v2.slug !== undefined && v2.slug !== '' ? v2.slug : v2.id_content);
                                                                                if(current_top_dropdown === '') {
                                                                                    if(location === '/' + v2.link) {
                                                                                        current_top_dropdown = v2.link
                                                                                    }
                                                                                }

                                                                                return (
                                                                                    v2.link.substr(0, 4) !== 'http' ?
                                                                                        <a key={key2} href={link_dropdown_item} className={"dropdown-item " + ((page_location !== '' && page_location === current_top_dropdown) || (page_location !== '' && location === '/' + current_top_dropdown) ? 'current_top' : '')}  >
                                                                                            {lang === 'pt' || v2.language === undefined ? v2.title : (v2.language[lang_id] === undefined || v2.language[lang_id] === null || v2.language[lang_id].title === undefined || v2.language[lang_id].title === '' ? v2.title : v2.language[lang_id].title)}
                                                                                        </a>
                                                                                    :
                                                                                        <a key={key2} href={link_dropdown_item} className={"dropdown-item " + ((page_location !== '' && page_location === current_top_dropdown) || (page_location !== '' && location === '/' + current_top_dropdown) ? 'current_top' : '')} target="_blank" rel="noreferrer" >
                                                                                            {lang === 'pt' || v2.language === undefined ? v2.title : (v2.language[lang_id] === undefined || v2.language[lang_id] === null || v2.language[lang_id].title === undefined || v2.language[lang_id].title === '' ? v2.title : v2.language[lang_id].title)}
                                                                                        </a>
                                                                                )

                                                                            })
                                                                        }
                                                                    </div>
                                                                </li>
                                                            )
                                                        }
                                                        else {
                                                            return (
                                                                v.link.substr(0, 4) !== 'http' ?
                                                                    <li className="nav-item mx-2" data-a={page_location + ' ' + current_top_check} key={key} >
                                                                        <a href={link} className={"nav-link " + ((page_location !== '' && page_location === current_top_check) || (page_location !== '' && location === '/' + current_top_check) ? 'current_top' : '')} >
                                                                            {v.title}
                                                                        </a>
                                                                    </li>
                                                                :
                                                                    <li className="nav-item mx-2" key={key} >
                                                                        <a href={link} className={"nav-link " + ((page_location !== '' && page_location === current_top_check) || (page_location !== '' && location === '/' + current_top_check) ? 'current_top' : '')} target="_blank" rel="noreferrer" >
                                                                            {v.title}
                                                                        </a>
                                                                    </li>
                                                                
                                                            )

                                                        }
                                                    /* } */
                                                    /* return (
                                                        <li className="nav-item" key={key}>
                                                            <Link activeClassName="active" to={v.link} spy={true} smooth={true} offset={-150} duration={600}
                                                                className={"nav-link " + (page_location === 'scientia_magazine' ? 'current_top' : '')}>
                                                                {v.title}
                                                            </Link>
                                                        </li>
                                                    ) */
                                                })
                                                }
                                            </ul>
                                        </div>
                                    </>
                                :
                                    null
                                }
                            </div>
                        </nav>
                    </section>
                :
                    null
            }
        </div>

    )
};
export default Navbar;