import React, { useState, useEffect, useRef } from 'react';

import { /*reqGET,*/ repo_img_link, content_language, translate } from '../../../../Utils';

//import * as Hosts from "../../../../Hosts";

import { language } from '../Language';

import AssecImage from "../../ASSEC_Image/Image"

import parse from 'html-react-parser';

import Loader from '../../../../loader';
import "./HomeFloatingContent_01.css"

const HomeFloatingContent = (props) => {

	const [, set_dados] = useState(null)
	const content_list = useRef([])
	const dados_config = useRef([])
	const dados_component = useRef([])

    //const [content_title, set_content_title] = useState('')
    //const [content_subtitle, set_content_subtitle] = useState('')

    const [isloading, setLoading] = useState(true);

    const [lang, set_lang] = useState(localStorage.getItem('lang'));
    const [lang_id, set_lang_id] = useState('1');

    useEffect(() => {

        if(props.dados_config !== undefined && props.dados_config !== null && props.dados_config !== '') {
            set_dados(dados_config.current = props.dados_config)
            set_dados(dados_component.current = props.dados_component)

            content_list.current = []

            for (let el of dados_config.current.content[0].components) {

                if(el.id === dados_component.current.id && el.info.tag === 'floating') {
                    //set_content_title(translate(el.info.title, lang))
                    //set_content_subtitle(translate(el.info.subtitle, lang))

                    set_dados(content_list.current.push(el))
                    setLoading(false)
                }
            }
        }

        if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                    set_lang_id(el.id)
                }
            })
        }

    }, [props, lang])

    return (
        isloading ?
            <Loader />
		:
            <section className="homefloatingcontent_01">
                <div className="container">
                    <div className="row">
                        {/*<div className="col-12 title-properties d-none">
                            <h3 className="titulo-pp">
                                {content_title}
                            </h3>
                            {
                                content_subtitle !== '' ?
                                    <h4 className={"subtitulo-pp"}>
                                        {content_subtitle}
                                    </h4>
                                :
                                    null
                            }
                            <hr></hr>
                        </div>*/}
                        {
                            content_list.current !== undefined && content_list.current !== null && content_list.current.length > 0 ?
                                <div className="col-12">
                                    {
                                        content_list.current.map((v, k) => {
                                            return (
                                                <div className={"div-container"} key={k}>
                                                    {	
                                                        (v.info.title !== undefined && translate(v.info.title, lang) !== '') || (v.info.subtitle !== undefined && translate(v.info.subtitle, lang) !== '') ?
                                                            <div className='title-properties'>
                                                                {	
                                                                    v.info.title !== undefined && translate(v.info.title, lang) !== '' ?
                                                                        <h3 className="titulo-pp">
                                                                            {translate(v.info.title, lang)}
                                                                        </h3>
                                                                    :
                                                                        null
                                                                }
                                                                {
                                                                    v.info.subtitle !== undefined && translate(v.info.subtitle, lang) !== '' ?
                                                                        <h4 className={"subtitulo-pp"}>
                                                                            {translate(v.info.subtitle, lang)}
                                                                        </h4>
                                                                    :
                                                                        null
                                                                }
                                                            </div>
                                                        :
                                                            null
                                                    }
                                                    {
                                                        lang !== 'pt' && v.info['language'] !== undefined && v.info['language'][lang_id] !== undefined && v.info['language'][lang_id] !== null && v.info['language'][lang_id]['media'] !== null && v.info['language'][lang_id]['media']['image'] !== undefined && v.info.language[lang_id]['media']['image'].length > 0 ?
                                                            
                                                            <div className={"div-image float-md-end ms-md-4 mb-3"}>
                                                                <a
                                                                    href={v.info['language'][lang_id]['media']['image'][0]['link']}
                                                                    //title={lang === 'pt' || v.info.language === undefined ? v.info.title : (v.info.language[lang_id] === undefined || v.info.language[lang_id] === null || v.info.language[lang_id].title === undefined || v.info.language[lang_id].title === '' ? v.info.title : v.info.language[lang_id].title)}
                                                                    data-fancybox="content-gallery"
                                                                    data-caption={v.info['language'][lang_id]['media']['image'][0]['link'].includes(v.info['media']['image'][0]['name']) || v.info['language'][lang_id]['media']['image'][0]['link'].includes('webp') || v.info['language'][lang_id]['media']['image'][0]['link'].includes('jpg') || v.info['language'][lang_id]['media']['image'][0]['link'].includes('png') ? '' : v.info['language'][lang_id]['media']['image'][0]['name']}
                                                                >
                                                                    <div className="card border-0 shadow content-image">
                                                                        <AssecImage
                                                                            linkImage={v.info['language'][lang_id]['media']['image'][0]['link'] !== '' ? v.info['language'][lang_id]['media']['image'][0]['link'] : repo_img_link('nophoto.webp')}
                                                                            width="400"
                                                                            height="300"
                                                                            fit='crop'
                                                                            type='img'
                                                                            sClass="img-fluid" 
                                                                            alt={v.info['language'][lang_id]['media']['image'][0]['name']}
                                                                        />
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        :
                                                            v.info['media'] !== undefined && v.info['media']['image'] !== undefined && v.info['media']['image'].length > 0 ?
                                                                <div className={"div-image float-md-end ms-md-4 mb-3"}>
                                                                    <a
                                                                        href={v.info['media']['image'][0]['link']}
                                                                        //title={lang === 'pt' || v.info.language === undefined ? v.info.title : (v.info.language[lang_id] === undefined || v.info.language[lang_id] === null || v.info.language[lang_id].title === undefined || v.info.language[lang_id].title === '' ? v.info.title : v.info.language[lang_id].title)}
                                                                        data-fancybox="content-gallery"
                                                                        data-caption={v.info['media']['image'][0]['link'].includes(v.info['media']['image'][0]['name']) || v.info['media']['image'][0]['link'].includes('webp') || v.info['media']['image'][0]['link'].includes('jpg') || v.info['media']['image'][0]['link'].includes('png') ? '' : v.info['media']['image'][0]['name']}
                                                                    >
                                                                        <div className="card border-0 shadow content-image">
                                                                            <AssecImage 
                                                                                linkImage={v.info['media']['image'][0]['link'] !== '' ? v.info['media']['image'][0]['link'] : repo_img_link('nophoto.webp')}
                                                                                width="400"
                                                                                height="300"
                                                                                fit='crop'
                                                                                type='img'
                                                                                sClass="img-fluid" 
                                                                                alt={v.info['media']['image'][0]['name']}
                                                                            />
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            :
                                                                null
                                                    }
                                                    <div className="div-content">
                                                        {	
                                                            v.info.text !== undefined && translate(v.info.text, lang) !== '' ?
                                                                <div className="content-description">
                                                                    {parse(translate(v.info.text, lang))}
                                                                </div>
                                                            :
                                                                null
                                                        }
                                                        {	
                                                            v.info.link !== undefined && translate(v.info.link, lang) !== '' ?
                                                                <a className="btn btn-alert" href={translate(v.info.link, lang).includes('http') ? translate(v.info.link, lang) : 'http://' + translate(v.info.link, lang)} target="_blank" rel="noreferrer" >
                                                                    {	
                                                                        v.info.btn_label !== undefined && translate(v.info.btn_label, lang) !== '' ?
                                                                            translate(v.info.btn_label, lang)
                                                                        :
                                                                            language[lang].learn_more
                                                                    }
                                                                </a>
                                                            :
                                                                null
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })              
                                    }
                                </div>
                            :
                            null
                        }
                    </div>
                </div>
            </section>
    )
};
export default HomeFloatingContent;