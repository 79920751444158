import React, { useState, useEffect, useRef } from 'react';

import { /*reqGET, repo_img_link,*/ content_language, translate } from '../../../../Utils';

//import * as Hosts from "../../../../Hosts";

//import { language } from '../Language';

//import AssecImage from "../../ASSEC_Image/Image"

import parse from 'html-react-parser';

import Loader from '../../../../loader';
// import "../../Pagina.css";
import "./HomeFaqs_02.css"

const HomeFaqs = (props) => {

	const [, set_dados] = useState(null)
	const faqs_list = useRef([])
	const dados_config = useRef([])

    const [faqs_title, set_faqs_title] = useState('')
    const [faqs_subtitle, set_faqs_subtitle] = useState('')

    const [isloading, setLoading] = useState(true);

    const [lang, set_lang] = useState(localStorage.getItem('lang'));
    const [/*lang_id*/, set_lang_id] = useState('1');

    useEffect(() => {

        if(props.dados_config !== undefined && props.dados_config !== null && props.dados_config !== '') {
            set_dados(dados_config.current = props.dados_config)
            
            for (let el of dados_config.current.content[0].components) {

                if(el.info.tag === 'faqs' && el.info.active) {
                    set_faqs_title(translate(el.info.title, lang))
                    set_faqs_subtitle(translate(el.info.subtitle, lang))

                    set_dados(faqs_list.current = dados_config.current.content[0].faqs)
                    setLoading(false)
                }
            }
        }

        if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                    set_lang_id(el.id)
                }
            })
        }

    }, [props, lang])

    return (
        isloading ?
            <Loader />
		:
            <section id="homefaqs_02">
                <div className="container">
                    <div className="row">
                        <div className="col-12 title-properties">
                            <h3 className="titulo-pp">
                                {faqs_title}
                            </h3>
                            {
                                faqs_subtitle !== '' ?
                                    <h4 className={"subtitulo-pp"}>
                                        {faqs_subtitle}
                                    </h4>
                                :
                                    null
                            }
                            <hr></hr>
                        </div>
                        {
                            faqs_list.current !== undefined && faqs_list.current !== null && faqs_list.current.length > 0 ?
                                <>
                                    <div className="col-12 col-md-9 mx-auto">
                                        <div className="accordion" id="accordionFaqs">
                                            {
                                                faqs_list.current.map((v, k) => {
                                                    return (
                                                        <div className={"accordion-item"} key={k}>
                                                            <div className="accordion-header" id={"heading-" + k}>
                                                                <button className={"accordion-button" + (k === 0 && false ? "" : " collapsed")} type="button" data-bs-toggle="collapse" data-bs-target={"#collapse-" + k} aria-expanded={k === 0 && false ? "true" : "false"} aria-controls={"collapse-" + k}>
                                                                    {	
                                                                        v.info.title !== undefined && translate(v.info.title, 'pt') !== '' ?
                                                                            <div className="faq-title">
                                                                                {translate(v.info.title, 'pt')}  
                                                                            </div>
                                                                        :
                                                                            null
                                                                    }
                                                                </button>
                                                            </div>
                                                            {/*com o atributo data-bs-parent="#accordionMultipagina" só abre um accordion de cada vez*/}
                                                            <div id={"collapse-" + k} className={"accordion-collapse collapse" + (k === 0 && false ? " show" : "")} aria-labelledby={"heading-" + k} data-bs-parent="#accordionFaqs">
																<div className="accordion-body">
                                                                {	
                                                                    v.info.text !== undefined && translate(v.info.text, 'pt') !== '' ?
                                                                        <div className="faq-description">
                                                                            {parse(translate(v.info.text, 'pt'))}
                                                                        </div>
                                                                    :
                                                                        null
                                                                }
                                                                </div>
                                                             </div>
                                                        </div>
                                                    )
                                                })              
                                            }
                                        </div>
                                    </div>
                                </> 
                            :
                            null
                        }
                    </div>
                </div>
            </section>
    )
};
export default HomeFaqs;