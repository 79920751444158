import React, { useState, useEffect, useRef } from 'react';

import './ASSECForms_02.css';

import { loadCaptchaEnginge, LoadCanvasTemplate, /*LoadCanvasTemplateNoReload,*/ validateCaptcha } from 'react-simple-captcha';

import { language } from '../WEBContent/Language';

import { 
  reqPOST,
  content_language, 
  translate, 
  functionSendMail 
} from '../../../Utils';

import * as Hosts from '../../../Hosts';

import parse from 'html-react-parser';

//import { facebook } from "@fortawesome/free-solid-svg-icons";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


import Swal from 'sweetalert2';

const AssecForm = (props) => {

  const [, set_dados] = useState(null)
	const dados_config = useRef({})
	const dados_campanha = useRef('')

  const [lang, set_lang] = useState(localStorage.getItem('lang'));
  const [lang_id, set_lang_id] = useState('1');

  const [isloading, setLoading] = useState(true);

  useEffect(() => {

    if(props.dados_config !== undefined && props.dados_config !== null && props.dados_config !== '') {
      set_dados(dados_config.current = props.dados_config)

      if(props.dados_config.info !== undefined && props.dados_config.info?.form !== undefined) {
        set_dados(dados_campanha.current = translate(props.dados_config.info?.form?.info?.title, lang))
      }
    }

    if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
      set_lang('pt')
      set_lang_id('1')

      setLoading(false)

      setTimeout(() => {
        loadCaptchaEnginge(7);
      }, "1000");
    } else {
      set_lang(localStorage.getItem('lang'))
      content_language.forEach(el => {
        if (el.code === localStorage.getItem('lang')) {
          set_lang_id(el.id)
        }
      })

      setLoading(false)

      setTimeout(() => {
        loadCaptchaEnginge(7);
      }, "1000");
      
    }

  }, [props, lang])

  const [agree, setAgree] = useState(false);

  const checkboxHandler = () => {
    // if agree === true, it will be set to false
    // if agree === false, it will be set to true
    setAgree(!agree);
    // Don't miss the exclamation mark
  }
  const checkboxHandler_reload = (agree) => {
    //console.log('AGREE', agree)
    if (agree === true)
        return true
    else 
        return false
    // if agree === false, it will be set to true
    //setAgree(!agree);
    // Don't miss the exclamation mark
  }

  /*const change_campanha_value = (e) => {
		const { value } = e.target;

    set_dados(dados_campanha.current = value)

  }*/

  const change_fileds_value = (e) => {
		const { /*name,*/ value, dataset } = e.target;
		const { order } = dataset;

    set_dados(dados_config.current.info.form.info.fields[order]['value'] = value)
    
    // console.log('dados_config.current.info.form.info.fields', JSON.parse(JSON.stringify(dados_config.current.info.form.info.fields)))
  }

  const send_form = async () => {

    let mail_text = ''
    let mail_link = ''
    let mail_btn_text = ''
    let mail_file_link = ''
    let mail_file_name = ''

    let color_primary = dados_config.current.info.theme.info.color.primary

    if(translate(dados_config.current.content[0]?.mail?.text, 'pt') !== '') {
      mail_text = parse(translate(dados_config.current.content[0]?.mail?.text, 'pt'))
    }
    if(translate(dados_config.current.content[0]?.mail?.link, 'pt') !== '') {
      mail_link = translate(dados_config.current.content[0]?.mail?.link, 'pt')
    }
    if(translate(dados_config.current.content[0]?.mail?.btn_label, 'pt') !== '') {
      mail_btn_text = translate(dados_config.current.content[0]?.mail?.btn_label, 'pt')
    }

    if(lang !== 'pt' && dados_config.current.content[0]?.mail?.file !== undefined && Object.keys(dados_config.current.content[0]?.mail?.file).length > 0 && dados_config.current.content[0]?.mail?.file[lang_id] !== undefined && dados_config.current.content[0]?.mail?.file[lang_id] !== null && dados_config.current.content[0]?.mail?.file[lang_id] !== '' && dados_config.current.content[0]?.mail?.file[lang_id]?.link !== '') {
      mail_file_link = dados_config.current.content[0]?.mail?.file[lang_id]?.link
      mail_file_name = dados_config.current.content[0]?.mail?.file[lang_id]?.name
    } else if (dados_config.current.content[0]?.mail?.file !== undefined !== undefined && Object.keys(dados_config.current.content[0]?.mail?.file).length > 0 && dados_config.current.content[0]?.mail?.file['1'] !== null && dados_config.current.content[0]?.mail?.file['1'] !== '' && dados_config.current.content[0]?.mail?.file['1']?.link !== '') {
      mail_file_link = dados_config.current.content[0]?.mail?.file['1']?.link
      mail_file_name = dados_config.current.content[0]?.mail?.file['1']?.name
    } else {
      mail_file_link = ''
      mail_file_name = ''
    }

    let userCaptchaValue = document.getElementById('user_captcha_input').value;

    if (validateCaptcha(userCaptchaValue, false) === true) {
      //alert('Captcha Matched');
    }
    else {
      Swal.fire({
        title: language[lang].contact_form,
        text: language[lang].no_captcha,
        icon: 'warning',
        //showCancelButton: true,
        confirmButtonColor: '#08396a',
        //confirmButtonText: 'Tentar novamente',
      })

      return
    }

    let email_to = ''
    
    let dados_db = {
      id: '',
      info: {
        lp_id: dados_config.current.id,
        lp_name: dados_config.current.info.name,
        form_id: dados_config.current.info.form.id,
        form_name: dados_config.current.info.form.info.name,
        components: [],
        companycode: Hosts.companycode,
        date_submission: new Date()
      }
    } 

    if(dados_campanha.current === '') {

      Swal.fire({
        title: language[lang].contact_form,
        text: language[lang].no_value,
        icon: 'warning',
        //showCancelButton: true,
        confirmButtonColor: '#08396a',
        //confirmButtonText: 'Tentar novamente',
      })

    }
    else {
      if(dados_campanha.current !== '') {
        dados_db.info.components.push({
          name: 'Campanha',
          value: dados_campanha.current
        })
      }
    
      let verify = true
      for await (let el of dados_config.current.info.form.info.fields) {

        dados_db.info.components.push({
          name: el.name,
          value: el.value
        })

        if(el.name.toLowerCase() === 'email') {
          email_to = el.value
        }

        if(el.required && (el.value === undefined || el.value === null || el.value === '')) {
          verify = false
        }
      }

      const pattern_email_validation = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if(pattern_email_validation.test(email_to) === false) {
        verify = false
      }

      if(verify && email_to !== '') {

        let mail_content = '<table width="600" cellpadding="0" cellspacing="0" border="0" align="0" class="0" style="width: 600px; height: 90px; padding: 15px 30px; background-color:' + Hosts.EMAIL_BACKGROUND_COLOR +';border:1px solid transparent;border-collapse:collapse;">' +
            '<tr style="border-collapse: collapse;">' +
                '<td align="left" style="padding:40px; font-family:poppins, helvetica, arial, sans-serif; font-size:' + Hosts.EMAIL_TEXT_SIZE +'; line-height:' + Hosts.EMAIL_TEXT_LINE_HEIGHT  +'; color:' + Hosts.EMAIL_TEXT_COLOR +'">' +
                    '<div>'
        ;

        if(mail_text !== '') {
          mail_content += '<h4>'+ dados_config.current.info.name +'</h4>'
          mail_content += '<p style="white-space: pre-wrap;">' + mail_text.replace(/(?:\r\n|\r|\n)/g, '<br />') + '</p>';
        }
        if(mail_link !== '') {
          mail_content += '<div style="text-align: center; margin-bottom: 15px;"><a style="font-size: 14px; font-weight: 700; background-color: #FFFFFF; border: 2px solid ' + color_primary + '; color: ' + color_primary + '; white-space: normal; padding-bottom: 6px; padding-left: 18px; padding-right: 18px; padding-top: 8px; text-decoration: none; display: inline-block" href=' + mail_link + ' target="_blank">' + (mail_btn_text !== '' ? mail_btn_text : mail_link) + '</a></div>';
        }

        if(mail_file_link !== '') {
          mail_content += '<div style="text-align: center; margin-bottom: 15px;"><a style="font-size: 14px; font-weight: 700; background-color: #FFFFFF; border: 2px solid ' + color_primary + '; color: ' + color_primary + '; white-space: normal; padding-bottom: 6px; padding-left: 18px; padding-right: 18px; padding-top: 8px; text-decoration: none; display: inline-block" href=' + mail_file_link + ' target="_blank">' + (mail_file_name) + '</a></div>';
        }

        //mail_content += '<p></p>'

        mail_content += '<span style="text-transform: uppercase;"><b>'+ language[lang].contact_form_data +'</b></span><br/>'

        mail_content += '<span><b>Campanha: </b><label>' + dados_campanha.current + '</label></span><br/>';

        for await (let el of dados_config.current.info.form.info.fields) {
          if(el.value !== undefined){
            mail_content += '<span><b>'+ el.name +': </b><label style="white-space: pre-wrap;">' + el.value.replace(/(?:\r\n|\r|\n)/g, '<br />') + '</label></span><br/>';
          }
        }

        mail_content += '</div>'+
                '</td>' +
            '</tr>' +
        '</table>'

        // console.log('dados_db', dados_db)
        // return

        await reqPOST(Hosts.SIMCore_API + '/web/contact/form', dados_db)

        await functionSendMail(dados_config.current.content[0].contacts.email_form, [email_to], [dados_config.current.content[0].contacts.email_form], dados_config.current.info.name + ' - ' + language[lang].contact_form, mail_content, [], dados_config.current)
          .then((res) => {
              if(res === true) {

                //dados_campanha.current = ''
                dados_config.current.info.form.info.fields.forEach(el => {
                  el.value = ''
                })
                setTimeout(() => {
                  loadCaptchaEnginge(7);
                }, "1000");
                document.getElementById('user_captcha_input').value = ''

                document.getElementById('agree').checked = false
                  setAgree(false)
                  //setRefresh(refresh + 1)
                  Swal.fire({
                      title: language[lang].contact_form,
                      text: language[lang].sent_successfully,
                      icon: 'success',
                      //showCancelButton: true,
                      confirmButtonColor: '#08396a',
                      //confirmButtonText: 'Tentar novamente',
                      })

              }
              else {
                  console.log('erro')
              }
          })

      }
      else {
        Swal.fire({
          title: language[lang].contact_form,
          text: language[lang].no_form_fields,
          icon: 'warning',
          //showCancelButton: true,
          confirmButtonColor: '#08396a',
          //confirmButtonText: 'Tentar novamente',
        })
      }
    }

  }

  return isloading ?
    null
  :
    <section id="assecform">
      <div className={"div-form " + dados_config.current.info.form.info.tag}>
        <div>
          <h3 className='form-title'>{translate(dados_config.current.info.form.info.title, lang)}</h3>
          <div className='form-subtitle'>{translate(dados_config.current.info.form.info.subtitle, lang)}</div>
        </div>
        <div className='fields'>
          {
            dados_config.current.info.form.info.tag === 'form-campanha' ?
              <>
                {/*<div className="col-12 mb-3">
                  <input id={"input_campanha"} className='form-control form-control-sm border-0 rounded-0' data-order={0} type={'text'} name={'Campanha'} placeholder={language[lang].campaign_placeholder} value={dados_campanha.current} onChange={(e) => change_campanha_value(e)} required={true} readOnly />
                </div>*/}
                <input id={"input_campanha"} data-order={0} type={'hidden'} name={'Campanha'} value={dados_campanha.current} />
              </>
            :
              null
          }
          {
            dados_config.current.info.form.info.fields.map((el_f, k_f) => {
              if(el_f.type === 'text') {
                return (
                  <div key={k_f} className={"col-12 mb-3" + (el_f.name === 'Telefone' ? ' col-md-6 float-start pe-0 pe-md-3' : '')}>
                    <input className='form-control form-control-sm border-0 rounded-0' data-order={k_f} type={el_f.type} name={el_f.name} placeholder={el_f.name + (el_f.required ? '*' : '')} value={el_f.value !== undefined ? el_f.value : ''} onChange={(e) => change_fileds_value(e)} required={el_f.required} />
                  </div>
                )
              }
              else if(el_f.type === 'email') {
                return (
                  <div key={k_f} className="col-12 col-md-6 float-end mb-3">
                    <input className='form-control form-control-sm border-0 rounded-0' data-order={k_f} type={el_f.type} name={el_f.name} placeholder={el_f.name + (el_f.required ? '*' : '')} value={el_f.value !== undefined ? el_f.value : ''} onChange={(e) => change_fileds_value(e)} required={el_f.required} />
                  </div>
                )
              }
              else if(el_f.type === 'textarea') {
                return (
                  <div key={k_f} className="col-12 mb-3">
                    <textarea className='form-control form-control-sm border-0 rounded-0' data-order={k_f} type={el_f.type} rows="3" name={el_f.name} placeholder={el_f.name + (el_f.required ? '*' : '')} value={el_f.value !== undefined ? el_f.value : ''} onChange={(e) => change_fileds_value(e)} required={el_f.required} />
                  </div>
                )
              }
              else {
                return null
              }
            })
          }
        </div>
        <div className='row g-3 align-items-center mb-3'>
          <div className='col-12 col-md-6'>
            <LoadCanvasTemplate reloadText='<i class="bi bi-arrow-clockwise"></i>' reloadColor="white" />
          </div>
          <div className='col-12 col-md-6'>
            <input id="user_captcha_input" type="text" name="user_captcha_input" className='form-control border-0 rounded-0 font-weight-bolder' placeholder={language[lang].capthca_placeholder} />
          </div>
        </div>
        <div className="form-check" style={{maxWidth: '500px'}}>
          <input className="form-check-input rounded-0" type="checkbox" id="agree" onChange={checkboxHandler} defaultChecked={checkboxHandler_reload(agree)}/>
          <label className="form-check-label small" htmlFor="agree">{language[lang].agree_privacy_policy}</label>&nbsp;
          {
            lang !== 'pt' && dados_config.current.content !== undefined && dados_config.current.content[0]?.terms !== undefined && Object.keys(dados_config.current.content[0]?.terms).length > 0 && dados_config.current.content[0]?.terms[lang_id] !== undefined && dados_config.current.content[0]?.terms[lang_id] !== null && dados_config.current.content[0]?.terms[lang_id] !== '' && dados_config.current.content[0]?.terms[lang_id]?.link !== undefined && dados_config.current.content[0]?.terms[lang_id]?.link !== '' ?
              <a className="small" href={dados_config.current.content[0]?.terms[lang_id]?.link} target="_blank" rel="noreferrer">
                  {language[lang].privacy_policy_link}
              </a>
            :
              dados_config.current.content !== undefined && dados_config.current.content[0]?.terms !== undefined && Object.keys(dados_config.current.content[0]?.terms).length > 0 && dados_config.current.content[0]?.terms['1'] !== null && dados_config.current.content[0]?.terms['1'] !== '' && dados_config.current.content[0]?.terms['1']?.link !== undefined && dados_config.current.content[0]?.terms['1']?.link !== '' ?
                <a className="small" href={dados_config.current.content[0]?.terms['1']?.link} target="_blank" rel="noreferrer">
                  {language[lang].privacy_policy_link}
                </a>
              :
                  <a className="small pe-none" href='!#' target="_blank" rel="noreferrer">
                      {language[lang].privacy_policy_link}
                  </a>
          }
        </div>

        <div className='text-center d-grid mt-4 mb-1'>
          <button className="btn-alert" onClick={() => send_form()} disabled={!agree}>{language[lang].form_know_more}</button>    
        </div>
      </div>
    </section>


};
export default AssecForm;