import React, {useState, useEffect, useRef } from 'react';

import "../Pagina.css";
import "../content-styles.css"

import Swal from 'sweetalert2'

import * as Hosts from '../../../Hosts';
import {
    //set_language,
	reqGET,
	// reqPOST,
	reqPUT,
    content_language,
    passwordHash,
    // upgrade_cart,
    // my_local_storage,
    // repo_site_assets_link,
    // functionSendMail,

} from '../../../Utils';

import Loader from '../../../loader'

import Navbar from "../Navbar/Navbar";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Footer from "../Footer/Footer";

import { language } from './Language';
import useContextProvider from '../GenericStore/Providers/ContextProvider';

export default function Perfil(props) {
    const [, set_dados] = useState(null);
    const { /*updateprodnegative, removeprod, get_lista_carrinho, get_numeroProdutos, get_lista, get_ws_info, get_is_logged,*/ get_id_user/*, set_lista_carrinho, update_timer, get_linguagem, refreshlista`*/ } = useContextProvider()

    const user_info = useRef({});

	const [isloading, setLoading] = useState(true);

    const [lang, set_lang] = useState('pt');
    const [, set_lang_id] = useState('1');

    const [new_pwd, set_new_pwd] = useState('');
    const [new_pwd_conf, set_new_pwd_conf] = useState('');

    const [agree, setAgree] = useState(false);
    
    useEffect(() => {

        let getClientInfo = async (id_user) => {
            return await reqGET(`${Hosts.SIMCore_API}/entities/${Hosts.companycode}/${id_user}`)
                .then(res => {
                    console.log('set_user_info', res.data[0])

                    set_dados(user_info.current = res.data[0])
                    // set_dados(user_info.current.notifications = [])

                    setAgree(res.data[0].info.marketing)

                    setLoading(false)
                })
                .catch(erro => console.log(erro))
        }
        if (get_id_user() !== undefined && get_id_user() !== null && get_id_user() !== '') {
            getClientInfo(get_id_user())
        }

        if(localStorage.getItem('lang') === undefined  || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if(el.code === localStorage.getItem('lang')) {
                    set_lang_id(el.id)
                }
            })
        }

    }, [props, get_id_user]);

    const handleSubmit = async (e) => {
		e.preventDefault();

        console.log('user_info', user_info)

        if(user_info.current.info.name === '') {

            Swal.fire('', language[lang].name_error, 'info')
            return
        }
        if(user_info.current.info.email === '') {

            Swal.fire('', language[lang].email_error, 'info')
            return

        }

        if(new_pwd !== '') {
            if(new_pwd === new_pwd_conf) {
                let npwd = passwordHash(new_pwd)
                user_info.current.info.password = npwd

            }
            else {
                Swal.fire('', language[lang].pwd_error, 'info')
                return
            }
        }

        await reqPUT(`${Hosts.SIMCore_API}/entities`, user_info.current)

        Swal.fire('', language[lang].perfil_upadate_success, 'info')

	}

    const handle_change_info_documento_userinfo = (e) => {
        const { name, value } = e.target;

        if(name === 'email') {
            set_dados(user_info.current.info['username'] = value)
        }

        set_dados(user_info.current.info[name] = value)


    }

    const handle_change_info_documento_userinfo_addresses = (e) => {
        const { name, value } = e.target;

        set_dados(user_info.current.addresses[0][name] = value)

    }

    const handle_change_info_documento_userinfo_contacts = (e) => {
        const { name, value } = e.target;

        if (name === 'email')
            set_dados(user_info.current.info[name] = value)

        set_dados(user_info.current.contacts[0][name] = value)

    }

    const checkboxHandler = () => {
      // if agree === true, it will be set to false
      // if agree === false, it will be set to true
      setAgree(!agree);
      // Don't miss the exclamation mark
    }

    const checkboxHandler_reload = (value) => {
        //console.log('AGREE', agree)
        if (value === true)
            return true
        else 
            return false
        // if agree === false, it will be set to true
        //setAgree(!agree);
        // Don't miss the exclamation mark
    } 

    const handle_change_pwd = (e) => {
        const { name, value } = e.target;

        if(name === 'new_pwd') {
            set_new_pwd(value)
        }
        else if(name === 'new_pwd_conf') {
            console.log('conf')
            set_new_pwd_conf(value)
        }

    }

    return (
        isloading ?
            <Loader />
        :
        <>
            <Navbar /> 

            <Breadcrumb /> 

            <div id="cart" className="container-fluid my-5">
                <div className="row">
                    <div className="container">

                        <div className='row'>
                            <div className='col-12 col-lg-10'>

                                <div>
                                    <div className="my-3">
                                        <h3 className="titulo-primario">{language[lang].dados_pessoais}</h3>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-8">
                                            <label>{language[lang].dados_nome} (*)</label>
                                            <input type="text" name="name" className="form-control" placeholder="" value={user_info.current.info.name} onChange={handle_change_info_documento_userinfo} />
                                        </div>

                                        <div className="col-4">
                                            <label>{language[lang].dados_vat}</label>
                                            <input type="text" name="vat" className="form-control" placeholder="" value={user_info.current.info.vat} onChange={handle_change_info_documento_userinfo} />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-8">
                                            <label>{language[lang].dados_email} (*)</label>
                                            <input type="email" name="email" className="form-control" placeholder="" value={(user_info.current.contacts[0].email !== '' ? user_info.current.contacts[0].email : user_info.current.info.email)} onChange={handle_change_info_documento_userinfo_contacts} />
                                        </div>

                                        <div className="col-4">
                                            <label>{language[lang].dados_mobile_phone}</label>
                                            <input type="text" name="mobile_phone" className="form-control" placeholder="" value={user_info.current.contacts[0].mobile_phone} onChange={handle_change_info_documento_userinfo_contacts} />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-8">
                                            <label>{language[lang].dados_morada}</label>
                                            <textarea type="text" name="address_detail" className="form-control" placeholder="" value={user_info.current.addresses[0].address_detail} onChange={handle_change_info_documento_userinfo_addresses} />
                                        </div>

                                        <div className="col-4">
                                            <label className="form-label">{language[lang].dados_localidade}</label>
                                            <input type="text" name="localidade" className="form-control" placeholder="" value={user_info.current.addresses[0].localidade} onChange={handle_change_info_documento_userinfo_addresses} />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-6">
                                            <label>{language[lang].dados_codigo_postal}</label>
                                            <input type="text" name="postal_code" className="form-control" placeholder="" value={user_info.current.addresses[0].postal_code} onChange={handle_change_info_documento_userinfo_addresses} />
                                        </div>
                                        <div className="col-6">
                                            <label>{language[lang].dados_pais}</label>
                                            <input type="text" name="country" className="form-control" placeholder="" value={user_info.current.addresses[0].country} onChange={handle_change_info_documento_userinfo_addresses} />
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <div className="col-12">
                                            <input type="checkbox" id="agree" onChange={checkboxHandler} defaultChecked={checkboxHandler_reload(agree)} />
                                            <label className="pl-2" htmlFor="agree">{language[lang].agree_marketing}</label>

                                            <label className="pl-2 small" htmlFor="agree">{language[lang].terms_conditions_required}</label>
                                        </div>
                                    </div>

                                </div>

                                <div className='mt-5'>
                                    <div className="my-3">
                                        <h3 className="titulo-primario">{language[lang].dados_acesso}</h3>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-12 col-sm-6">
                                            <label>{language[lang].new_pwd}</label>
                                            <input type="password" name="new_pwd" className="form-control" placeholder="" value={new_pwd} onChange={handle_change_pwd} />
                                        </div>

                                        <div className="col-12 col-sm-6">
                                            <label>{language[lang].new_pwd_conf}</label>
                                            <input type="password" name="new_pwd_conf" className="form-control" placeholder="" defaultValue={new_pwd_conf} onBlur={handle_change_pwd} />
                                        </div>
                                    </div>
                                </div>


                                <div className='text-right mt-5'>
                                    <button className="btn btn-secondary px-4" onClick={handleSubmit}> {language[lang].salvar} </button>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            
            <Footer />
        </>

    );
}

