import React, { useState, useEffect } from 'react';

//import { Fancybox as NativeFancybox } from "@fancyapps/ui/dist/fancybox.esm.js";
import "@fancyapps/ui/dist/fancybox.css";

import ReactHtmlParser/*, { processNodes, convertNodeToElement, htmlparser2 }*/ from 'react-html-parser';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

import { /*repo_logo_link,*/ content_language  } from '../../../Utils';

import Navbar from '../Navbar/Navbar_00';
import Footer from '../Footer/Footer';
import Loader from '../../../loader';
import Preview from '../Preview/Preview';

import AssecImage from "../ASSEC_Image/Image"

export default function List(props) {

    const [dados_config, set_dados_config] = useState({});
    const [navbar, set_navbar] = useState({});

    const [isloading, setLoading] = useState(true);

    const [lang, set_lang] = useState(localStorage.getItem('lang'));

    const [lang_id, set_lang_id] = useState('1');

    const data_interface = {
        id: '',
        info: {},
        content: {}
    }
    const [content, set_content] = useState(data_interface);

    useEffect(() => {

        if(props.info !== undefined && props.info !== null && props.info !== '') {
            set_dados_config(props.info.state.dados_config)
            set_navbar(props.info.state.dados_navbar)
            set_content(props.info.state.dados_content)
        }

        try {

            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                set_lang_id(el.id)
                }
            })

            setLoading(false)

            return

        } catch {
            console.log('nada')

			return
		}
        
    }, [props]);

    return (isloading ?
		<Loader />
	:
        <>
            {
            content.info['preview'] === true ? 
                <Preview />
            : 
                null
            }
            <Navbar dados_config={dados_config} dados_navbar={navbar} />
            {/*<Breadcrumb dados_config={dados_config} dados_navbar={navbar} dados_content={content} />*/}
            <main className="list list-alt mt-3 mb-3 mb-lg-5">

                <section className="content-body">
                    <div className="container">
                        <div className="row">
                        {
                        content.content.map((el, k) => {  
                            if (k === 0){
                                return (
                                    <div className="col-12" key={k}>
                                        {
                                            <div data-k={k}>
                                                {
                                                lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el['language'][lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['image'] !== undefined && el.language[lang_id]['media']['image'].length > 0 ?
                                                    <div className="div-image w-100"> 
                                                        <a
                                                            href={el['media']['image'][0]['link']}
                                                            data-fancybox="gallery"
                                                            data-caption={el['media']['image'][0]['link'].includes(el['media']['image'][0]['name']) ? '' : el['media']['image'][0]['name']}
                                                        >
                                                            <AssecImage 
                                                                linkImage={el['media']['image'][0]['link']}
                                                                width="1280"
                                                                height="720"
                                                                fit='crop'
                                                                type='img'
                                                                sClass="img-fluid rounded shadow-sm" 
                                                                alt={el['media']['image'][0]['name']}
                                                            />
                                                            {/*<img src={el['media']['image'][0]['link']} className="img-fluid rounded shadow-sm" alt={el['media']['image'][0]['name']} />*/}
                                                        </a>
                                                    </div>
                                                :
                                                    el['media'] !== undefined && el['media']['image'] !== undefined && el['media']['image'].length > 0 ? 
                                                        <div className="div-image w-100"> 
                                                            <a
                                                                href={el['media']['image'][0]['link']}
                                                                data-fancybox="gallery"
                                                                data-caption={el['media']['image'][0]['link'].includes(el['media']['image'][0]['name']) ? '' : el['media']['image'][0]['name']}
                                                            >
                                                                <AssecImage 
                                                                    linkImage={el['media']['image'][0]['link']}
                                                                    width="1280"
                                                                    height="720"
                                                                    fit='crop'
                                                                    type='img'
                                                                    sClass="img-fluid rounded shadow-sm" 
                                                                    alt={el['media']['image'][0]['name']}
                                                                />
                                                                {/*<img src={el['media']['image'][0]['link']} className="img-fluid rounded shadow-sm" alt={el['media']['image'][0]['name']} />*/}
                                                            </a>
                                                        </div>
                                                    : 
                                                        null 
                                                }

                                                {/*
                                                <div className="div-image w-100">
                                                    <img src={repo_img_link("nophoto.png")} className="img-fluid ms-5 rounded shadow-sm" alt="" />
                                                </div>
                                                */}

                                                <div className="div-content my-5" data-k={k}>
                                                    <h2 className={el.title !== '' ? "titulo-primario" : "d-none"}>
                                                        {lang === 'pt' || el.language === undefined ? el.title : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].title === undefined || el.language[lang_id].title === '' ? el.title : el.language[lang_id].title)}
                                                    </h2>
                                                    { 
                                                        el.subtitle !== '' ?
                                                            <h2 className={el.subtitle !== '' ? "titulo-secundario mb-3 mb-lg-5" : "d-none"}>
                                                                {lang === 'pt' || el.language === undefined ? el.subtitle : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].subtitle === undefined || el.language[lang_id].subtitle === '' ? el.subtitle : el.language[lang_id].subtitle)}
                                                            </h2>
                                                        :
                                                            ''
                                                    }
                                                    { 
                                                        el.text !== '' ?
                                                            <div className={"div-text"}>
                                                                {ReactHtmlParser(lang === 'pt' || el.language === undefined ? el.text : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].text === undefined || el.language[lang_id].text === '' ? el.text : el.language[lang_id].text))}
                                                            </div>
                                                        :
                                                            ''
                                                    }
                                                </div>

                                                {
                                                lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el['language'][lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['image'] !== undefined && el.language[lang_id]['media']['image'].length > 1 ?
                                                    <div className="div-content mb-5">
                                                        <div className="row div-gallery">
                                                            {
                                                            el.language[lang_id]['media']['image'].slice(1).map((el_img, k_img) => {
                                                                return (
                                                                    <div key={k_img} className="col-2">
                                                                        <figure>
                                                                            <AssecImage 
                                                                                linkImage={el_img.link}
                                                                                width="450"
                                                                                height="300"
                                                                                fit='crop'
                                                                                type='img'
                                                                                sClass="img-fluid" 
                                                                                alt={el_img.name}
                                                                            />
                                                                            {/*<img className="img-fluid" src={el_img.link} alt={el_img.name} title={el_img.name} />*/}
                                                                            <figcaption>{el_img.description}</figcaption>
                                                                        </figure>
                                                                    </div>
                                                                )
                                                            })
                                                            }
                                                        </div>
                                                    </div>
                                                :
                                                    el['media'] !== undefined && el['media']['image'] !== undefined && el.media.image.length > 1 ? 
                                                        (
                                                        <div className="div-content mb-5">
                                                            <div className="row div-gallery">
                                                                {
                                                                el.media.image.slice(1).map((el_img, k_img) => {
                                                                    return (
                                                                        <div key={k_img} className="col-2">
                                                                            <figure>
                                                                                <AssecImage 
                                                                                    linkImage={el_img.link}
                                                                                    width="450"
                                                                                    height="300"
                                                                                    fit='crop'
                                                                                    type='img'
                                                                                    sClass="img-fluid" 
                                                                                    alt={el_img.name}
                                                                                />
                                                                                {/*<img className="img-fluid" src={el_img.link} alt={el_img.name} title={el_img.name} />*/}
                                                                                <figcaption>{el_img.description}</figcaption>
                                                                            </figure>
                                                                        </div>
                                                                    )
                                                                })
                                                                }
                                                            </div>
                                                        </div>
                                                        ) 
                                                    : 
                                                        null 
                                                }

                                                {
                                                lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el['language'][lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['doc'] !== undefined && el.language[lang_id]['media']['doc'].length > 0 ?
                                                    <div className="div-content mb-5">
                                                        <div className="row div-documents">
                                                            {
                                                            el.language[lang_id]['media']['doc'].map((el_doc, k_doc) => {
                                                                return (
                                                                    <div key={k_doc} className="col-12 mb-2">
                                                                        {/* <img className="img-fluid" src={el_doc.link} title="" /> */}
                                                                        
                                                                        <a href={el_doc.link} rel="noreferrer" target="_blank">
                                                                            <FontAwesomeIcon className="font-weight-small pr-2" icon={faFilePdf} fixedWidth />
                                                                            {el_doc.name}
                                                                        </a>
                                                                    </div>
                                                                )
                                                            })
                                                            }
                                                        </div>
                                                    </div>
                                                :
                                                    el['media'] !== undefined && el['media']['doc'] !== undefined && el.media.doc.length > 0 ? 
                                                        (
                                                        <div className="div-content mb-5">
                                                            <div className="row div-documents">
                                                                {
                                                                el.media.doc.map((el_doc, k_doc) => {
                                                                    return (
                                                                        <div key={k_doc} className="col-12 mb-2">
                                                                            {/* <img className="img-fluid" src={el_doc.link} title="" /> */}
                                                                            
                                                                            <a href={el_doc.link} rel="noreferrer" target="_blank">
                                                                                <FontAwesomeIcon className="font-weight-small pr-2" icon={faFilePdf} fixedWidth />
                                                                                {el_doc.name}
                                                                            </a>
                                                                        </div>
                                                                    )
                                                                })
                                                                }
                                                            </div>
                                                        </div>
                                                        ) 
                                                    : 
                                                        null 
                                                }
                                            </div>
                                        }
                                    </div>
                                )
                            }
                            return ''
                        })
                        }
                        </div>
                    </div>
                </section>

                <section className="content-body content-list">
                    <div className="container">
                            {
                            content.content.map((el, k) => {  
                                return (
                                    k > 0 ?
                                        /*<div key={k} className={el['media'] !== undefined && el['media']['image'] !== undefined && el.media.image.length > 0 ? "d-flex align-items-top row mb-3" : "row mb-5"} >*/
                                        <div key={k} className={"align-items-top row mb-3"} >
                                            {
                                            lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el['language'][lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['image'] !== undefined && el.language[lang_id]['media']['image'].length > 0 ?
                                                <div className="col-12">
                                                    <div className={(k % 2 !== 0 ? 'col-image float-md-right ml-lg-5 mb-5' : 'col-image float-md-left mr-lg-5 mb-5')}>
                                                        <div className="img-list2 mx-auto d-block rounded shadow-sm">
                                                            <a
                                                                href={el['media']['image'][0]['link']}
                                                                data-fancybox={"gallery_" + k}
                                                                data-caption={el['media']['image'][0]['link'].includes(el['media']['image'][0]['name']) ? '' : el['media']['image'][0]['name']}
                                                            >
                                                                <AssecImage 
                                                                    linkImage={el['media']['image'][0]['link']}
                                                                    width="450"
                                                                    height="300"
                                                                    fit='crop'
                                                                    type='img'
                                                                    sClass="img-fluid" 
                                                                    alt={el['media']['image'][0]['name']}
                                                                />
                                                                {/*<img src={el['media']['image'][0]['link']} className="img-fluid" alt={el['media']['image'][0]['name']} />*/}
                                                            </a>
                                                        </div>

                                                        <div className="div-content">
                                                            <h2 className={el.title !== '' ? "titulo-primario" : "d-none"}>
                                                                {lang === 'pt' || el.language === undefined ? el.title : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].title === undefined || el.language[lang_id].title === '' ? el.title : el.language[lang_id].title)}
                                                            </h2>
                                                            { 
                                                                el.subtitle !== '' ?
                                                                    <h2 className={el.subtitle !== '' ? "titulo-secundario mb-3" : "d-none"}>
                                                                        {lang === 'pt' || el.language === undefined ? el.subtitle : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].subtitle === undefined || el.language[lang_id].subtitle === '' ? el.subtitle : el.language[lang_id].subtitle)}
                                                                    </h2>
                                                                :
                                                                    ''
                                                            }
                                                            { 
                                                                el.text !== '' ?
                                                                    <div className={"div-text"}>
                                                                        {ReactHtmlParser(lang === 'pt' || el.language === undefined ? el.text : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].text === undefined || el.language[lang_id].text === '' ? el.text : el.language[lang_id].text))}
                                                                    </div>
                                                                :
                                                                    ''
                                                            }
                                                        </div>

                                                    </div>

                                                    <div className="div-content">
                                                        <h2 className={el.title !== '' ? "titulo-primario" : "d-none"}>
                                                            {lang === 'pt' || el.language === undefined ? el.title : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].title === undefined || el.language[lang_id].title === '' ? el.title : el.language[lang_id].title)}
                                                        </h2>
                                                        { 
                                                            el.subtitle !== '' ?
                                                                <h2 className={el.subtitle !== '' ? "titulo-secundario mb-3" : "d-none"}>
                                                                    {lang === 'pt' || el.language === undefined ? el.subtitle : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].subtitle === undefined || el.language[lang_id].subtitle === '' ? el.subtitle : el.language[lang_id].subtitle)}
                                                                </h2>
                                                            :
                                                                ''
                                                        }
                                                        { 
                                                            el.text !== '' ?
                                                                <div className={"div-text"}>
                                                                    {ReactHtmlParser(lang === 'pt' || el.language === undefined ? el.text : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].text === undefined || el.language[lang_id].text === '' ? el.text : el.language[lang_id].text))}
                                                                </div>
                                                            :
                                                                ''
                                                        }
                                                    </div>
                                                </div>
                                            :
                                                el['media'] !== undefined && el['media']['image'] !== undefined && el.media.image.length > 0 ?
                                                    <div className="col-12">
                                                        <div className={(k % 2 !== 0 ? 'col-image float-md-right ml-lg-5 mb-5' : 'col-image float-md-left mr-lg-5 mb-5')}>
                                                            <div className="img-list2 mx-auto d-block rounded shadow-sm">
                                                            <a
                                                                href={el['media']['image'][0]['link']}
                                                                data-fancybox={"gallery_" + k}
                                                                data-caption={el['media']['image'][0]['link'].includes(el['media']['image'][0]['name']) ? '' : el['media']['image'][0]['name']}
                                                            >
                                                                <AssecImage 
                                                                    linkImage={el['media']['image'][0]['link']}
                                                                    width="450"
                                                                    height="300"
                                                                    fit='crop'
                                                                    type='img'
                                                                    sClass="img-fluid" 
                                                                    alt={el['media']['image'][0]['name']}
                                                                />
                                                                {/*<img src={el['media']['image'][0]['link']} className="img-fluid" alt={el['media']['image'][0]['name']} />*/}
                                                            </a>
                                                        </div>
                                                        </div>

                                                        <div className="div-content">
                                                            <h2 className={el.title !== '' ? "titulo-primario" : "d-none"}>
                                                                {lang === 'pt' || el.language === undefined ? el.title : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].title === undefined || el.language[lang_id].title === '' ? el.title : el.language[lang_id].title)}
                                                            </h2>
                                                            { 
                                                                el.subtitle !== '' ?
                                                                    <h2 className={el.subtitle !== '' ? "titulo-secundario mb-3" : "d-none"}>
                                                                        {lang === 'pt' || el.language === undefined ? el.subtitle : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].subtitle === undefined || el.language[lang_id].subtitle === '' ? el.subtitle : el.language[lang_id].subtitle)}
                                                                    </h2>
                                                                :
                                                                    ''
                                                            }
                                                            { 
                                                                el.text !== '' ?
                                                                    <div className={"div-text"}>
                                                                        {ReactHtmlParser(lang === 'pt' || el.language === undefined ? el.text : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].text === undefined || el.language[lang_id].text === '' ? el.text : el.language[lang_id].text))}
                                                                    </div>
                                                                :
                                                                    ''
                                                            }
                                                        </div>
                                                    </div>
                                                :
                                                    <div className="col-12">
                                                        <div className="div-content 1">
                                                            <h2 className={el.title !== '' ? "titulo-primario" : "d-none"}>
                                                                {lang === 'pt' || el.language === undefined ? el.title : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].title === undefined || el.language[lang_id].title === '' ? el.title : el.language[lang_id].title)}
                                                            </h2>
                                                            { 
                                                                el.subtitle !== '' ?
                                                                    <h2 className={el.subtitle !== '' ? "titulo-secundario mb-3" : "d-none"}>
                                                                        {lang === 'pt' || el.language === undefined ? el.subtitle : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].subtitle === undefined || el.language[lang_id].subtitle === '' ? el.subtitle : el.language[lang_id].subtitle)}
                                                                    </h2>
                                                                :
                                                                    ''
                                                            }
                                                            { 
                                                                el.text !== '' ?
                                                                    <div className={"div-text"}>
                                                                        {ReactHtmlParser(lang === 'pt' || el.language === undefined ? el.text : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].text === undefined || el.language[lang_id].text === '' ? el.text : el.language[lang_id].text))}
                                                                    </div>
                                                                :
                                                                    ''
                                                            }
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                    :
                                        null
                                )
                            })
                            }
                    </div>
                </section>

            </main>
            <Footer dados_config={dados_config} dados_navbar={navbar} />
        </>
    )
}