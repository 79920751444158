import React, { useState, useEffect, useRef } from 'react'

// import Loader from '../../../loader';

import ASSECForms01 from './ASSECForms_01';
import ASSECForms02 from './ASSECForms_02';
import ASSECForms03 from './ASSECForms_03';

import './ASSEC_Form.css';

export default function Content(props) {

	const [, set_dados] = useState(null)
	const dados_config = useRef({})
	const template = useRef({})
	// const layout = useRef('')
	const content = useRef({})
	const pw = useRef({})

    const [isloading, setLoading] = useState(true);

    useEffect(() => {

        if(props.dados_config !== undefined && props.dados_config !== null && props.dados_config !== '') {
            set_dados(dados_config.current = props.dados_config)
            set_dados(template.current = props.dados_config.info.template)
            set_dados(content.current = props.dados_config)
            set_dados(pw.current = props.pag_welcome)
            // set_dados(layout.current = props.layout)

            setLoading(false)
        }

    }, [props]);

    return (isloading ?
		null
    :
        <>
            {
            dados_config?.current?.info?.form?.info?.tag === 'form-produto' ?
                <ASSECForms01 pag_welcome={pw.current} dados_config={dados_config.current} />
            :
            dados_config?.current?.info?.form?.info?.tag === 'form-campanha' ?
                <ASSECForms02 pag_welcome={pw.current} dados_config={dados_config.current} />
            :
            dados_config?.current?.info?.form?.info?.tag === 'form-inscricao' ?
                <ASSECForms03 pag_welcome={pw.current} dados_config={dados_config.current} />
            :
                <ASSECForms01 pag_welcome={pw.current} dados_config={dados_config.current} />
            }
        </>
    )
}
