import React, { useState, useEffect } from 'react';

import ReactHtmlParser/*, { processNodes, convertNodeToElement, htmlparser2 }*/ from 'react-html-parser';
//import parse from 'html-react-parser';
//import { Helmet } from 'react-helmet';

import * as Hosts from '../../../Hosts';
import { reqGET, content_language } from '../../../Utils';

import Loader from '../../../loader';
import Navbar from '../Navbar/Navbar_00';
import Footer from '../Footer/Footer';
// import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { language } from '../WEBContent/Language';

import AssecImage from "../ASSEC_Image/Image"

export default function RestaurantCards(props) {

    const [dados_config, set_dados_config] = useState({});
    const [navbar, set_navbar] = useState({});

    const [isloading, setLoading] = useState(true);

    const [lang, set_lang] = useState('pt');
    const [lang_id, set_lang_id] = useState('1');

    const [content_parent, ] = useState(null);
    const [list_content, set_list_content] = useState([]);

    useEffect(() => {

        if(props.info !== undefined && props.info !== null && props.info !== '') {
            set_dados_config(props.info.state.dados_config)
            set_navbar(props.info.state.dados_navbar)
        }

        try {

            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                set_lang_id(el.id)
                }
            })

            let getInfoList = async () => {
                await reqGET(`${Hosts.SIMCore_API}/web/content/type/${Hosts.companycode}/restaurant/order`)
                    .then(res => { 
                        if (res.data.length > 0) {

                            var hoje = new Date();
                            let aux = []
                            res.data.forEach((v) => {
                              if(v.info.date.published !== undefined && v.info.date.published !== '') {
                                  var date_p = new Date(v.info.date.published);
                    
                                  if(hoje >= date_p) 
                                    aux.push(v)
                              }
                            })
                            set_list_content(aux)
                            //set_list_content(res.data)
                            console.log('LIST_CONTENT', aux)
                  
                            setLoading(false)
                          }
                    })
                    .catch(erro => console.log('ERROR', erro))
            }
            getInfoList()
 
            return

        } catch {
            console.log('nada')

			return
		}
        
    }, [props]);

    return (isloading ?
		<Loader />
	:
        <>
            <Navbar dados_config={dados_config} dados_navbar={navbar} />
            <main className="restaurant-cards">
                <section id="restaurant-cards" className="content-body">

                    <div className="container-fluid">
                        <div className="row">
                            <div className='col-12 bg-light py-5'>
                                <div className='container'>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <h3 className="titulo-primario">
                                                <span>{language[lang].restauracao}</span>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <div className="row">
                            <div className="col-12 py-3">

                                <div className="row">
                                    {
                                    content_parent !== undefined && content_parent !== null ?
                                        <div className="div-content col-12 mb-3 mb-md-5">
                                            <h2 className="titulo-primario">
                                                {/*content_parent['content'][0] !== undefined && content_parent['content'][0]['title'] !== undefined && content_parent.content[0].title !== '' ? content_parent.content[0].title : 'Parceiros'*/}
                                                {(lang === 'pt' || content_parent['content'][0].language === undefined ? content_parent['content'][0].title : (content_parent['content'][0].language[lang_id] === undefined || content_parent['content'][0].language[lang_id].title === undefined || content_parent['content'][0].language[lang_id].title === '' ? content_parent['content'][0].title : content_parent['content'][0].language[lang_id].title))}
                                            </h2>
                                            <h2 className={content_parent['content'][0] !== undefined && content_parent['content'][0]['subtitle'] !== undefined && content_parent.content[0].subtitle !== '' ? "titulo-secundario" : "d-none"}>
                                                {/*content_parent['content'][0] !== undefined && content_parent['content'][0]['subtitle'] !== undefined && content_parent.content[0].subtitle !== '' ? content_parent.content[0].subtitle : ''*/}
                                                {(lang === 'pt' || content_parent['content'][0].language === undefined ? content_parent['content'][0].subtitle : (content_parent['content'][0].language[lang_id] === undefined || content_parent['content'][0].language[lang_id].subtitle === undefined || content_parent['content'][0].language[lang_id].subtitle === '' ? content_parent['content'][0].subtitle : content_parent['content'][0].language[lang_id].subtitle))}
                                            </h2>
                                            <div className={"div-text"}>
                                                {/*content_parent['content'][0] !== undefined && content_parent['content'][0]['text'] !== undefined && content_parent.content[0].text !== '' ? ReactHtmlParser(content_parent.content[0].text) : ''*/}
                                                {ReactHtmlParser(lang === 'pt' || content_parent['content'][0].language === undefined ? content_parent['content'][0].text : (content_parent['content'][0].language[lang_id] === undefined || content_parent['content'][0].language[lang_id].text === undefined || content_parent['content'][0].language[lang_id].text === '' ? content_parent['content'][0].text : content_parent['content'][0].language[lang_id].text))}
                                            </div>
                                        </div>
                                    :
                                        null
                                    }
                                </div>

                                <div className="row p-3">
                                    {
                                    list_content.map((content) => {
                                        return (
                                            content.content.map((el, k) => {  
                                                return (
                                                    <div key={k} className="col-6 col-md-4 col-lg-4 my-3">
                                                        <div className="card restaurant-item hover-animate border-0">
                                                            <a href={'./restaurant/' + (content.info['slug'] !== undefined && content.info.slug !== '' ? content.info.slug : content.id)} title={el.title}>
                                                                {
                                                                el['media'] !== undefined && el['media']['image'] !== undefined && el['media']['image'].length > 0 ?
                                                                    <div className="restaurant-img"> 
                                                                        <AssecImage 
                                                                            linkImage={el['media']['image'][0]['link']}
                                                                            width="400"
                                                                            height="300"
                                                                            fit='crop'
                                                                            type='img'
                                                                            sClass="img-fluid rounded mb-2" 
                                                                            alt={el['media']['image'][0]['name']}
                                                                        />
                                                                        {/*<img src={el['media']['image'][0]['link']} className="img-fluid" alt={el['media']['image'][0]['name']} />*/}
                                                                    </div>
                                                                :
                                                                    null
                                                                }
                                                                <div className="card-body pb-3 d-none d-md-block bg-light rounded">
                                                                    <div className="card-bar mb-3"></div>
                                                                    <div className="card-title">
                                                                        {(lang === 'pt' || el.language === undefined ? el.title : (el.language[lang_id] === undefined || el.language[lang_id].title === undefined || el.language[lang_id].title === '' ? el.title : el.language[lang_id].title))}
                                                                    </div>
                                                                    <div className="card-text limit-5-lines">
                                                                        {ReactHtmlParser(lang === 'pt' || el.language === undefined ? el.abstract : (el.language[lang_id] === undefined || el.language[lang_id].abstract === undefined || el.language[lang_id].abstract === '' ? el.abstract : el.language[lang_id].abstract))}
                                                                    </div>
                                                                </div>
                                                            </a>
                                                            <div className="card-footer text-center d-none">
                                                                <a className="btn btn-text" href={'./restaurant/' + content.id} title={language[lang].see_restaurant}>{language[lang].see_restaurant}<i className="bi bi-chevron-right"></i>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div> 
                                                )
                                            })
                                        )
                                    })
                                    }
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer dados_config={dados_config} dados_navbar={navbar} />
        </>
    )
}