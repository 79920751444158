import React, { useState, useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import './App.css';

// import ASSEC_Helmet from './components/Pagina/ASSEC_Helmet/ASSEC_Helmet_async';

// import { Helmet } from 'react-helmet';
// import { Helmet, HelmetProvider } from 'react-helmet-async';


import useToken from './UseToken';
import { getTokenInfo } from './UseToken';

import Preview from './components/Pagina/Preview/Preview';

// import Pagina from './components/Pagina/Pagina';
import Pagina01 from './components/Pagina/Pagina_01';
import Pagina02 from './components/Pagina/Pagina_02';
import Pagina03 from './components/Pagina/Pagina_03';
import Pagina04 from './components/Pagina/Pagina_04';

import Cookies from "./components/Pagina/Cookies/Cookies";
import GoTopButton from './components/Pagina/GoTopButton/GoTopButton';

import Content from './components/Pagina/Content/Content';

import Store from './components/Pagina/Store/Content';

import Cart from './components/Pagina/Cart/Cart';
import PaymentNotificationPage from './components/Pagina/Store/PaymentNotificationPage';
import PaymentNotificationPageApp from './components/Pagina/Store/PaymentNotificationPageApp';

import RegistClient from "./components/Pagina/Forms/RegisterClient";
import RecoveryClient from "./components/Pagina/Forms/RecoveryClient";

import PerfilClient from "./components/Pagina/Cliente/Perfil";
import OrdersClient from "./components/Pagina/Cliente/Encomendas";

import Loader from './loader'

import * as Hosts from './Hosts';

import {
  repo_favicon_link,
  reqGET,
  set_language,
  translate
} from './Utils';

import GenericStore from './components/Pagina/GenericStore/GenericStore';
import ProdByCategory from './components/Pagina/GenericStore/ProdByCategory';
// import useContextProvider from './components/Pagina/GenericStore/Providers/ContextProvider';
// import { useHistory } from 'react-router-dom/cjs/react-router-dom';

//if (process.env.REACT_APP_MODE === 'production') console.log = function () { };

function App() {
  
  const { pathname } = useLocation();

  const { setToken } = useToken();

  // const [, set_is_loading] = useState(true)

  const [maintenance, set_maintenance] = useState(false)
  const [available, set_available] = useState(false)
  const [template, set_template] = useState({})
  const [theme, set_theme] = useState({})
  const [dados_config, set_dados_config] = useState({})

  const [lang, ] = useState(localStorage.getItem('lang'))

  const handleFavIcon = (url) => {
    function getFaviconEl() {
      return document.getElementById("favicon");
    }
    const favicon = getFaviconEl(); // Accessing favicon element
    
    if(url !== undefined && url !== '') {
      favicon.href = url;
    }
    else {
      favicon.href = repo_favicon_link("favicon.ico");
    }
  };
  // handleFavIcon()

  //LOADER TIMEOUT
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    window.process = {
      ...window.process,
    };
  }, []);

  useEffect(() => {
    if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null) {
      set_language('pt')
    }

    setToken({ "token_site": Hosts.SITES.SIMWEBSITE.ACCESS_TOKEN })

    const timer = setTimeout(() => {
      setIsLoading(false);
      //setCount('Timeout called!');
    }, 500);

    return () => clearTimeout(timer);

    /*    setTimeout(() => {
        setIsLoading(false);
      }, 500);  */
  }, [setToken])

  useEffect(() => {

    /*
    const aux_func = async () => {
      // await  useContextProvider().on_init(set_is_loading)
      await on_init(set_is_loading, history.action, get_lista_carrinho)

      if (get_is_logged() === true) {
        await set_var_timer_countdown()

      }

      console.log('############')

    }
    */

    (async () => {
      // await aux_func()
      // window.addEventListener("focus", async() => await on_init(set_is_loading));
      //console.error('history.action', history.action)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  useEffect(() => {
    
    const getConfig = async (id) => {

      if(id !== undefined && id !== null && id !== '') {

        await reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/${id}`)
          .then(res => {
            
            if(res.data.length > 0) {
              set_template(res.data[0].info.template !== undefined && res.data[0].info.template.id !== undefined ? res.data[0].info.template : {info: {tag: ''}})
              set_theme(res.data[0].info.theme !== undefined && res.data[0].info.theme.id !== undefined ? res.data[0].info.theme : {info: {tag: ''}})
              set_maintenance(res.data[0].info.maintenance)
              set_available(res.data[0].info.available)
              set_dados_config(res.data[0])

              document.getElementById("head_style").innerHTML = ":root { --primary: " + res.data[0].info.theme.info.color.primary + " !important; --primary-rgb: " + res.data[0].info.theme.info.color.primary_rgb + " !important; --secondary: " + res.data[0].info.theme.info.color.secondary + " !important; }";
              document.title = translate(res.data[0].content[0].title, 'pt');
              // document.title = 'asdasdasasd';

              if(res.data[0].content[0].favicon.url !== undefined && res.data[0].content[0].favicon.url !== '') {
                handleFavIcon(res.data[0].content[0].favicon.url)
              }
              else {
                handleFavIcon()
              }
            }
            else {
              set_template({info: {tag: ''}})
              set_theme({info: {tag: ''}})
              set_maintenance(true)
              set_available(false)
              set_dados_config({info: {maintenance: true, available: false}})

              handleFavIcon()
            }

          })
          .catch(erro => console.log('Erro', erro))

      }
      else {
        await reqGET(`${Hosts.SIMCore_API}/web/content/type/${Hosts.companycode}/landingpage/available`)
          .then(res => {
            
            if(res.data.length > 0) {
              set_template(res.data[0].info.template !== undefined && res.data[0].info.template.id !== undefined ? res.data[0].info.template : {info: {tag: ''}})
              set_theme(res.data[0].info.theme !== undefined && res.data[0].info.theme.id !== undefined ? res.data[0].info.theme : {info: {tag: ''}})
              set_maintenance(res.data[0].info.maintenance)
              set_available(res.data[0].info.available)
              set_dados_config(res.data[0])

              document.getElementById("head_style").innerHTML = ":root { --primary: " + res.data[0].info.theme.info.color.primary + " !important; --primary-rgb: " + res.data[0].info.theme.info.color.primary_rgb + " !important; --secondary: " + res.data[0].info.theme.info.color.secondary + " !important; }";
              document.title = translate(res.data[0].content[0].title, lang);
              // document.title = 'asdasdasasd';

              if(res.data[0].content[0].favicon.url !== undefined && res.data[0].content[0].favicon.url !== '') {
                handleFavIcon(res.data[0].content[0].favicon.url)
              }
              else {
                handleFavIcon()
              }
            }
            else {
              set_template({info: {tag: ''}})
              set_theme({info: {tag: ''}})
              set_maintenance(true)
              set_available(false)
              set_dados_config({info: {maintenance: true, available: false}})

              handleFavIcon()
            }

          })
          .catch(erro => console.log('Erro', erro))
      }
    }
    
    (async () => {
      if((getTokenInfo()?.profile.includes('Administrador') || getTokenInfo()?.profile.includes('Gestor')) && pathname.includes('preview')) {

        let id_aux = pathname.split('/').pop()
        if(id_aux !== '') {

          await getConfig(id_aux)
        }

      }
      else {
        await getConfig()
      }

    })()

  }, [pathname, lang])

  return (
      isLoading ?
        <Loader />
      :
        <>
          <Cookies item={{template: template?.info?.tag, theme: theme?.info?.tag, dados_config: dados_config}} />
          <GoTopButton item={{template: template?.info?.tag, theme: theme?.info?.tag}} />

          <div className={template?.info?.tag + ' ' + theme?.info?.tag}>
            <Switch>
              {
              maintenance || !available ?
                <> 
                  {
                    getTokenInfo()?.profile.includes('Administrador') || getTokenInfo()?.profile.includes('Gestor') ? (
                        <>
                          <Preview />
                          {
                          template?.info?.tag === 'page-01' ?
                            <Route path='/preview/:id_preview' exact render={(props) => <Pagina01 {...props} dados_config={dados_config} />} /*component={Pagina01}*/ />
                          :
                          template?.info?.tag === 'page-02' ?
                            <Route path='/preview/:id_preview' exact render={(props) => <Pagina02 {...props} dados_config={dados_config} />} /*component={Pagina02}*/ />
                          :
                          template?.info?.tag === 'page-03' ?
                            <Route path='/preview/:id_preview' exact render={(props) => <Pagina03 {...props} dados_config={dados_config} />} /*component={Pagina03}*/ />
                          :
                          template?.info?.tag === 'page-04' ?
                            <Route path='/preview/:id_preview' exact render={(props) => <Pagina04 {...props} dados_config={dados_config} />} /*component={Pagina04}*/ />
                          :
                            <Route component={Content} />
                          }
                        </>
                      )
                    :
                      <Route component={Content} />
                  }
                </>
              :
                template?.info?.tag === 'page-01' ?
                  <Route path='/' exact render={(props) => <Pagina01 {...props} dados_config={dados_config} />} /*component={Pagina01}*/ />
                :
                  template?.info?.tag === 'page-02' ?
                    <Route path='/' exact render={(props) => <Pagina02 {...props} dados_config={dados_config} />} /*component={Pagina02}*/ />
                  :
                    template?.info?.tag === 'page-03' ?
                      <Route path='/' exact render={(props) => <Pagina03 {...props} dados_config={dados_config} />} /*component={Pagina03}*/ />
                    :
                      template?.info?.tag === 'page-04' ?
                        <Route path='/' exact render={(props) => <Pagina04 {...props} dados_config={dados_config} />} /*component={Pagina04}*/ />
                      :
                        <Route path='/' exact render={(props) => <Pagina01 {...props} dados_config={dados_config} />} /*component={Pagina01}*/ />
              }

              <Route path={"/pag/:pag_id"} component={Content} exact={true} />

              <Route path={"/article/:article_id"} component={Content} exact={true} />
              <Route path={"/news/:article_id"} component={Content} exact={true} />
              <Route path={"/press/:press_id"} component={Content} exact={true} />
              <Route path={"/gallery/:gallery_id"} component={Content} exact={true} />
              <Route path={"/partner/:partner_id"} component={Content} exact={true} />
              <Route path={"/service/:service_id"} component={Content} exact={true} />
              <Route path={"/product/:product_id"} component={Content} exact={true} />
              <Route path={"/brand/:brand_id"} component={Content} exact={true} />

              <Route path={"/store/product/:product_id"} component={Store} exact={true} />

              <Route path={"/store/:category"} component={ProdByCategory} exact={true} />
              <Route path={"/store"} component={GenericStore} exact={true} />

              <Route path={"/store/cart"} component={Cart} exact={true} />
              <Route path={"/store/payment/notification/status/:key/:state"} component={PaymentNotificationPage} exact={true} />
              <Route path={"/app/payment/notification/status/:key/:state"} component={PaymentNotificationPageApp} exact={true} />

              <Route path={"/routes/:routes_id"} component={Content} exact={true} />
              <Route path={"/accommodation/:accommodation_id"} component={Content} exact={true} />
              <Route path={"/restaurant/:restaurant_id"} component={Content} exact={true} />
              <Route path={"/events/:event_id"} component={Content} exact={true} />
              <Route path={"/animation/:animation_id"} component={Content} exact={true} />

              <Route path={"/client/perfil"} component={PerfilClient} exact={true} />
              <Route path={"/client/order"} component={OrdersClient} exact={true} />

              <Route path={"/regist/client"} component={RegistClient} exact={true} />
              <Route path={"/regist/client/recovery/:hash"} component={RecoveryClient} exact={true} />

              <Route path={"/preview/:preview_id"} component={Content} exact={true} />

              <Route path={"/:slug"} component={Content} exact={true} />

              <Redirect to="/" />

            </Switch >
          </div>
        </>
  )
}

export default App;

