import React, { useState, useEffect, useRef } from 'react';

import { /*reqGET, repo_img_link,*/ content_language, translate } from '../../../../Utils';

//import * as Hosts from "../../../../Hosts";

import { language } from '../Language';

//import AssecImage from "../../ASSEC_Image/Image"

import parse from 'html-react-parser';

import Loader from '../../../../loader';
import "./HomeCallToAction_02.css"

const HomeCallToAction = (props) => {

	const [, set_dados] = useState(null)
	const cta_list = useRef([])
	const dados_config = useRef([])

    //const [cta_title, set_cta_title] = useState('')
    //const [cta_subtitle, set_cta_subtitle] = useState('')

    const [isloading, setLoading] = useState(true);

    const [lang, set_lang] = useState(localStorage.getItem('lang'));
    const [/*lang_id*/, set_lang_id] = useState('1');

    useEffect(() => {

        if(props.dados_config !== undefined && props.dados_config !== null && props.dados_config !== '') {
            set_dados(dados_config.current = props.dados_config)
            
            for (let el of dados_config.current.content[0].components) {

                if(el.info.tag === 'cta' && el.info.active) {
                    //set_cta_title(translate(el.info.title, lang))
                    //set_cta_subtitle(translate(el.info.subtitle, lang))

                    set_dados(cta_list.current = dados_config.current.content[0].cta)
                    //set_dados(cta_list.current.push(el))
                    setLoading(false)
                }
            }
        }

        if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                    set_lang_id(el.id)
                }
            })
        }

    }, [props, lang])

    return (
        isloading ?
            <Loader />
		:
            <section id="homecalltoaction_02">
                <div className="container">
                    {
                        cta_list.current !== undefined && cta_list.current !== null && cta_list.current.length > 0 ?
                            <div className="cta-content">
                                {
                                    cta_list.current.map((v, k) => {
                                        return (
                                            <div className={"row align-items-center"} key={k}>
                                                <div className='col-xl-7 col-xxl-8 text-center text-xl-start'>
                                                    {	
                                                        v.info.title !== undefined && translate(v.info.title, 'pt') !== '' ?
                                                            <h3 className="cta-title">
                                                                {translate(v.info.title, 'pt')}
                                                            </h3>
                                                        :
                                                            null
                                                    }
                                                    {	
                                                        v.info.text !== undefined && translate(v.info.text, 'pt') !== '' ?
                                                            <div className="cta-description">
                                                                {parse(translate(v.info.text, 'pt'))}
                                                            </div>
                                                        :
                                                            null
                                                    }
                                                </div>
                                                <div className='col-xl-5 col-xxl-4 text-center mt-3 mt-xl-0'>
                                                    {	
                                                        v.info.link !== undefined && translate(v.info.link, 'pt') !== '' ?
                                                            <a className="btn btn-alert" href={translate(v.info.link, 'pt').includes('http') ? translate(v.info.link, 'pt') : 'http://' + translate(v.info.link, 'pt')} target="_blank" rel="noreferrer" >
                                                                {	
                                                                    v.info.btn_label !== undefined && translate(v.info.btn_label, 'pt') !== '' ?
                                                                        translate(v.info.btn_label, 'pt')
                                                                    :
                                                                        language[lang].learn_more_2
                                                                }
                                                            </a>
                                                        :
                                                            null
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })              
                                }
                            </div>
                        :
                        null
                    }
                </div>
            </section>
    )
};
export default HomeCallToAction;