import React, { useState, useEffect } from 'react';

// import {
//     FacebookIcon,
//     FacebookShareButton,
// } from "react-share";


// import { Fancybox as NativeFancybox } from "@fancyapps/ui/dist/fancybox.esm.js";
import "@fancyapps/ui/dist/fancybox.css";

import ReactHtmlParser/*, { processNodes, convertNodeToElement, htmlparser2 }*/ from 'react-html-parser';

import { content_language } from '../../../Utils';

import Loader from '../../../loader';
import Navbar from '../Navbar/Navbar_00';
import Footer from '../Footer/Footer';
import Preview from '../Preview/Preview';

import AssecImage from "../ASSEC_Image/Image"

// import DivSharer from '../DivSharer/DivSharer';

import { language } from '../WEBContent/Language';

export default function Insight(props) {

    const [dados_config, set_dados_config] = useState({});
    const [navbar, set_navbar] = useState({});

    const [isloading, setLoading] = useState(true);

	const [lang, set_lang] = useState(localStorage.getItem('lang'));
	const [lang_id, set_lang_id] = useState('1');

    const [link, ] = useState('')

    const data_interface = {
        id: '',
        info: {},
        content: {}
    }
    const [content, ] = useState(data_interface);

    useEffect(() => {

        if(props.info !== undefined && props.info !== null && props.info !== '') {
            set_dados_config(props.info.state.dados_config)
            set_navbar(props.info.state.dados_navbar)
        }

        try {

            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                set_lang_id(el.id)
                }
            })

            setLoading(false)

            return

        } catch {
            console.log('nada')

			return
		}
        
    }, [props]);

    return (isloading ?
		<Loader />
	:
        <>
            {
            content.info['preview'] === true ? 
                <Preview />
            : 
                null
            }
            <Navbar dados_config={dados_config} dados_navbar={navbar} />
            <main className="article" id="restaurant">

                {
                content.content.map((el, k) => {  
                    return (
                        <section key={k} className="content-body">

                            <div className="container-fluid">
                                <div className="row">
                                    <div className='col-12 bg-light py-5'>
                                        <div className='container'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <h3 className="titulo-primario">
                                                        <span>
                                                            {lang === 'pt' || el.language === undefined ? el.title : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].title === undefined || el.language[lang_id].title === '' ? el.title : el.language[lang_id].title)}
                                                        </span>
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container">
                                <div className="row">
                                    <div className="px-3 my-3 text-right w-100">
                                        <a className="btn btn-text" href={'./restaurant/all'} ><i className="bi bi-chevron-left"></i> {language[lang].back}</a>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className='col-12'>
                                        <div className='row'>
                                            <div className="div-content col-12 col-md-9 col-lg-8 order-last order-md-first pr-md-4 pr-lg-5">

                                                <div className={"div-text py-3 py-md-3"}>
                                                    <p style={{display: 'inline-flex', flexWrap: 'nowrap', flexDirection: 'column'}}>
                                                        
                                                        {
                                                        el.contacts['morada'] !== undefined && el.contacts.morada !== '' ?
                                                            <span style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start'}} className="mb-2"><i className="bi bi-house mr-2"></i>
                                                                {ReactHtmlParser(el.contacts.morada.replaceAll('\n', '<br/>'))}
                                                            </span>
                                                        :
                                                            null
                                                        } 
                                                        {
                                                        el.contacts.website !== '' ?
                                                            <span style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', lineHeight: '2'}}>
                                                                <i className="bi bi-globe mr-2"></i> 
                                                                <a href={el.contacts.website.includes('http') ? el.contacts.website : 'http://' + el.contacts.website} alt="" target="_blank" rel="noreferrer">
                                                                    {
                                                                    link
                                                                    //el.contacts.website.includes(el.contacts.website_type) ? el.contacts.website : el.contacts.website_type + el.contacts.website
                                                                    }
                                                                </a>
                                                            </span>
                                                        :
                                                            null
                                                        }
                                                        {
                                                        el.contacts.email !== '' ?
                                                            <span style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', lineHeight: '2'}}><i className="bi bi-envelope mr-2"></i>

                                                            <a href={'mailto: '+ el.contacts.email} alt="" target="_blank" rel="noreferrer">{el.contacts.email}</a>


                                                            </span>
                                                        :
                                                            null
                                                        } 
                                                        {
                                                        content.info.category !== '' ?
                                                            <span style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', lineHeight: '2'}} className='d-none'><strong>{content.info.category}</strong></span>
                                                        :
                                                            null
                                                        }
                                                        {
                                                        el.contacts.tlf !== '' ?
                                                            <span style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', lineHeight: '2'}}><i className="bi bi-telephone mr-2"></i> <a href={"tel:" + el.contacts.tlf}>{el.contacts.tlf}</a></span>
                                                        :
                                                            null
                                                        }
                                                        {
                                                        el.contacts.tlm !== '' ?
                                                            <span style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', lineHeight: '2'}}><i className="bi bi-phone mr-2"></i> <a href={"tel: " + el.contacts.tlm}>{el.contacts.tlm}</a> <br /></span>
                                                        :
                                                            null
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                            {
                                            el['media'] !== undefined && el['media']['image'] !== undefined && el['media']['image'].length > 0 ?
                                                <div className={"div-image col-12 col-md-3 col-lg-4 order-first order-md-last mt-3 mb-5"}>
                                                    <div className="card restaurant-item hover-animate">
                                                        <a
                                                            href={el['media']['image'][0]['link']}
                                                            data-fancybox="gallery"
                                                            data-caption={el['media']['image'][0]['link'].includes(el['media']['image'][0]['name']) ? '' : el['media']['image'][0]['name']}
                                                        >
                                                            <AssecImage 
                                                                linkImage={el['media']['image'][0]['link']}
                                                                width="400"
                                                                height="300"
                                                                fit='crop'
                                                                type='img'
                                                                sClass="img-fluid rounded shadow-sm" 
                                                                alt={el['media']['image'][0]['name']}
                                                            />
                                                        </a>
                                                    </div>
                                                </div>
                                            :
                                            null
                                            }
                                        </div>
                                    </div>

                                    <div className='div-content col-12'>

                                        <div className={"div-abstract mt-3 d-none"}>
                                            <p>
                                                {ReactHtmlParser(lang === 'pt' || el.language === undefined ? el.abstract : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].abstract === undefined || el.language[lang_id].abstract === '' ? el.abstract : el.language[lang_id].abstract))}
                                            </p>
                                        </div>

                                        <div className="div-categories w-100 d-none"><i className="bi bi-tag" />
                                            {
                                            content.info.tags.map((v, k) => {
                                                return (
                                                    <span key={k} className="pill-tag">{v.text}</span>
                                                )
                                            })
                                            }
                                        </div>
                                        <div className={"div-text pb-4 pb-md-5"}>
                                            {ReactHtmlParser(lang === 'pt' || el.language === undefined ? el.text : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].text === undefined || el.language[lang_id].text === '' ? el.text : el.language[lang_id].text))}
                                        </div>
                                    </div>

                                    {
                                    el['media'] !== undefined && el['media']['image'] !== undefined && el.media.image.length > 1 ?
                                        <div className="div-content col-12 mb-5 order-last">
                                            <div className="row div-gallery">
                                                {
                                                el.media.image.map((el_img, k_img) => {
                                                    if(k_img > 0) {

                                                        return (
                                                            <div key={k_img} className="col-6 col-sm-3 col-lg-2 p-0">
                                                                <a
                                                                    href={el_img.link}
                                                                    data-fancybox="gallery"
                                                                    data-caption={(el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png')) ? '' : el_img.name}
                                                                >
                                                                    <AssecImage 
                                                                        linkImage={el_img.link}
                                                                        width="400"
                                                                        height="400"
                                                                        fit='crop'
                                                                        type='img'
                                                                        sClass="img-fluid" 
                                                                        alt={el_img.name}
                                                                    />
                                                                </a>
                                                            </div>
                                                        )
                                                    }
                                                    else
                                                        return null
                                                })
                                                }
                                            </div>
                                        </div>
                                    :
                                        null
                                    }
                                    {
                                    el['media'] !== undefined && el['media']['doc'] !== undefined && el.media.doc.length > 0 ?
                                        <div className="div-content col-12 mb-5 order-last">
                                            <div className="row div-documents">
                                                {
                                                el.media.doc.map((el_doc, k_doc) => {
                                                    if(el_doc['visible'] !== undefined && el_doc['visible'] === true) {
                                                        return (
                                                            <div key={k_doc} className="mb-2">
                                                                <img className="img-fluid" src={el_doc.link} alt="" />
                                                                <a className="btn btn-primary" href={el_doc.link} target="_blank" rel="noreferrer">{el_doc.name}</a>
                                                            </div>
                                                        )
                                                    }
                                                    else {
                                                        return null
                                                    }
                                                })
                                                }
                                            </div>
                                        </div>
                                    :
                                        null
                                    }
                                </div>
                            </div>
                        </section>
                    )
                })
                }
            </main>
            <Footer dados_config={dados_config} dados_navbar={navbar} />
        </>
    )
}