import React, { useState, useEffect } from 'react';

// import {
//     FacebookIcon,
//     FacebookShareButton,
// } from "react-share";


// import { Fancybox as NativeFancybox } from "@fancyapps/ui/dist/fancybox.esm.js";
import "@fancyapps/ui/dist/fancybox.css";

import ReactHtmlParser/*, { processNodes, convertNodeToElement, htmlparser2 }*/ from 'react-html-parser';

import { content_language } from '../../../Utils';

import Loader from '../../../loader';
import Navbar from '../Navbar/Navbar_00';
import Footer from '../Footer/Footer';

// import DivSharer from '../DivSharer/DivSharer';

import { language } from '../WEBContent/Language';

import AssecImage from "../ASSEC_Image/Image"

export default function Insight(props) {

    const [dados_config, set_dados_config] = useState({});
    const [navbar, set_navbar] = useState({});

    const [isloading, setLoading] = useState(true);

	//const [lang, ] = useState('pt');
    const [lang, set_lang] = useState(localStorage.getItem('lang'));
	const [lang_id, set_lang_id] = useState('1');

    const data_interface = {
        id: '',
        info: {},
        content: {}
    }
    const [content, set_content] = useState(data_interface);

    useEffect(() => {

        if(props.info !== undefined && props.info !== null && props.info !== '') {
            set_dados_config(props.info.state.dados_config)
            set_navbar(props.info.state.dados_navbar)
            set_content(props.info.state.dados_content)
        }

        try {

            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                set_lang_id(el.id)
                }
            })

            setLoading(false)

            return

        } catch {
            console.log('')

			return
		}
        
    }, [props]);


    return (isloading ?
		<Loader />
	:
        <>
            <Navbar dados_config={dados_config} dados_navbar={navbar} />
            <main className="article" id="gallery">

                <section className="content-body">

                    <div className="container-fluid">
                        <div className="row">
                            <div className='col-12 bg-light py-5'>
                                <div className='container'>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <h3 className="titulo-primario">
                                                <span>
                                                    {
                                                    content.content.map((el, k) => {  
                                                        if (k === 0){
                                                            return (
                                                                lang === 'pt' || content.content[0].language === undefined ? 
                                                                    content.content[0].title 
                                                                : 
                                                                    content.content[0].language[lang_id] === undefined || content.content[0].language[lang_id] === null || content.content[0].language[lang_id].title === undefined || content.content[0].language[lang_id].title === '' ? 
                                                                        content.content[0].title 
                                                                    : 
                                                                        content.content[0].language[lang_id].title
                                                            )
                                                        }
                                                        else {
                                                            return null
                                                        }
                                                    })
                                                    }
                                                </span>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                    content.content.map((el, k) => { 
                        return (
                            <div key={k} className="container">
                                <div className="row">
                                    <div className="px-3 my-3 text-right w-100">
                                        <a className="btn btn-text" href={'./gallery/all'} >
                                            <i className="bi bi-arrow-left"></i> {language[lang].back}
                                        </a>
                                    </div>
                                </div>

                                <div className="row">
                                    <div id="div-content" className="div-content col-12">

                                        {
                                            el.text !== '' ?

                                                <div className="div-text text-center mb-5">
                                                    {ReactHtmlParser(lang === 'pt' || el.language === undefined ? el.text : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].text === undefined || el.language[lang_id].text === '' || el.language[lang_id].text === '<p></p>\n' ? el.text : el.language[lang_id].text))}
                                                </div>
                                            : null 
                                        }
                                    </div>

                                    {
                                        lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el['language'][lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['image'] !== undefined && el.language[lang_id]['media']['image'].length > 0 ?
                                            <div className="div-content col-12 card-columns mb-5">
                                                {
                                                    el.language[lang_id].media.image.map((el_img, k_img) => {

                                                        let link = ''
                                                        let youtube_thumbnail = ''

                                                        if(el_img.type === 'video') {

                                                            link = el_img.link.replace('watch?v=', 'embed/');
                                                        
                                                            youtube_thumbnail = link.split('/').pop();
                                                        }

                                                        return (
                                                            <div key={k_img} className="card mb-3">

                                                                    {
                                                                        el_img.type === 'video' ?

                                                                            <a
                                                                                className='text-decoration-none'
                                                                                href={link}
                                                                                data-fancybox="gallery"
                                                                                data-caption={(el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png') || el_img.name.includes('youtube.com')) ? '' : el_img.name}
                                                                            >
                                                                                <figure className="shadow-sm hover-animate m-0">
                                                                                    <img src={'https://i1.ytimg.com/vi/' + youtube_thumbnail +'/0.jpg'} className="img-fluid" alt={el_img.name} title={el_img.name} />
                                                                                    {
                                                                                        (el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png') || el_img.name.includes('youtube.com')) ?
                                                                                            ''
                                                                                        :
                                                                                            <figcaption className='p-3 text-center'>{el_img.name}</figcaption>
                                                                                    }
                                                                                </figure>
                                                                            </a>

                                                                        :
                                                                            <a
                                                                                className='text-decoration-none'
                                                                                href={el_img.link}
                                                                                data-fancybox="gallery"
                                                                                data-caption={(el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png')) ? '' : el_img.name}
                                                                            >
                                                                                <figure className="shadow-sm hover-animate m-0">
                                                                                    <AssecImage 
                                                                                        linkImage={el_img.link}
                                                                                        width="450"
                                                                                        height="300"
                                                                                        fit='crop'
                                                                                        type='img'
                                                                                        sClass="img-fluid" 
                                                                                        alt={el_img.name}
                                                                                    />
                                                                                    {/*<img src={el_img.link} className="img-fluid" alt={el_img.name} title={el_img.name} />*/}
                                                                                    {
                                                                                        (el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png')) ?
                                                                                            ''
                                                                                        :
                                                                                            <figcaption className='p-3 text-center'>{el_img.name}</figcaption>
                                                                                    }
                                                                                </figure>
                                                                            </a>
                                                                    }
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        
                                        :
                                            el['media'] !== undefined && el['media']['image'] !== undefined && el.media.image.length > 0 ?
                                                <div className="div-content col-12 card-columns mb-5">
                                                        {
                                                            el.media.image.map((el_img, k_img) => {

                                                                let link = ''
                                                                let youtube_thumbnail = ''

                                                                if(el_img.type === 'video') {

                                                                    link = el_img.link.replace('watch?v=', 'embed/');
                                                                
                                                                    youtube_thumbnail = link.split('/').pop();
                                                                }

                                                                return (
                                                                    <div key={k_img} className="card mb-3">

                                                                            {
                                                                                el_img.type === 'video' ?

                                                                                    <a
                                                                                        className='text-decoration-none'
                                                                                        href={link}
                                                                                        data-fancybox="gallery"
                                                                                        data-caption={(el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png') || el_img.name.includes('youtube.com')) ? '' : el_img.name}
                                                                                    >
                                                                                        <figure className="shadow-sm hover-animate m-0">
                                                                                            <img src={'https://i1.ytimg.com/vi/' + youtube_thumbnail +'/0.jpg'} className="img-fluid" alt={el_img.name} title={el_img.name} />
                                                                                            {
                                                                                                (el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png') || el_img.name.includes('youtube.com')) ?
                                                                                                    ''
                                                                                                :
                                                                                                    <figcaption className='p-3 text-center'>{el_img.name}</figcaption>
                                                                                            }
                                                                                        </figure>
                                                                                    </a>

                                                                                :
                                                                                    <a
                                                                                        className='text-decoration-none'
                                                                                        href={el_img.link}
                                                                                        data-fancybox="gallery"
                                                                                        data-caption={(el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png')) ? '' : el_img.name}
                                                                                    >
                                                                                        <figure className="shadow-sm hover-animate m-0">
                                                                                            <AssecImage 
                                                                                                linkImage={el_img.link}
                                                                                                width="450"
                                                                                                height="300"
                                                                                                fit='crop'
                                                                                                type='img'
                                                                                                sClass="img-fluid" 
                                                                                                alt={el_img.name}
                                                                                            />
                                                                                            {/*<img src={el_img.link} className="img-fluid" alt={el_img.name} title={el_img.name} />*/}
                                                                                            {
                                                                                                (el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png')) ?
                                                                                                    ''
                                                                                                :
                                                                                                    <figcaption className='p-3 text-center'>{el_img.name}</figcaption>
                                                                                            }
                                                                                        </figure>
                                                                                    </a>
                                                                            }
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                </div>
                                            : null
                                    }

                                    { 
                                        //sem card-columns (desactivado)
                                        lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el['language'][lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['image'] !== undefined && el.language[lang_id]['media']['image'].length > 0 && 0?
                                            <div className="div-content col-12 mb-5">
                                                <div className="row div-gallery">
                                                    {
                                                        el.language[lang_id].media.image.map((el_img, k_img) => {

                                                            let link = el_img.link
                                                            let youtube_thumbnail = ''

                                                            if(el_img.type === 'video') {

                                                                link = link.replace('watch?v=', 'embed/');
                                                            
                                                                youtube_thumbnail = link.split('/').pop();
                                                            }

                                                            return (
                                                                <div key={k_img} className="col-6 col-md-4 col-lg-4">

                                                                    {
                                                                        el_img.type === 'video' ?

                                                                            <a
                                                                                className='text-decoration-none'
                                                                                href={link}
                                                                                data-fancybox="gallery"
                                                                                data-caption={(el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png') || el_img.name.includes('youtube.com')) ? '' : el_img.name}
                                                                            >
                                                                                <figure className="shadow-sm hover-animate m-2">
                                                                                    <img src={'https://i1.ytimg.com/vi/' + youtube_thumbnail +'/0.jpg'} className="img-fluid" alt={el_img.name} title={el_img.name} />
                                                                                    {
                                                                                        (el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png') || el_img.name.includes('youtube.com')) ?
                                                                                            ''
                                                                                        :
                                                                                            <figcaption className='p-3 text-center'>{el_img.name}</figcaption>
                                                                                    }
                                                                                </figure>
                                                                            </a>

                                                                        :
                                                                            <a
                                                                                className='text-decoration-none'
                                                                                href={el_img.link}
                                                                                data-fancybox="gallery"
                                                                                data-caption={(el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png')) ? '' : el_img.name}
                                                                            >
                                                                                <figure className="shadow-sm hover-animate m-2">
                                                                                    <AssecImage 
                                                                                        linkImage={el_img.link}
                                                                                        width="450"
                                                                                        height="300"
                                                                                        fit='crop'
                                                                                        type='img'
                                                                                        sClass="img-fluid" 
                                                                                        alt={el_img.name}
                                                                                    />
                                                                                    {/*<img src={el_img.link} className="img-fluid" alt={el_img.name} title={el_img.name} />*/}
                                                                                    {
                                                                                        (el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png')) ?
                                                                                            ''
                                                                                        :
                                                                                            <figcaption className='p-3 text-center'>{el_img.name}</figcaption>
                                                                                    }
                                                                                </figure>
                                                                            </a>
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        :
                                            el['media'] !== undefined && el['media']['image'] !== undefined && el.media.image.length > 0 && 0 ?
                                                <div className="div-content col-12 mb-5">
                                                    <div className="row div-gallery">
                                                        {
                                                            el.media.image.map((el_img, k_img) => {

                                                                let link = el_img.link
                                                                let youtube_thumbnail = ''

                                                                if(el_img.type === 'video') {

                                                                    link = link.replace('watch?v=', 'embed/');
                                                                
                                                                    youtube_thumbnail = link.split('/').pop();
                                                                }

                                                                return (
                                                                    <div key={k_img} className="col-6 col-md-4 col-lg-4">

                                                                        {
                                                                            el_img.type === 'video' ?

                                                                                <a
                                                                                    className='text-decoration-none'
                                                                                    href={link}
                                                                                    data-fancybox="gallery"
                                                                                    data-caption={(el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png') || el_img.name.includes('youtube.com')) ? '' : el_img.name}
                                                                                >
                                                                                    <figure className="shadow-sm hover-animate m-2">
                                                                                        <img src={'https://i1.ytimg.com/vi/' + youtube_thumbnail +'/0.jpg'} className="img-fluid" alt={el_img.name} title={el_img.name} />
                                                                                        {
                                                                                                (el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png') || el_img.name.includes('youtube.com')) ?
                                                                                                    ''
                                                                                                :
                                                                                                    <figcaption className='p-3 text-center'>{el_img.name}</figcaption>
                                                                                            }
                                                                                    </figure>
                                                                                </a>

                                                                            :
                                                                                <a
                                                                                    className='text-decoration-none'
                                                                                    href={el_img.link}
                                                                                    data-fancybox="gallery"
                                                                                    data-caption={(el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png')) ? '' : el_img.name}
                                                                                >
                                                                                    <figure className="shadow-sm hover-animate m-2">
                                                                                        <AssecImage 
                                                                                            linkImage={el_img.link}
                                                                                            width="450"
                                                                                            height="300"
                                                                                            fit='crop'
                                                                                            type='img'
                                                                                            sClass="img-fluid" 
                                                                                            alt={el_img.name}
                                                                                        />
                                                                                        {/*<img src={el_img.link} className="img-fluid" alt={el_img.name} title={el_img.name} />*/}
                                                                                        {
                                                                                            (el_img.link.includes(el_img.name) || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png')) ?
                                                                                                ''
                                                                                            :
                                                                                                <figcaption className='p-3 text-center'>{el_img.name}</figcaption>
                                                                                        }
                                                                                    </figure>
                                                                                </a>
                                                                        }
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            :
                                                null
                                    }

                                    {
                                        lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el['language'][lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['doc'] !== undefined && el.language[lang_id]['media']['doc'].length > 0 ?
                                            <div className="div-content col-12 mb-5">
                                                <div className="row div-documents">
                                                    {
                                                    el.language[lang_id].media.doc.map((el_doc, k_doc) => {
                                                        if(el_doc['visible'] !== undefined && el_doc['visible'] === true) {
                                                            return (
                                                                <div key={k_doc} className="mb-2">
                                                                    <img className="img-fluid" src={el_doc.link} alt="" />
                                                                    <a className="btn btn-primary" href={el_doc.link} target="_blank" rel="noreferrer">{el_doc.name}</a>
                                                                </div>
                                                            )
                                                        }
                                                        else {
                                                            return null
                                                        }
                                                    })
                                                    }
                                                </div>
                                            </div>

                                        :
                                            el['media'] !== undefined && el['media']['doc'] !== undefined && el.media.doc.length > 0 ?
                                                <div className="div-content col-12 mb-5">
                                                    <div className="row div-documents">
                                                        {
                                                        el.media.doc.map((el_doc, k_doc) => {
                                                            if(el_doc['visible'] !== undefined && el_doc['visible'] === true) {
                                                                return (
                                                                    <div key={k_doc} className="mb-2">
                                                                        <img className="img-fluid" src={el_doc.link} alt="" />
                                                                        <a className="btn btn-primary" href={el_doc.link} target="_blank" rel="noreferrer">{el_doc.name}</a>
                                                                    </div>
                                                                )
                                                            }
                                                            else {
                                                                return null
                                                            }
                                                        })
                                                        }
                                                    </div>
                                                </div>
                                            :
                                                null
                                        }
                                </div>
                            </div>
                        )
                    })
                    }
                </section>
            </main>
            <Footer dados_config={dados_config} dados_navbar={navbar} />
        </>
    )
}