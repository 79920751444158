import React, { useState, useEffect } from 'react';

// import {
//     FacebookIcon,
//     FacebookShareButton,
// } from "react-share";

import ReactHtmlParser/*, { processNodes, convertNodeToElement, htmlparser2 }*/ from 'react-html-parser';
import parse from 'html-react-parser';

import * as Hosts from '../../../Hosts';
import { reqGET, content_language, set_language, repo_img_link } from '../../../Utils';

import Loader from '../../../loader';
import Navbar from '../Navbar/Navbar_00';
import Footer from '../Footer/Footer';
import Preview from '../Preview/Preview';

import AssecImage from "../ASSEC_Image/Image"

//import DivSharer from '../DivSharer/DivSharer';

import { language } from '../WEBContent/Language';

export default function News(props) {

    const [dados_config, set_dados_config] = useState({});
    const [navbar, set_navbar] = useState({});

    const [isloading, setLoading] = useState(true);

    const [lang, set_lang] = useState('pt');
    const [lang_id, set_lang_id] = useState('1');

    const [link, ] = useState('')
    let last_i = 0

    const data_interface = {
        id: '',
        info: {},
        content: {}
    }
    const [content, set_content] = useState(data_interface);
    const [list_content, set_list_content] = useState([]);

    useEffect(() => {

        if(props.info !== undefined && props.info !== null && props.info !== '') {
            set_dados_config(props.info.state.dados_config)
            set_navbar(props.info.state.dados_navbar)
            set_content(props.info.state.dados_content)
        }

        if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_language('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                set_lang_id(el.id)
                }
            })
        }

        try {

            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                set_lang_id(el.id)
                }
            })
            
			let id = props.info.state.id;

            if(id !== '') {
                let getInfoList = async () => {
                    await reqGET(`${Hosts.SIMCore_API}/web/content/type/${Hosts.companycode}/news`)
                        .then(res => { 
                            if (res.data.length > 0) {
    
                                var hoje = new Date();
                                let aux = []
                                res.data.forEach((v, k) => {
                                  if(v.info.date.published !== undefined && v.info.date.published !== '' && aux.length <= 2) {
                                      var date_p = new Date(v.info.date.published);
                        
                                      if(hoje >= date_p) {
                                        if(v.info.date.avaiable !== '') {
                                            var date_a = new Date(v.info.date.avaiable);
    
                                            if(hoje <= date_a) {
                                                aux.push(v)
                                            }
                                        }
                                        else {
                                            aux.push(v)
                                        }
                                      }
                                  }
                                })
                                set_list_content(aux)
                                
                                setLoading(false)
                              }
                        })
                        .catch(erro => console.log('ERROR', erro))
                }
                getInfoList()
            }
            else {
                console.log('=== ""')
            }

            return

        } catch {
            console.log('nada')

			return
		}
        
    }, [props]);

    return (isloading ?
		<Loader />
	:
        <>
            {
            content.info['preview'] === true ? 
                <Preview />
            : 
                null
            }
            <Navbar dados_config={dados_config} dados_navbar={navbar} />
            {/*<Breadcrumb dados_config={dados_config} dados_navbar={navbar} dados_content={content} />*/}
            <main className="article">

                {
                content.content.map((el, k) => {  
                    return (
                        <section key={k} className="content-body content-list">
                            <div className="container">
                                <div className="row mt-3 mt-md-5">
                                    <div className="div-content col-12 col-md-9 pr-md-3 pr-lg-5">
                                        <div className={"div-title"}>
                                            <h1 className={el.title !== '' ? "titulo-primario" : "d-none"}>
                                                {lang === 'pt' || el.language === undefined ? el.title : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].title === undefined || el.language[lang_id].title === '' ? el.title : el.language[lang_id].title)}
                                            </h1>
                                        </div>

                                        <div className={"div-abstract my-3"}>
                                            {ReactHtmlParser(lang === 'pt' || el.language === undefined ? el.abstract : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].abstract === undefined || el.language[lang_id].abstract === '' ? el.abstract : el.language[lang_id].abstract))}
                                        </div>

                                        <div className="div-categories w-100 d-none"><i className="bi bi-tag" />
                                            {
                                            content.info.tags.map((v, k) => {
                                                return (
                                                    <span key={k} className="pill-tag">{v.text}</span>
                                                )
                                            })
                                            }
                                        </div>

                                        <div className={"div-source"}>
                                            {
                                            content.info.date !== undefined && content.info.date['published'] !== '' ?
                                                <span>
                                                    {content.info.date['published'].replace("T", " ")}
                                                </span>
                                            :
                                                ''
                                            }
                                            
                                            {
                                            content.info.source !== undefined && content.info.source['text'] !== undefined ? 
                                                <span>
                                                    {language[lang].source_text}
                                                    {
                                                    content.info.source['text'].includes("http") ? 
                                                        <a href={content.info.source['text']} target="_blank" rel="noreferrer">{link}</a> 
                                                    : 
                                                        <a href={'http://' + content.info.source['text']} target="_blank" rel="noreferrer">{link}</a> 
                                                    }
                                                </span> 
                                            : 
                                                ''
                                            }

                                            {
                                            content.info.source !== undefined && content.info.source['image'] !== undefined && content.info.source['image'] !== '' ? 
                                                <span>
                                                    {language[lang].source_image}
                                                    {
                                                    content.info.source !== undefined && content.info.source['image'] !== '' ? 
                                                        content.info.source['image'] 
                                                    : 
                                                        ''
                                                    }
                                                </span>
                                            : 
                                                ''
                                            }
                                            
                                        </div>

                                        {
                                        el['media'] !== undefined && el['media']['image'] !== undefined && el['media']['image'].length > 0 ?
                                            <div className={"div-image mt-3 mb-3 mb-md-5"}>
                                                <AssecImage 
                                                    linkImage={el['media']['image'][0]['link']}
                                                    width="960"
                                                    height="540"
                                                    fit='crop'
                                                    type='img'
                                                    sClass="img-fluid rounded shadow-sm" 
                                                    alt={el['media']['image'][0]['link']['name']}
                                                />
                                                {/*<img src={el['media']['image'][0]['link']} className="img-fluid rounded shadow-sm mt-2 mb-3" alt="" />*/}
                                            </div>
                                        :
                                            null
                                        }
                                        
                                        <div className={"div-text mt-3"}>
                                            {ReactHtmlParser(lang === 'pt' || el.language === undefined ? el.text : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].text === undefined || el.language[lang_id].text === '' || el.language[lang_id].text === '<p></p>\n' ? el.text : el.language[lang_id].text))}
                                        </div>

                                        {
                                        el['media'] !== undefined && el['media']['image'] !== undefined && el.media.image.length > 1 ?
                                            <div className="row">
                                                <div className="div-gallery col-12 mb-5">
                                                    {
                                                    el.media.image.map((el_img, k_img) => {
                                                        if(k_img > 0) {
                                                            return (
                                                                <div key={k_img} className="col-2">
                                                                    <figure>
                                                                        <AssecImage 
                                                                            linkImage={el_img.link}
                                                                            width="450"
                                                                            height="300"
                                                                            fit='crop'
                                                                            type='img'
                                                                            sClass="img-fluid rounded shadow-sm" 
                                                                            alt={el_img.name}
                                                                        />
                                                                        {/*<img className="img-fluid rounded shadow-sm" src={el_img.link} alt={el_img.description} />*/}
                                                                        <figcaption>{el_img.description}</figcaption>
                                                                    </figure>
                                                                </div>
                                                            )
                                                        }
                                                        else 
                                                            return null
                                                    })
                                                    }
                                                </div>
                                            </div>
                                        :
                                            null
                                        }
                                        {
                                        el['media'] !== undefined && el['media']['doc'] !== undefined && el.media.doc.length > 0 ?
                                            <div className="row">
                                                <div className="div-documents col-12 mb-5">
                                                    {
                                                    el.media.doc.map((el_doc, k_doc) => {
                                                        return (
                                                            <div key={k_doc} className="mb-2">
                                                                <img className="img-fluid rounded shadow-sm" src={el_doc.link} alt="" />
                                                                <a className="btn btn-primary" href={el_doc.link} target="_blank" rel="noreferrer">{el_doc.name}</a>
                                                            </div>
                                                        )
                                                    })
                                                    }
                                                </div>
                                            </div>
                                        :
                                            null
                                        }

                                        {/*<div className="row">
                                            <div className="div-social col-12 mt-3 mb-3 mb-md-5">
                                                <DivSharer link={Hosts.EMAIL_WEB_SITE_URL + pathname} description={get_seo_data('description')} />
                                            </div>
                                        </div>*/}
                                    </div>
                                    

                                    <div className="div-last news-cards col-12 col-md-3 mb-5">
                                        <div className='row'>
                                            <div className='col-12'>
                                                <h3 className='subtitulo-pp'>{language[lang].news_turistrela}</h3>
                                            </div>
                                            {
                                            list_content.map((content_list) => {
                                                return (
                                                    content_list.content.map((el, k) => {  
                                                        if(content_list.id !== content.id && last_i < 2) {
                                                            last_i = last_i + 1
                                                            return (
                                                                <div key={k} className="col-12">
                                                                    <div className="mb-3 insight-item hover-animate shadow-sm">
                                                                        <a href={'./article/' + content_list.id} title={el.title}>
                                                                            <div className="div-image d-none d-md-block">
                                                                                {
                                                                                el['media'] !== undefined && el['media']['image'] !== undefined && el['media']['image'].length > 0 ?
                                                                                     <AssecImage 
                                                                                            linkImage={el['media']['image'][0]['link']}
                                                                                            width="450"
                                                                                            height="300"
                                                                                            fit='crop'
                                                                                            type='img'
                                                                                            sClass="img-fluid" 
                                                                                            alt=""
                                                                                        />
                                                                                :
                                                                                    <img src={repo_img_link('nophoto.png')} className="img-fluid" alt="" />
                                                                                }
                                                                            </div>
                                                                            <div className="p-0 p-md-3">
                                                                                <div className="div-source">
                                                                                    <span>{parse(content_list.info.date.published.split('T')[0])}</span>
                                                                                </div>
                                                                                <div className="div-text">
                                                                                    {lang === 'pt' || el.language === undefined ? el.title : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].title === undefined || el.language[lang_id].title === '' ? el.title : el.language[lang_id].title)}
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                        else 
                                                            return null
                                                    })
                                                )
                                            })
                                            }
                                            <div className='col-12 text-center my-3'>
                                                <a className='btn btn-text-inv shadow-sm' href={'./article/all'} >{language[lang].btn_news}</a>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )
                })
                }
            </main>
            <Footer dados_config={dados_config} dados_navbar={navbar} />
        </>
    )
}