import React, { useState, useEffect, useRef } from 'react';
import './SliderLanding.css';
import '../Pagina.css';
import '../Animate/animate.css';

import { /*reqGET,*/ set_language, content_language, repo_img_link} from '../../../Utils';

//import * as Hosts from "../../../Hosts";

import { Navigation, Pagination, Autoplay, Scrollbar, A11y, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';

import "swiper/swiper-bundle.min.css";

//import { language } from '../WEBContent/Language';

import Loader from '../../../loader';

const Slider = (props) => {

    const [/*lang*/, set_lang] = useState('pt');
    const [/*lang_id*/, set_lang_id] = useState('1');

    const [, set_dados] = useState(null);
	const banner = useRef(null);

    const [isloading, setLoading] = useState(true);

    const [navbarHeight, setnavbarHeight] = useState(0);
    const [vhHeight, setvhHeight] = useState('100vh');

    useEffect(() => {

        function getNavbarHeight() {
            setTimeout(() => {
                let height = 0;
                if(document.getElementsByClassName('sticky-top').length > 0) {
                    height = document.getElementsByClassName('sticky-top')[0].clientHeight;
                }
                let windowWidth = window.innerWidth;
                setnavbarHeight(height);
                if(windowWidth < 992) {
                    setvhHeight('50vh');
                } else {
                    if(document.getElementsByClassName('page-01').length > 0) {
                        setvhHeight('100vh');
                    } else if(document.getElementsByClassName('page-02').length > 0) {
                        setvhHeight('100vh');
                    } else if(document.getElementsByClassName('page-03').length > 0) {
                        setvhHeight('100vh');
                    } else if(document.getElementsByClassName('page-04').length > 0) {
                        setvhHeight('100vh');
                    } else {
                        setvhHeight('100vh');
                    }
                }
            }, 100)
        }

        window.addEventListener('resize', getNavbarHeight);
        getNavbarHeight();

        if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_language('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                    set_lang_id(el.id)
                }
            })
        }

        if(props.dados_config !== undefined && props.dados_config !== null && props.dados_config !== '') {
           
            let aux = []
            aux.push(props.dados_config.content[0].banner)
            set_dados(banner.current = aux)

            setTimeout(() => {
                setLoading(false)
            }, 750)

        }

    }, [props])

    return (
        isloading ?
            <div id="slider">
                <div className='swiper-slide'>
                    <Loader />
                </div>
            </div>
		:
            <>
                {
                banner.current !== undefined && banner.current !== null ?
                    <section id="slider">
                        <div className="swiper swiper-container swiper-cartaz slideshow">
                            <Swiper
                                modules={[Navigation, Autoplay, Pagination, Scrollbar, A11y, EffectFade]}
                                spaceBetween={0}
                                slidesPerView={1}
                                //navigation={banner.length > 1 ? true : false}
                                navigation={{
                                    nextEl: '.swiper-cartaz-button-next',
                                    prevEl: '.swiper-cartaz-button-prev',
                                }}
                                loop={false}
                                speed={2000}
                                autoplay={{
                                    delay: 8000,
                                    disableOnInteraction: false
                                }}
                                effect="fade"
                                //pagination={banner.length > 1 ? true : false}
                                pagination={{
                                    el: '.swiper-pagination-cartaz',
                                    //dynamicBullets: true,
                                    //type: "progressbar",
                                    clickable: true
                                }}
                                breakpoints={{
                                    768: {
                                        slidesPerView: 1,
                                        //navigation: true,
                                    },
                                    1200: {
                                        slidesPerView: 1,
                                        //navigation: true,
                                        //loop: true,
                                    },
                                }}
                            /*scrollbar={{ draggable: true }}*/
                            //onSwiper={(swiper) => console.log(swiper)}
                            //onSlideChange={() => console.log('slide change')}
                            >
                                {
                                banner.current.length > 0 ?
                                    banner.current.map((v, key) => {
                                        //console.log('v', v)

                                        if(v !== undefined) {
                                            //if banner/image doesn't exist, add noimage_banner.jpg
                                            if((v[0] === undefined || v[0]['link'] === undefined || v[0]['link'] === '')) {
                                                v[0] = {id: '', link: repo_img_link("nophoto_banner.jpg")}
                                            }

                                            return (
                                                <div key={key}>
                                                    <SwiperSlide key={key} className={"slide-image slide-image-" + key} style={{ backgroundImage: "linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(255, 255, 255, 0) 40%), url(" + v.link + ")", height: "calc(" + vhHeight + " - " + navbarHeight + "px)" }}>                            
                                                    </SwiperSlide>
                                                </div>
                                            )
                                        }
                                        else {
                                            return null
                                        }

                                    })
                                :
                                    <SwiperSlide className={"slide-image slide-image"} style={{ backgroundImage: "linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(255, 255, 255, 0) 40%), url(" + repo_img_link("nophoto_banner.jpg") + ")", height: "calc(" + vhHeight + " - " + navbarHeight + "px)" }}>
                                    </SwiperSlide>
                                }

                                <div className="swiper-pagination-cartaz mb-3"></div>
                                <div className={"swiper-button-next swiper-cartaz-button-next " + (banner.current.length > 1 ? 'd-none d-lg-block' : 'd-none')}></div>
                                <div className={"swiper-button-prev swiper-cartaz-button-prev " + (banner.current.length > 1 ? 'd-none d-lg-block' : 'd-none')}></div>

                            </Swiper>
                        </div>
                    </section>
                :
                    null
                }
            </>
    )
};
export default Slider;