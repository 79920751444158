import React, { useState, useEffect, useRef } from 'react';

import { /*reqGET, repo_img_link,*/ content_language, translate } from '../../../../Utils';

import Loader from '../../../../loader';
import "./HomeSteps_01.css"

const HomeSteps = (props) => {

	const [, set_dados] = useState(null)
	const steps_list = useRef([])
	const dados_config = useRef([])

    const [steps_title, set_steps_title] = useState('')
    const [steps_subtitle, set_steps_subtitle] = useState('')

    const [isloading, setLoading] = useState(true);

    const [lang, set_lang] = useState(localStorage.getItem('lang'));
    const [/*lang_id*/, set_lang_id] = useState('1');

    useEffect(() => {

        if(props.dados_config !== undefined && props.dados_config !== null && props.dados_config !== '') {
            set_dados(dados_config.current = props.dados_config)
            
            for (let el of dados_config.current.content[0].components) {

                if(el.info.tag === 'statistics' && el.info.active) {
                    set_steps_title(translate(el.info.title, lang))
                    set_steps_subtitle(translate(el.info.subtitle, lang))

                    let aStepsAux = []
                    for (let el of dados_config.current.content[0].statistics) {

                        let jSteps = {
                            name: translate(el.info.title, lang),
                            description: translate(el.info.description, lang),
                            value: el.info.value,
                            icon: el.info.icon
                        }

                        aStepsAux.push(jSteps)

                    }

                    set_dados(steps_list.current = aStepsAux)
                    setLoading(false)
                }
            }
        }

        if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                    set_lang_id(el.id)
                }
            })
        }

    }, [props, lang])

    return (
        isloading ?
            <Loader />
		:
            <section id="homesteps_01" className='div-component'>
                <div className="container">
                    <div className="row">
                        <div className="col-12 title-properties">
                            <h3 className="titulo-pp">
                                {steps_title}
                            </h3>
                            {
                                steps_subtitle !== '' ?
                                    <h4 className={"subtitulo-pp"}>
                                        {steps_subtitle}
                                    </h4>
                                :
                                null
                            }
                            <hr></hr>
                        </div>
                        {
                            steps_list.current !== undefined && steps_list.current !== null &&  steps_list.current.length > 0 ?
                                <>
                                    <div className="col-12" >
                                        <div className="row g-4 justify-content-center">
                                            {
                                                steps_list.current.map((v, k) => {
                                                    if(k < 4) {
                                                        return (
                                                            <div className="col-md-6 col-lg-3 step-column mb-4" key={k}>
                                                                <div className="step-container h-100">
                                                                    <div className='step-circle'>
                                                                        <div className='step-circle-content'>{'0' + (k +1)}</div>
                                                                    </div>
                                                                    <div className="step-content">
                                                                        {
                                                                            v.name !== undefined && v.name !== '' ?
                                                                                <div className="step-title">{v.name}</div>
                                                                            :
                                                                                null 
                                                                        }
                                                                        {
                                                                            v.description !== undefined && v.description !== '' ?
                                                                                <div className="step-text">{v.description}</div>
                                                                            :
                                                                                null 
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    } else { 
                                                        return null
                                                    }
                                                })              
                                            }
                                        </div>
                                    </div>
                                </> 
                            :
                                null
                        }
                    </div>
                </div>
            </section>
    )
};
export default HomeSteps;